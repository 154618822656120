import React from "react";
import { useSelector } from "react-redux";
import styled, { css, useTheme } from "styled-components";
import { IAppState } from "../../store";
import Donut from "../donut/Donut";
import Illustration, { getBackgroundImage, getLocationImage } from "../illustration/Illustration";
import { SelectedDiv } from "../location-picker/LocationChoice";
import SelectionCircle from "../selection-circle/SelectionCircle";
import { Body1Light, Text2Roman, Title1Medium, Title2Medium } from "../typography/Typography";

interface IMarketingCardProps {
  time: number;
  cost: number;
  inventory: number;
  backgroundImage: string;
  selected?: boolean;
  onClick?: () => void;
}
const StyledTitle = styled(Title2Medium)(
  ({ theme }) => css`
    color: ${theme.colors.green.green25};
    margin-top: 35px;
    text-align: left;
  `
);
const StyledInventory = styled(Text2Roman)(
  ({ theme }) => css`
    color: ${theme.colors.red.red50};
  `
);
export const StyledBackground = styled.div<{ backgroundImage?: number }>(
  ({ theme, backgroundImage }) => css`
    background-image: url(${backgroundImage != undefined ? getLocationImage(backgroundImage): ""});
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 24px 0px 0px 0px;
    filter: opacity(25%);
  `
);

export const StyledBackgroundOverlay = styled.div<{ backgroundImage: string }>(
  ({ theme, backgroundImage }) => css`
    background-image: url(${getBackgroundImage(backgroundImage)});
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 155px;
    width: 100%;
    background-size: contain;
    background-position: right;
    z-index: 1;
    background-repeat: no-repeat;
  `
);
export const MarketingCard: React.FC<IMarketingCardProps> = ({
  cost,
  time,
  inventory,
  backgroundImage,
  selected,
  onClick,
}) => {

  const theme: any = useTheme();
  const currentLocation: any =  useSelector((state: IAppState) => state.loggingReducer.location12pm);

  return (
    <StyledMarketingCard selected={selected} onClick={onClick}>
      <div className="top-container">
        <SelectionCircle selected={selected} text={`This will cost €${cost}`} />
        <StyledTitle>{time}min</StyledTitle>
        <div className="inventory-container">
          <Donut value={inventory} size={28} thickness={4} backgroundColor={{ fill: theme.colors.white }} foregroundColor={{ fill: theme.colors.red.red25 }} />
          <StyledInventory>+{inventory}% inventory</StyledInventory>
        </div>
        
      </div>
      <div className="bottom-part">
        <StyledBackground backgroundImage={currentLocation}>
        </StyledBackground>
        
        <Illustration name={backgroundImage} className="overlay-marketing" />
      </div>
      {/* <StyledBackgroundOverlay backgroundImage={backgroundImage} /> */}
    </StyledMarketingCard>
  );
};

export const StyledMarketingCard = styled.div<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    width: 250px;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    min-height: 350px;
    max-height: 400px;
    margin: 0px 8px;
    position: relative;
    box-sizing: border-box;

    background-color: ${selected ? theme.colors.blue.blue100 : theme.colors.grey.grey25};
    color: ${selected ? theme.colors.white : theme.colors.blue.blue100};
    border: ${selected
      ? "2px solid " + theme.colors.blue.blue100
      : "2px solid " + theme.colors.grey.grey25};
    cursor: pointer;

    opacity: 0;
    animation: show 0.5s forwards;
    &:hover {
      border: ${"2px solid " + theme.colors.black};
    }
    .top-container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      .inventory-container {
        display: flex;
        align-items: center;
        margin-top: 8px;
        > svg {
          margin: 0px 8px;
        }
      }
    }
    .bottom-part {
      position: relative;
      display: flex;
      flex: 1;
      > svg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        height: 100%;
      }
    }

    @keyframes show {
      from {
        opacity: 0.5;
        transform: scale(0.1);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  `
);

import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as PopcornGuyEmpty } from "../../assets/images/illustrations/Businesses/PopcornGuyEmpty.svg"
import { ReactComponent as PopcornGuySmallBag } from "../../assets/images/illustrations/Businesses/PopcornGuySmallBag.svg"
import { ReactComponent as PopcornGuyMediumBag } from "../../assets/images/illustrations/Businesses/PopcornGuyMediumBag.svg"
import { ReactComponent as PopcornGuyLargeBag } from "../../assets/images/illustrations/Businesses/PopcornGuyLargeBag.svg"
import { ReactComponent as PopcornGuyTrophy } from "../../assets/images/illustrations/Businesses/PopcornGuyTrophy.svg"

import { ReactComponent as SouvenirLadyEmpty } from "../../assets/images/illustrations/Businesses/SouvenirLadyEmpty.svg";
import { ReactComponent as SouvenirLadyFlag } from "../../assets/images/illustrations/Businesses/SouvenirLadyFlag.svg";
import { ReactComponent as SouvenirLadyTrophy } from "../../assets/images/illustrations/Businesses/SouvenirLadyTrophy.svg";

import { ReactComponent as MissToyEmpty } from "../../assets/images/illustrations/Businesses/MissToyEmpty.svg";
import { ReactComponent as MissToyTeddy } from "../../assets/images/illustrations/Businesses/MissToyTeddy.svg";
import { ReactComponent as MissToyTrophy } from "../../assets/images/illustrations/Businesses/MissToyTrophy.svg";

import MorningImage from "../../assets/images/illustrations/TimeOfDay/morning.svg"
import NoonImage from "../../assets/images/illustrations/TimeOfDay/noon.svg"
import EveningImage from "../../assets/images/illustrations/TimeOfDay/evening.svg"
import RainingImage from "../../assets/images/illustrations/TimeOfDay/raining.svg" 
import { getBusinessColor } from "../illustration/Illustration";


import { ReactComponent as ChoirSmall } from "../../assets/images/illustrations/choir_small.svg"
import { ReactComponent as ClownsSmall} from "../../assets/images/illustrations/clown_small.svg"
import { ReactComponent as JazzSmall} from "../../assets/images/illustrations/jazz_small.svg"

import { ReactComponent as PopcornStand } from "../../assets/images/illustrations/popcorn_stand.svg"
import { ReactComponent as ToyStand} from "../../assets/images/illustrations/toy_stand.svg"
import { ReactComponent as SouvenirStand} from "../../assets/images/illustrations/souvenir_stand.svg"


interface ILeftIllustrationProps {
    timeOfDay?: "morning" | "noon" | "evening" | "raining" ;
    business?: number;
    withProp?: string;
    otherProp?: number; // TODO businessProp rename
    marketing?: number;
}



const DivContainer = styled.div` 
    position: absolute;
    top: 0px;
    height: 100vh;
    width: 100%;
    z-index: 0;

    .char {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
    
    .stand {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
`
const Background = styled.div<{ timeOfDay?: string, backgroundColor: string }>(
    ({ theme, timeOfDay, backgroundColor }) => `
        height: 100vh;
        width: 100%;
        background-color: ${backgroundColor};
        background-size: cover;
        background-image: url(${getImageTimeOfDay(timeOfDay)})

`)


export const LeftIllustration: React.FC<ILeftIllustrationProps> = ({ otherProp, timeOfDay, business, withProp, marketing }) => {
    return <DivContainer>
        <Background timeOfDay={timeOfDay} backgroundColor={!timeOfDay && business != undefined && business != -1 ? getBusinessColor(business) : "none"}/>
        {getCharacter(business, withProp, marketing, otherProp)};
    </DivContainer>
}



const getImageTimeOfDay = (timeOfDay?: string) => {

    switch(timeOfDay) {
        case "morning":
            return MorningImage
        case "noon":
            return NoonImage
        case "evening":
            return EveningImage
        case "raining":
            return RainingImage
    }
}

const getCharacter = (business?: number, withProp?: string, marketing?: number, otherProp?: number) => {

    if(marketing != undefined) {
        switch(marketing) {
            case 0:
                return <ChoirSmall className="char" />;
            case 1:
                return <ClownsSmall className="char" />;
            case 2:
                return <JazzSmall className="char" />;
            default:
                return <ClownsSmall className="char" />;
        }

    } else if(business != undefined) {
        switch(business) {
            case 0:
                if(withProp === "small") {
                    return <PopcornGuySmallBag className="char" />
                } else if(withProp === "medium") {
                    return <PopcornGuyMediumBag className="char" />
                } else if(withProp === "large") {
                    return <PopcornGuyLargeBag className="char" />
                } else if(withProp == "trophy") {
                    return <PopcornGuyTrophy className="char" />
                } else {
                    return <PopcornGuyEmpty className="char" />
                }
    
            case 1:
                if(withProp === "small") {
                    return <MissToyTeddy className="char" />
                } else if(withProp === "medium") {
                    return <MissToyTeddy className="char" />
                } else if(withProp === "large") {
                    return <MissToyTeddy className="char" />
                } else if(withProp == "trophy") {
                    return <MissToyTrophy className="char" />
                } else {
                    return <MissToyEmpty className="char" />
                }
    
            case 2:
                if(withProp === "small") {
                    return <SouvenirLadyFlag className="char" />
                } else if(withProp === "medium") {
                    return <SouvenirLadyFlag className="char" />
                } else if(withProp === "large") {
                    return <SouvenirLadyFlag className="char" />
                } else if(withProp == "trophy") {
                    return <SouvenirLadyTrophy className="char" />
                } else {
                    return <SouvenirLadyEmpty className="char" />
                }
    
            default:
                
        }
    } else {
        switch(otherProp) {
            case 0:
                return <PopcornStand className="stand" />
            case 1:
                return <ToyStand className="stand" />
            case 2:
                return <SouvenirStand className="stand" />
        }
    }
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IAppState } from "../../store";
import { ReactComponent as GiantWheel } from "../../assets/images/illustrations/big_ferris_wheel.svg";
import { ReactComponent as Tent } from "../../assets/images/illustrations/big_tent.svg";
import {
  getChart,
  getGameInfoRequest,
  kickPlayer,
} from "../../store/logging/actions";
import { api } from "../../sagas/logging";
import styled, { css } from "styled-components";
import PrimaryButton from "../../components/button/PrimaryButton";
import SecondaryButton from "../../components/button/SecondaryButton";
import { businesses } from "../../tokens/definitions";
import {
  Body1Light,
  Body2Light,
  Text2Bold,
  Text2Roman,
  Text3Bold,
  Text3Light,
  Title2Light,
  Title2Medium,
} from "../../components/typography/Typography";
import { resolve } from "dns";
import IconButton from "../../components/button/IconButton";
import Left from "../default/Left";
import { step_to_name } from "./utils";

export const SlidesContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    > * {
      flex: 1;
      margin: 12px;
      max-width: 800px;
      min-width: 400px;
    }
  `
);

export const GameMaster: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();


  useEffect(() => {
    getGameInfo();
    let interval = setInterval(() => {
      getGameInfo()
    }, 3000)  
    return () => clearInterval(interval)
  }, [location]);

  const gameInfo = useSelector(
    (state: IAppState) => state.loggingReducer.gameInfo
  );

  const getGameInfo = () => {
    const [_, gameId] = location.pathname.split("/gamemaster/");
    if (gameId) {
      dispatch(getGameInfoRequest(gameId.toUpperCase()));
    }
  };

  const resetGame = (gameid: string) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ gameid: gameid.toUpperCase() }),
    };
    fetch(api + "/resetgame", requestOptions)
      .then(() => getGameInfo());
  };
  const allowGameStart = (gameid: string) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ gameid: gameid.toUpperCase() }),
    };
    fetch(api + "/start_game", requestOptions)
      .then(() => getGameInfo());
  }

  return (

    <div className="page" style={{ width: "100%", display: "flex"}}>
      <Left className="left" flexWidth="0.5" backgroundColor="linear-gradient(180deg, #A0C2F5 0%, #BFD6F9 49.26%)">
        <GiantWheel style={{ position: "absolute", right: 0, height: "100%" }} />
        <Tent style={{ position: "absolute", left: "0px", bottom: 0, width: "60%" }} />
      </Left>

      <div style={{flex: "0.5"}}>
        <SlidesContainer>
          {gameInfo && gameInfo.game && (
            <SecondaryButton
              onClick={() => dispatch(getChart(gameInfo.game.game_id, ""))}
            >
              Download Slides
            </SecondaryButton>
          )}

          {gameInfo && gameInfo.game && (
            <div>
              <Title2Medium>'{gameInfo.game.game_id}' Game Info</Title2Medium>
              <Body1Light>Current Step: {gameInfo.game.step}</Body1Light>
              <br />

              <Body1Light>
                {gameInfo.game.step >= 4
                  ? "This game Has already started, please reset to allow new players"
                  : "This game hasn't started yet, you are free to join"}
              </Body1Light>


              {gameInfo.game.can_be_started && <div style={{margin: "24px 0px"}}>
                <Body1Light>The Players are free to start the game. Once the game has started, no new players will be able to join</Body1Light>
              </div>}
              <Title2Light>Players:</Title2Light>
              {gameInfo.players.map((pl: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text2Bold style={{ minWidth: "300px" }}>
                      {pl.player_id}
                    </Text2Bold>
                    <Text2Roman>
                      {pl.business_choice != -1
                        ? businesses[pl.business_choice] ?  businesses[pl.business_choice].name : "Unknown"
                        : "No Business"}
                    </Text2Roman>
                    {/* <Text2Roman>{pl.inventory_choice}</Text2Roman> */}
                    <Text2Bold>{step_to_name(pl.step)}</Text2Bold>
                    
                    <IconButton
                      stroke="black"
                      onClick={() => {
                        dispatch(
                          kickPlayer({
                            userId: pl.player_id,
                            gameId: gameInfo.game.game_id,
                          })
                        );
                      }}
                      name="close"
                    />
                  </div>
                );
              })}
            </div>
          )}
          {gameInfo && gameInfo.game && 
            <SecondaryButton onClick={() => resetGame(gameInfo.game.game_id)}>
            Reset Game
          </SecondaryButton>}

          {gameInfo && gameInfo.game && gameInfo.game.game_id.indexOf("ONLINE") !== -1 && !gameInfo.game.can_be_started && <SecondaryButton onClick={() => allowGameStart(gameInfo.game.game_id)}>
            Allow Game Start
          </SecondaryButton>}

          {/* Loading Game Slides, please wait for Document */}
          {/* {charts && charts.map((d:any)=> { 
                  return <img src={d} height="500" width="700" />
              })} */}
        </SlidesContainer>
      </div>
    </div>
  );
};

export default withRouter(GameMaster);



export const step_to_name = (step: number) => {
    console.log(step);
    if(step == 1) {
        return "Selecting Business"
    } 
    else if(step == 2) {
        return "Selecting Location"
    }
    else if(step == 3) {
        return "Selecting Inventory"
    }
    else if(step == 2) {
        return "Results 9am"
    }
    else if(step == 5) {
        return "Results 12pm"
    }
    else if(step == 6) {
        return "Selecting Location 2"
    }
    else if(step == 7) {
        return "Selecting Marketing Campaign"
    }
    else if(step == 8 || step == 9) {
        return "Results 4pm"
    }
    else if(step == 10) {
        return "Selecting Location 3"
    }
    else if(step == 11) {
        return "Selecting Price Markdown"
    }
    else if(step == 12 || step == 13) {
        return "Results End of Day"
    }
    else if(step == 14) {
        return "Reviewing actions"
    }
    else if(step == 15) {
        return "Checking Scores"
    } else {
        return step
    }
}
import React from "react";
import styled, { css } from "styled-components";

export const LocationList: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, ...props }) => {
  return <StyledLocationList id={props.id} onScroll={props.onScroll}>{children}</StyledLocationList>;
};
const StyledLocationList = styled.div<React.HTMLProps<HTMLDivElement>>(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0px 32px 151px 32px;
    padding: 100px 32px 20px 32px;
    overflow-y: auto;
    > * {
      margin: 8px;
    }
  `
);

export default LocationList;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styled, { css } from "styled-components";
import { setDemographics } from "../../store/logging/actions";
import BackButton from "../button/BackButton";
import SecondaryButton from "../button/SecondaryButton";
import { Text2Roman } from "../typography/Typography";

interface ITopBarProps {
  customBackOperation?: () => void;
  hide?: boolean;
  hideDemographics?: boolean;
  top?: number;
  hideBackButton?: boolean;
}
const Topbar: React.FC<ITopBarProps> = ({
  customBackOperation,
  hide,
  top,
  hideDemographics,
  hideBackButton
}) => {
  const dispatch = useDispatch();

  return (
    <StyledTopbar className="topbar" hide={hide} top={top}>
      {hideBackButton && <span></span>}
      {!hideBackButton && <BackButton operation={customBackOperation} />}
      {!hideDemographics && (
        <SecondaryButton
          onClick={() => {
            dispatch(setDemographics(true));
          }}
        >
          Key Demographics
        </SecondaryButton>
      )}
    </StyledTopbar>
  );
};
const StyledTopbar = styled.div<{ hide?: boolean,  top?: number }>(
  ({ theme, hide, top}) => css`
    display: flex;
    justify-content: space-between;
    padding: 24px 64px;
    
    position: absolute;
    background-color: ${theme.colors.white};
    transition: top 0.2s;
    width: 100%;
    box-sizing: border-box;
    top: ${hide ? "-100px" : "0px"};
    @media (max-width: 1300px) {
      padding: 24px 32px;
    }
  `
);

const TimesContainer = styled.div(
  () => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 82px;
    max-width: 820px;
    flex: 1;
  `
);

const StickyTopBar = styled(StyledTopbar)(
  ({ theme }) => css`
    position: sticky;
    padding: 0px 24px;
    justify-content: stretch;
    border-bottom: 1px solid ${theme.colors.grey.grey25};
    .center-container {
      display: flex;
      justify-content: center;
      flex: 1;
    }
    .back-button {
      position: absolute;
      left: 54px;
      top: 0px;
      height: 82px;
      @media (max-width: 1300px) {
        left: 22px;
      }
    }
  `
);
const TimeButton = styled(Text2Roman)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 6px;
    font-family: "neue-haas-grotesk-display",
    background-color: ${selected ? theme.colors.grey.grey25 : "transparent"};
    font-weight: ${selected ? 700 : 400};
  `
);
interface ITopBarWithActionsProps {
  onTimeButtonClick: (time: string) => void;
  selectedTimeButton: string;
}
export const TopbarWithActions: React.FC<ITopBarWithActionsProps> = ({
  onTimeButtonClick,
  selectedTimeButton,
}) => {
  return (
    <StickyTopBar className="custom-action-bar" style={{padding: "0px"}}>
      <BackButton />
      <div className="center-container">
        <TimesContainer>
          <TimeButton
            selected={selectedTimeButton == "9am"}
            onClick={() => onTimeButtonClick("9am")}
          >
            9am
          </TimeButton>
          <TimeButton
            selected={selectedTimeButton == "12pm"}
            onClick={() => onTimeButtonClick("12pm")}
          >
            12pm
          </TimeButton>
          <TimeButton
            selected={selectedTimeButton == "4pm"}
            onClick={() => onTimeButtonClick("4pm")}
          >
            4pm
          </TimeButton>
          {/* <TimeButton
            selected={selectedTimeButton == "6pm"}
            onClick={() => onTimeButtonClick("6pm")}
          >
            6pm
          </TimeButton> */}
        </TimesContainer>
      </div>
    </StickyTopBar>
  );
};

export default Topbar;

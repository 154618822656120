import { dispatch } from "d3";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { BusinessResume } from "../../components/business-resume/BusinessResume";

import SecondaryButton from "../../components/button/SecondaryButton";
import CTABig from "../../components/cta-big/CTABig";
import { Icon } from "../../components/icon/Icon";
import Illustration, {
  DivImage,
  KermisLogo,
  getBusinessImage,
  getBusinessColor,
  WhiteKermisLogo,
} from "../../components/illustration/Illustration";
import Picker from "../../components/picker/Picker";
import Topbar from "../../components/topbar/Topbar";
import {
  Title1Medium,
  H3,
  Title2Bold,
  Title2Light,
  Body1Light,
  Title2Medium,Body2Light, Title2LightSpan
} from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { businesses } from "../../tokens/definitions";
import { setBusiness } from "../../store/logging/actions";
import Left, { LeftTitle, LeftContent, LeftOverlayContainer, BackgroundLeft } from "../default/Left";

import "./business.scss";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";
import { api } from "../../sagas/logging";
export const Business: React.FC = () => {

  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  
  return (
    <div className="business page">
       <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>
          {activeSlideExplorer ? 
          <SlideExplorer /> :
          (business != undefined ? (
            <BusinessResume>
              <Title2Medium>Hurray!</Title2Medium>
              <Title2Light>You're now the manager of a {businesses[business].name}</Title2Light>
            </BusinessResume>
          ) : (
          <LeftContent>
            <div className="left-pre-selection">
              <Title2Bold className="section">Select Your Business</Title2Bold>
              <Body1Light>
                Before you take your place, you must select your business.
              </Body1Light>
            </div>
          </LeftContent>
          ))}
        </LeftOverlayContainer> 
        {!activeSlideExplorer && <LeftIllustration business={business} withProp="empty" />}
        
          
      </Left>
      {business != undefined ? (
        <BusinessAfterSelection />
      ) : (
        <div className="picker-horizontal">
          <Picker onClick={() => dispatch(
            setBusiness({
              gameId,
              userId,
              business: 0,
            })
          )} backgroundColor="#FFE3A6" backgroundOnHover="linear-gradient(to right, #FFEDCB 0%, #FFC65B 100%)">
            <DivImage backgroundImage={getBusinessImage(0)} />
            <div className="dummy-picker"></div>
            <div className="select-container">
              <BusinessPickerSelect businessNumber={0}>
                <Title2Light>Popcorn Stand</Title2Light>
              </BusinessPickerSelect>
            </div>
          </Picker>
          <Picker onClick={() => dispatch(
            setBusiness({
              gameId,
              userId,
              business: 2,
            })
          )} backgroundColor="#FF9482" backgroundOnHover="linear-gradient(to right, #E67865 0%, rgba(234, 127, 127, 0) 144.76%)">
          
            <div className="select-container" >
            <BusinessPickerSelect businessNumber={2}>
                <Title2Light>Souvenir Stand</Title2Light>
            </BusinessPickerSelect>
            </div>
            <div className="dummy-picker"></div>
            <DivImage className="right" backgroundImage={getBusinessImage(2)} />
          </Picker>
          <Picker onClick={() => dispatch(
            setBusiness({
              gameId,
              userId,
              business: 1,
            })
          )} backgroundColor="#C4CDFF" backgroundOnHover="linear-gradient(to right, #C4CDFF 0%, #6980FF 100%)">
            <DivImage backgroundImage={getBusinessImage(1)} />
            <div className="dummy-picker"></div>
            <div className="select-container">
            <BusinessPickerSelect businessNumber={1}>
              <Title2Light>Toy Stand</Title2Light>
            </BusinessPickerSelect>
            </div>
          </Picker>
        </div>
      )}
    </div>
  );
};
export const LeftPreSelection: React.FC = () => {
  return (
    <div className="left-pre-selection">
      <Title2Bold className="section">Select Your Business</Title2Bold>
      <Body1Light>
        Before you take your place, you must select your business.
      </Body1Light>
    </div>
  );
};
export const BusinessPickerSelect: React.FC<{ businessNumber: number, hovered?: boolean, children?: React.ReactNode }> = ({
  children,
  hovered,
  businessNumber,
}) => {
  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  return (
    <div className="business-picker-select">
      {children}
      <SecondaryButton>
        Select
      </SecondaryButton>
    </div>
  );
};

let inter: any;
export const BusinessAfterSelection: React.FC = () => {
  
  const dispatch = useDispatch();
  const currentStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );
  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );

  const [canBeStarted, setCanBeStarted] = useState(false);

  const checkGameStart = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ gameid: currentGame.toUpperCase() }),
    };
    fetch(api + "/check_start_game", requestOptions)
    .then((d) => d.json())
    .then((d) => setCanBeStarted(d.canBeStarted))
    .catch(err => console.log(err))
  }

  useEffect(() => {
    if(currentGame) {
      inter = setInterval(() => {
        checkGameStart();
      }, 5000);
      checkGameStart();
    }
    return () => {
      clearInterval(inter);
    }
  }, [currentGame])

  useEffect(() => {
    if(canBeStarted) {
      clearInterval(inter);
    }
  }, [canBeStarted])


  const theme: any = useTheme();
  console.log(canBeStarted)
  return (
    <div className="business-after-selection">
      <Topbar
        hideBackButton
        customBackOperation={() => {
          dispatch(
            setBusiness({
              business: undefined,
              gameId: currentGame,
              userId: currentName,
            })
          );
        }}
      />
      <div className="business-after-container">
        <div className="business-after-selection-inside">
          <Title2Light className="instruction">Get to Know your Business</Title2Light>
          <Body2Light className="instruction">
            Your business is simple. You buy inventory at €1 a unit, and sell 
            each for €2.
          </Body2Light>
          <Body2Light className="instruction">We can use past sales data to obtain key insights into your business!
          </Body2Light>
        </div>
      </div>
      <CTABig goToNextStep backgroundColor={theme.colors.red.red25} disabled={!canBeStarted}>
        <Icon name="right-arrow" />
        {canBeStarted && <Title2Light>Let's play</Title2Light>}
        {!canBeStarted && <Body1Light>Waiting for Game Master to start the game...</Body1Light>}
      </CTABig>
    </div>
  );
};
export default Business;

import React, { useEffect, useRef } from "react";

import styled, { css, DefaultTheme, useTheme } from "styled-components";
import { Text1Light } from "../typography/Typography";

interface ISliderProps {
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
}
const Slider: React.FC<ISliderProps> = ({ min, max, step, onChange }) => {
  const sliderInput = useRef(null);
  const theme: any = useTheme();

  const updateSlider = () => {
    if (sliderInput && sliderInput.current) {
      const current: any = sliderInput.current;

      if (current.max && current.min) {
        const max = +current.max!;
        const min = +current.min!;

        const value = ((current.value - min) / (max - min)) * 100;

        current.style.background =
          "linear-gradient(to right, " +
          theme.colors.alert.alert50 +
          " 0%, " +
          theme.colors.alert.alert50 +
          " " +
          value +
          "%, " +
          theme.colors.grey.grey50 +
          " " +
          value +
          "%, " +
          theme.colors.grey.grey50 +
          " 100%)";
      }
      onChange(current.value);
    }
  };

  useEffect(() => {
    updateSlider();
  }, []);
  return (
    <SliderContainer>
      <StyledSlider
        type="range"
        max={max}
        defaultValue={600}
        min={min}
        step={step}
        ref={sliderInput}
        onChange={(e) => updateSlider()}
      />
      <div className="limits-div">
        <Text1Light>{min}</Text1Light>
        <Text1Light>{max}</Text1Light>
      </div>
    </SliderContainer>
  );
};

const SliderContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    .limits-div {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: ${theme.colors.alert.alert50};
      }
    }
  `
);

const StyledSlider = styled.input(
  ({ theme }) => css`
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    outline: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(
      to right,
      ${theme.colors.alert.alert50} 0%,
      ${theme.colors.alert.alert50} 50%,
      ${theme.colors.grey.grey50} 50%,
      ${theme.colors.grey.grey50} 100%
    );
    border-radius: 1.3px;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: ${theme.colors.alert.alert50};
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: ${theme.colors.alert.alert50};
    }
  `
);

export default Slider;

import React from "react";
import styled, { css } from "styled-components";
import {
  TextInput,
  TextInputStyles,
  ITextInputProps,
  ErrorTextInput,
} from "@diana-ui/textinput";
import { Text1Bold, Text3Light } from "../typography/Typography";
import classnames from "classnames";
import { isNoSubstitutionTemplateLiteral } from "typescript";

// TODO fix this with Diana Text Input

const StyledTextInput = styled.input(
  ({ theme }) => css`
    font-size: 18px;
    border-radius: 6px;
    outline: none;
    height: 50px;
    box-shadow: none;
    border: ${"1px solid "+theme.colors.blue.blue100};
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 400;
    font-family: neue-haas-grotesk-display;
    &.error {
      border: ${"1px solid " + theme.colors.red.red25};
    }
  `
);

export const PrimaryTextInput = ({ ...props }) => {
  return <StyledTextInput {...props} />;
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const LabelDiv = styled.div(
  ({ theme }) => css`
  padding: 10px 0px;
  text-align: left;
  display: flex;
  flex-start;
  .missing {
    color: ${theme.colors.red.red25};
  }
`);
const InputError = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: ${theme.colors.red.red25};
  `
);
interface ITextInputWithLabelProps {
  label: string;
  error?: string;
}
export const TextInputWithLabel: React.FC<
  ITextInputWithLabelProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ label, error, value, ...props }) => {
  return (
    <Wrapper>
      <LabelDiv>
        <Text1Bold>{label}</Text1Bold>
        {(!value || (value as any).length == 0) && <Text1Bold className="missing">*</Text1Bold>}
      </LabelDiv>
      <StyledTextInput className={classnames({ error })} {...props} value={value} />
      <InputError className="error">
        {error ? <Text3Light>{error}</Text3Light> : <Text3Light>&nbsp;</Text3Light>}
      </InputError>
    </Wrapper>
  );
};

/*
const StyledTextInput = styled(TextInput)(
  ({ disabled, theme }) => css`
    border: 2px solid ${theme.colors.grey.grey100};
    border-radius: 10px;
    box-sizing: content-box;
    height: auto;
    padding: 0;
    &:hover,
    &.focus {
      border-color: ${theme.colors.black};
    }
    ${disabled &&
    css`
      background-color: ${theme.colors.white};
      &:hover,
      &.focus {
        border-color: ${theme.colors.grey.grey100};
      }
    `}
  `
);
export const PrimaryTextInput: React.FC<ITextInputProps> = ({
  label,
  ref,
  ...props
}) => {
  return <StyledTextInput {...props} />;
};
export default PrimaryTextInput;*/

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { IAppState } from "../../store";
import { setStep } from "../../store/logging/actions";
import Icon from "../icon/Icon";
import { Text1Light } from "../typography/Typography";
interface IBackButtonProps {
  operation?: () => void;
}
const BackButton: React.FC<IBackButtonProps> = ({ operation }) => {
  const dispatch = useDispatch();
  const currentStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );
  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  return (
    <StyledBackButton className="back-button"
      onClick={() => {
        if (operation) {
          operation();
        } else {
          dispatch(
            setStep({
              gameId: currentGame,
              userId: currentName,
              step: currentStep - 1,
              isBackStep: true
            })
          );
        }
      }}
    >
      <Icon name="rounded-left-arrow" />
      <Text1Light>Back</Text1Light>
    </StyledBackButton>
  );
};
const StyledBackButton = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    > svg {
      margin: 0px 10px;
    }
  `
);

export default BackButton;

import styled, { css } from "styled-components";
import { getBackgroundImage } from "../../components/illustration/Illustration";

interface ILeftProps {
  flexWidth?: string;
  backgroundColor?: string;
}

interface ILeftBackgroundProps {
  backgroundImage?: string;
  backgroundPosition?: string;
}

export const LeftOverlayContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const LeftTitle = styled.div(
  () => css`
    padding: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `
);

export const LeftContent = styled.div(

  ({ theme }) => css`
  padding: 0px 32px;
  text-align: left;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  .section {
    margin: 32px 0px;
  }
  .hightlight {
    color: ${theme.colors.red.red25};
  }
`)

export const BackgroundLeft = styled.div<ILeftBackgroundProps>(
  ({ theme, backgroundImage, backgroundPosition }) => css`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: ${backgroundPosition};
    background-image: url(${getBackgroundImage(backgroundImage || "")});
    flex: 1;
  `
);
const Left = styled.div<ILeftProps>(
  ({ theme, flexWidth, backgroundColor }) => css`
    background: ${backgroundColor ? backgroundColor : "white"};
    position: relative;
    flex: ${flexWidth ? flexWidth : 0.32};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
  `
);
export default Left;

import {
  IJoinGamePayload,
  IJoinGameRequest,
  JOIN_GAME_REQUEST,
  JOIN_GAME_SUCCESS,
  JOIN_GAME_ERROR,
  IJoinGameSuccess,
  IJoinGameSuccessPayload,
  ISetStep,
  ISetStepPayload,
  ISetStepSucess,
  SET_STEP_REQUEST,
  SET_STEP_SUCCESS,
  CHECK_GAME_REQUEST,
  ICheckGameSuccess,
  ICheckGameRequest,
  ICheckGameError,
  CHECK_GAME_SUCCESS,
  CHECK_GAME_ERROR,
  IGameRequest,
  IResetGame,
  RESET_GAME,
  SET_BUSINESS_REQUEST,
  ISetLocation,
  SET_LOCATION_REQUEST,
  ISetBusiness,
  ISetLocationPayload,
  ISetInventoryPayload,
  ISetInventory,
  SET_INVENTORY_REQUEST,
  IGetFinancialSuccess,
  GET_FINANCIAL_SUCCESS,
  IFinancial,
  IGetFinancialRequest,
  IGetFinancialRequestPayload,
  GET_FINANCIAL_REQUEST,
  IGetFinancialSuccessPayload,
  ISetMarketingPayload,
  ISetMarketingRequest,
  SET_MARKETING_REQUEST,
  SET_MARKETING,
  ISetMarketing,
  SET_MARKDOWN_REQUEST,
  ISetMarkdownRequest,
  ISetMarkdownRequestPayload,
  IGetBestActionsRequest,
  IGetBestActionsSuccess,
  GET_BEST_ACTIONS_REQUEST,
  IJoinGameError,
  SET_DEMOGRAPHICS,
  ISetDemographics,
  GET_BEST_ACTIONS_SUCCESS,
  IGetDemographicsRequest,
  GET_DEMOGRAPHICS_REQUEST,
  IGetDemographicsSuccess,
  GET_DEMOGRAPHICS_SUCCESS,
  IGetGameInfoRequest,
  IGetGameInfoSuccess,
  GET_GAME_INFO_REQUEST,
  GET_GAME_INFO_SUCCESS,
  IGetGameScoreRequest,
  IGetGameScoreSuccess,
  GET_GAME_SCORES_REQUEST,
  GET_GAME_SCORES_SUCCESS,
  ICanIMoveRequest,
  CAN_I_MOVE_REQUEST,
  ICanIMoveSuccess,
  CAN_I_MOVE_SUCCESS,
  ICanMovePayload,
  IGetChartRequest,
  GET_CHART_REQUEST,
  IGetChartSuccess,
  GET_CHART_SUCCESS,
  IKickPlayer,
  KICK_PLAYER_REQUEST,
  GET_SLIDE_OPTIONS,
  IGetSlideOptions,
  GET_SLIDE_OPTIONS_SUCCESS,
  IGetSlideOptionsSuccess,
  IGetSlide,
  GET_SLIDE,
  IGetSlideSuccess,
  GET_SLIDE_SUCCESS,
  IDiscoverSlide,
  DISCOVER_SLIDE,
  IDiscoverSlideSuccess,
  DISCOVER_SLIDE_SUCCESS,
} from "./types";

export const joinGameRequest = (
  payload: IJoinGamePayload
): IJoinGameRequest => ({
  type: JOIN_GAME_REQUEST,
  payload,
});
export const joinGameSuccess = (
  payload: IJoinGameSuccessPayload
): IJoinGameSuccess => ({
  type: JOIN_GAME_SUCCESS,
  payload,
});
export const joinGameError = (field: string, error: string): IJoinGameError => ({
  type: JOIN_GAME_ERROR,
  payload: {
    field, error
  },
});
export const setStep = (payload: ISetStepPayload): ISetStep => ({
  type: SET_STEP_REQUEST,
  payload,
});

export const setStepSuccess = (step: number, isBackStep: boolean): ISetStepSucess => ({
  type: SET_STEP_SUCCESS,
  payload: {
    step, isBackStep
  },
});

export const checkGameRequest = (payload: IGameRequest): ICheckGameRequest => ({
  type: CHECK_GAME_REQUEST,
  payload,
});

export const checkGameSuccess = (payload: number): ICheckGameSuccess => ({
  type: CHECK_GAME_SUCCESS,
  payload,
});

export const checkGameError = (): ICheckGameError => ({
  type: CHECK_GAME_ERROR,
});
export const resetGame = (): IResetGame => ({
  type: RESET_GAME,
});

// Game Actions
export const setBusiness = (payload: any): ISetBusiness => ({
  type: SET_BUSINESS_REQUEST,
  payload,
});
export const setLocation = (payload: ISetLocationPayload): ISetLocation => ({
  type: SET_LOCATION_REQUEST,
  payload,
});
export const setInventory = (payload: ISetInventoryPayload): ISetInventory => ({
  type: SET_INVENTORY_REQUEST,
  payload,
});
export const getFinancial = (
  payload: IGetFinancialRequestPayload
): IGetFinancialRequest => ({
  type: GET_FINANCIAL_REQUEST,
  payload,
});

export const getFinancialSuccess = (
  payload: IGetFinancialSuccessPayload
): IGetFinancialSuccess => ({
  type: GET_FINANCIAL_SUCCESS,
  payload,
});

export const setMarketing = (payload?: number): ISetMarketing => ({
  type: SET_MARKETING,
  payload,
});

export const setMarketingRequest = (
  payload: ISetMarketingPayload
): ISetMarketingRequest => ({
  type: SET_MARKETING_REQUEST,
  payload,
});

export const setMarkdownRequest = (
  payload: ISetMarkdownRequestPayload
): ISetMarkdownRequest => ({
  type: SET_MARKDOWN_REQUEST,
  payload,
});

export const getBestActions = (
  payload: IGameRequest
): IGetBestActionsRequest => ({
  type: GET_BEST_ACTIONS_REQUEST,
  payload,
});

export const getBestActionsSuccess = (
  payload: any
): IGetBestActionsSuccess => ({
  type: GET_BEST_ACTIONS_SUCCESS,
  payload,
});
export const setDemographics = (payload: boolean): ISetDemographics => ({
  type: SET_DEMOGRAPHICS,
  payload,
});

export const getDemographicsRequest = (payload: IGameRequest): IGetDemographicsRequest => ({
  type: GET_DEMOGRAPHICS_REQUEST,
  payload,
});

export const getDemographicsSuccess = (payload: any): IGetDemographicsSuccess => ({
  type: GET_DEMOGRAPHICS_SUCCESS,
  payload,
});

export const getGameInfoRequest = (payload: string): IGetGameInfoRequest => ({
  type: GET_GAME_INFO_REQUEST,
  payload,
});
export const getGameInfoSuccess = (payload: any): IGetGameInfoSuccess => ({
  type: GET_GAME_INFO_SUCCESS, 
  payload,
});


export const getGameScoresRequest = (payload: IGameRequest): IGetGameScoreRequest => ({
  type: GET_GAME_SCORES_REQUEST,
  payload,
});
export const getGameScoresSuccess = (score: number, scoreIndex: number, ranking: any[]): IGetGameScoreSuccess => ({
  type: GET_GAME_SCORES_SUCCESS, 
  payload: {
    score, scoreIndex, ranking
  },
});

export const canIMove = (payload: ISetStepPayload): ICanIMoveRequest => ({
  type: CAN_I_MOVE_REQUEST,
  payload
})

export const canIMoveSuccess = (payload: ICanMovePayload): ICanIMoveSuccess => ({
  type: CAN_I_MOVE_SUCCESS,
  payload
})

export const getChart = (gameId: string, slide: string): IGetChartRequest => ({
  type: GET_CHART_REQUEST,
  payload: {
    gameId, slide
  }
})

export const getChartSuccess = (payload: any): IGetChartSuccess => ({
  type: GET_CHART_SUCCESS,
  payload
})

export const kickPlayer = (payload: any): IKickPlayer => ({
  type: KICK_PLAYER_REQUEST,
  payload
})


export const getSlideOptions = (payload: any): IGetSlideOptions => ({
  type: GET_SLIDE_OPTIONS,
  payload
})

export const getSlideOptionsSuccess = (payload: any): IGetSlideOptionsSuccess => ({
  type: GET_SLIDE_OPTIONS_SUCCESS,
  payload
})


export const getSlide = (payload: any): IGetSlide => ({
  type: GET_SLIDE,
  payload
})

export const getSlideSuccess = (payload: any): IGetSlideSuccess => ({
  type: GET_SLIDE_SUCCESS,
  payload
})


export const discoverSlide = (payload: any): IDiscoverSlide => ({
  type: DISCOVER_SLIDE,
  payload
})

export const discoverSlideSuccess = (payload: any): IDiscoverSlideSuccess => ({
  type: DISCOVER_SLIDE_SUCCESS,
  payload
})
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Castle } from "../../assets/images/illustrations/castle_big.svg";
import { ReactComponent as Decor1 } from "../../assets/images/illustrations/decor1.svg";
import { ReactComponent as KermisPark } from "../../assets/images/parkmaster_logo.svg";
import { ReactComponent as MerryGoRound } from "../../assets/images/illustrations/merry_big.svg";

import { ReactComponent as Cloud1 } from "../../assets/images/illustrations/cloud1.svg";
import { ReactComponent as Cloud2 } from "../../assets/images/illustrations/cloud.svg";
import { ReactComponent as Cloud3 } from "../../assets/images/illustrations/cloud3.svg";

import { Title2Light } from "../typography/Typography";
import TweenMax, { Linear } from "gsap" 

const Background = styled.div`
    background: linear-gradient(180deg, #A0C2F5 0%, #BFD6F9 49.26%);
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: -1;
`   

const StyledCastle = styled(Castle)`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: auto;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100vh;
`
const StyledWelcomeTitle = styled(Title2Light)`
    margin-bottom: 5%;
`

const StyledMerryGoRound = styled(MerryGoRound)`
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: auto;
`

export const StyledLogo = styled(KermisPark)`
    height: 30vh;
    padding: 3% 0px;
    opacity: 0;
    animation: anim-logo 0.5s 0.3s forwards;
    @keyframes anim-logo {
        from {
            opacity: 0;
            transform: scale(0);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
`

const StyledCloud1 = styled(Cloud1)`
    position: absolute;
    top: 10%;
    right: 0px;
    width: auto;
`
const StyledCloud3 = styled(Cloud3)`
    position: absolute;
    top: 10%;
    left: 0px;
    width: auto;
`
const StyledCloud2 = styled(Cloud2)`
    position: absolute;
    bottom: 0px;
    right: 50%;
    width: auto;
`

export const WelcomeIllustration: React.FC = () => {


    useEffect(() => {
        var flag=document.querySelector(".flag");
        TweenMax.fromTo(flag, {x: 65}, { x: 0, repeat: -1, duration: 2, ease: "none"});
    }, [])
    return <Container>
        <Background />
        <StyledCloud1 height="30%" />
        <StyledCloud2 height="20%" />
        <StyledCloud3 height="30%" />
        <StyledCastle height="60%" />
        <StyledMerryGoRound height="50%" />
        <StyledWelcomeTitle>Welcome to</StyledWelcomeTitle>
        <Decor1 />
        <StyledLogo />
        <Decor1 />        
        <Title2Light>&nbsp;</Title2Light>

    </Container>
}

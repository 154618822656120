import React, { useState } from "react";
import Left, { LeftTitle, BackgroundLeft } from "../default/Left";
import { ReactComponent as Diversion } from "../../assets/images/diversion1.svg";
import {
  Title2Medium,
  Text6,
  Title2Light,
  Body2Bold,
  H4,
  Body2Light,
} from "../../components/typography/Typography";
import "./welcome.scss";

import styled, { css, useTheme } from "styled-components";
import CTABig from "../../components/cta-big/CTABig";
import { IAppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "../../store/logging/actions";
import { Icon } from "../../components/icon/Icon";
import { KermisLogo, WhiteKermisLogo } from "../../components/illustration/Illustration";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";

const WelcomeText = styled(Text6)(
  ({ theme }) => css`
    color: ${theme.colors.blue.blue100};
  `
);

const Instruction = styled(Body2Light)<{ highlight?: boolean }>(
  ({ theme, highlight }) => css`
    color: ${highlight ? theme.colors.red.red25 : theme.colors.blue.blue100};
  `
);


export const Welcome: React.FC = () => {
  const theme: any = useTheme();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );  
  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);
  return (
    <div className="page">
      <Left className="left" backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "#FFBA22"}>
        <LeftTitle>
          {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
          {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
        </LeftTitle>
        {activeSlideExplorer ? 
          <SlideExplorer /> :
          <BackgroundLeft backgroundImage="BigSeatRide" backgroundPosition="center"/>}
      </Left>
      <div className="major-right right welcome-right">
        <div className="center-right welcome-container">
          {gameId && <div className="welcome-container-information">
            <Title2Medium>How To Play</Title2Medium>
            <Instruction className="instruction">Today, you will run a business at the fair for a full day.</Instruction>
            <Instruction className="instruction">You’ll be asked to make plenty of important decisions. From the location of your business at the fair, to inventory management, marketing and pricing.</Instruction>
            <Instruction className="instruction">Don’t forget that data can play an important role in the decision-making process!</Instruction>
            {isOnlineGame(gameId) ? <Instruction className="instruction">Make sure to combine the right categories and granularities to obtain relevant data. We have plenty of valuable data that may just interest you. (You can see the data by clicking the "See Slides" button on the left)</Instruction> : <Instruction className="instruction">Please feel free to ask the game manager for relevant data. He has plenty of valuable data that may just interest you…</Instruction>}
          </div>}
        </div>
        <CTABig
          backgroundColor={theme.colors.red.red50}
          goToNextStep
        >
          <Icon name="right-arrow" />
          <Title2Light>Select your business</Title2Light>
        </CTABig>
      </div>
    </div>
  );
};
export default Welcome;

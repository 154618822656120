import React, { ReactComponentElement, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessResume } from "../../components/business-resume/BusinessResume";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import Slider from "../../components/slider/Slider";
import Topbar from "../../components/topbar/Topbar";
import {
  Title2Medium,
  Body2Light,
  Text2Bold,
  Text2Roman,
  Body1Light,
  Title2Bold,
  Title2Light,
} from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { setInventory, setMarkdownRequest } from "../../store/logging/actions";
import Left, { LeftTitle , LeftOverlayContainer } from "../default/Left";
import "./price-markdown.scss";
import styled, { css, useTheme } from "styled-components";
import Illustration, {
  getBackgroundImage,
  getBusinessColor,
  GetPriceImage,
  KermisLogo,getPriceMarkdownIllustration, WhiteKermisLogo
} from "../../components/illustration/Illustration";
import SelectionCircle from "../../components/selection-circle/SelectionCircle";
import Donut from "../../components/donut/Donut";
import { businesses, markdowns } from "../../tokens/definitions";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import {StyledBackgroundOverlay, StyledBackground } from "../../components/marketing-card/MarketingCard";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";

interface IPriceOption {
  cost: number;
  priceValue: string;
  inventoryEffect: number;
  amount: number;
  image: string;
}

const TitleSubtitle2 = styled(Text2Bold)(
  ({ theme }) => css`
    background-color: #05d973;
    padding: 8px 16px;
    margin-top: 18px;
    border-radius: 16px;
    color: ${theme.colors.white};
  `
);
const PriceCard = styled.div<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    display: flex;
    background-color: ${theme.colors.grey.grey25};
    border-radius: 16px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-width: 200px;
    margin: 8px;
    box-sizing: border-box;
    background-color: ${selected
      ? theme.colors.blue.blue100
      : theme.colors.grey.grey25};
    color: ${selected ? theme.colors.white : theme.colors.blue.blue100};
    border: ${selected
      ? "2px solid " + theme.colors.blue.blue100
      : "2px solid " + theme.colors.grey.grey25};
    cursor: pointer;
    &:hover {
      border: ${"2px solid " + theme.colors.black};
    }
  `
);
const InventoryOverviewTop = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    > * {
      margin-bottom: 24px;
    }
  `
);
const InventoryMiddleSection = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);
const TitleSubtitle = styled(Body2Light)(
  ({ theme }) => css`
    padding-top: 32px;
  `
);

const InventoryBottomSection = styled.div(
  () =>
    css`
      display: flex;
      align-items: center;
      > svg {
        margin: 0px 8px;
      }
    `
);
const InventoryOverviewCardsContainer = styled.div(
  () => css`
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    max-width: 800px;
    flex-wrap: wrap;
  `
);

const ColorTitle2Bold = styled(Title2Bold)<{ color: string }>(
  ({ color }) =>
    css`
      color: ${color};
      margin: 32px 0px 8px 0px;
    `
);
const ColorText2Bold = styled(Text2Bold)<{ color: string }>(
  ({ color }) =>
    css`
      color: ${color};
    `
);
const PriceCardTop = styled.div(
  () => css`
    padding: 24px;
  `
);
const PriceCardImage = styled.div(
  () => css`
    min-height: 159px;
    position: relative;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex: 1;
    > svg {
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: 1;
      height: 100%;
    }
  `
);

let prevScroll = 0;
export const PriceMarkdown: React.FC = () => {
  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );

  const markdown = useSelector(
    (state: IAppState) => state.loggingReducer.markdown
  );

  const inventory = useSelector(
    (state: IAppState) => state.loggingReducer.inventory
  );
  const salesUntil6pm = useSelector(
    (state: IAppState) => state.loggingReducer.financial.sales[2]
  );
  const pctSales = (salesUntil6pm / (inventory || 0)) * 100;
  const pctRemain = 100 - pctSales;
  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);

  const theme: any = useTheme();
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const currentLocation: any =  useSelector((state: IAppState) => state.loggingReducer.location4pm);
  const [visible, setVisible] = useState(true);  

  const handleScroll = (element: any) => {
    if(element) {
      const currentScrollPos = element.scrollTop;
  
      setVisible((prevScroll > currentScrollPos && prevScroll - currentScrollPos > 0) || currentScrollPos < 10);
      prevScroll = currentScrollPos
    }
  }

  useEffect(() => {

    const element = document.querySelector("#scroll-target");
    if(element) {
      element.addEventListener('scroll', () => handleScroll(element));
      return () => element.removeEventListener('scroll', () => handleScroll(element));
    } 
  }, []);
  return (
    <div className="page price-markdown">
      <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>
          {activeSlideExplorer ? 
          <SlideExplorer /> :
          <BusinessResume>
            {markdown != undefined && markdown > -1 ? <Body1Light>You selected {markdowns[markdown].description}</Body1Light> : 
            <Body1Light>The Park is about to close and you still have some inventory.</Body1Light>}
          </BusinessResume>}
        </LeftOverlayContainer>
        {!activeSlideExplorer && <LeftIllustration business={business} timeOfDay="noon" withProp={pctRemain > 60 ? "large" : pctRemain > 20 ? "medium" : pctRemain > 0 ? "small" : "none"}/>}
      </Left>
      <div className="price-markdown-right">
        <div className="price-markdown-content">
          <Topbar hide={!visible}/>
          <div className="price-markdown-middle" id="scroll-target">
            <div className="price-markdown-right-inside">
              <InventoryOverviewTop>
                <Title2Light>
                  Markdown the price of {businesses[business || 0].inventoryName} so you can improve your
                  profit
                </Title2Light>
                <Body2Light>
                  Don’t forget that you are currently selling each {businesses[business || 0].inventoryName} for
                  2 €
                </Body2Light>
              </InventoryOverviewTop>
              <div className="inventory-overview-middle">
                <InventoryOverviewCardsContainer>
                  {markdowns.map((option, index) => {
                    return (
                      <PriceCard
                        selected={index == markdown}
                        onClick={() => {
                          dispatch(
                            setMarkdownRequest({
                              gameId,
                              userId,
                              markdown: index,
                            })
                          );
                        }}
                      >
                        <PriceCardTop>
                          <SelectionCircle
                            selected={index == markdown}
                            text={`Markdown the price`}
                          />
                          <InventoryMiddleSection>
                            <ColorTitle2Bold color={theme.colors.green.green25}>
                              {option.priceValue}
                            </ColorTitle2Bold>
                          </InventoryMiddleSection> 
                          {/* <InventoryBottomSection>
                            <Donut value={Math.abs(option.inventoryEffect)} size={28} thickness={4} backgroundColor={{ fill: theme.colors.white }} foregroundColor={{ fill: theme.colors.red.red25 }} />
                            <Text2Roman>inventory</Text2Roman>
                          </InventoryBottomSection> */}
                        </PriceCardTop>
                        <PriceCardImage>
                          <StyledBackground backgroundImage={currentLocation}>
                          </StyledBackground>
                          {getPriceMarkdownIllustration(business || 0, index)}
                        </PriceCardImage>
                      </PriceCard>
                    );
                  })}
                </InventoryOverviewCardsContainer>
              </div>
            </div>
          </div>
        </div>
        
        <StyledProgressBar disabled={markdown == undefined || markdown == -1} text="End the day" />
      </div>
    </div>
  );
};
export default PriceMarkdown;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessResume } from "../../components/business-resume/BusinessResume";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import Slider from "../../components/slider/Slider";
import Topbar from "../../components/topbar/Topbar";
import {
  Title2Medium,
  Body2Light,
  Text2Bold,
  Text2Roman,
  Title2Bold,
  Title2Light,
} from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { setInventory } from "../../store/logging/actions";
import Left, { LeftOverlayContainer, LeftTitle } from "../default/Left";
import "./inventory-overview.scss";
import styled, { css, useTheme } from "styled-components";
import Illustration, { getBusinessColor, getInventoryIllustration, KermisLogo, WhiteKermisLogo } from "../../components/illustration/Illustration";
import Donut from "../../components/donut/Donut";
import Icon from "../../components/icon/Icon";
import { times } from "../../store/logging/reducer";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";

const TitleSubtitle2 = styled(Text2Bold)(
  ({ theme }) => css`
    background-color: #05d973;
    padding: 4px 8px;
    margin-top: 18px;
    border-radius: 6px;
    color: ${theme.colors.white};
  `
);
const InventoryCard = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    flex: 1;
    padding: 24px;
    border-left: ${"2px solid " + theme.colors.grey.grey25};
    &:last-child {
      border-right: ${"2px solid " + theme.colors.grey.grey25};
    }
  `
);
const InventoryOverviewTop = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `
);
const InventoryMiddleSection = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .middle-section-value {
      display: flex;
      align-items: center;
      > svg {
        margin-left: 8px;
      }
    }
  `
);
const TitleSubtitle = styled(Body2Light)(
  ({ theme }) => css`
    padding-top: 32px;
  `
);

const InventoryBottomSection = styled.div(
  () =>
    css`
      display: flex;
      align-items: center;
      > svg {
        margin: 0px 8px;
      }
    `
);
const InventoryOverviewCardsContainer = styled.div(
  () => css`
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    max-width: 800px;
  `
);

const ColorTitle2Bold = styled(Title2Bold)<{ color: string }>(
  ({ color }) =>
    css`
      color: ${color};
    `
);
const ColorText2Bold = styled(Text2Bold)<{ color: string }>(
  ({ color }) =>
    css`
      color: ${color};
    `
);

const StyledText2Roman = styled(Text2Roman)(
  () =>
    css`
      text-align: left;
    `
);
interface IInventoryOverviewProps {
  time: "9am" | "12pm" | "4pm" | "6pm";
  timeOfDay: "morning" | "noon" | "evening" | "raining" ;
  nextText?: string;
  leftText?: string;
}

let prevScroll = 0;
export const InventoryOverview: React.FC<IInventoryOverviewProps> = ({ timeOfDay, time, nextText, leftText }) => {
  const inventory = useSelector(
    (state: IAppState) => state.loggingReducer.inventory
  );

  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);
  const financial = useSelector(
    (state: IAppState) => state.loggingReducer.financial
  );
  const salesUntil6pm = useSelector(
    (state: IAppState) => state.loggingReducer.financial.sales[times.indexOf(time)]
  );

  const pctSales = (salesUntil6pm / (inventory || 0)) * 100;
  const pctRemain = 100 - pctSales;

  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const theme: any = useTheme();

  const [visible, setVisible] = useState(true);  

  const handleScroll = (element: any) => {
    if(element) {
      const currentScrollPos = element.scrollTop;
      
      setVisible((prevScroll > currentScrollPos && prevScroll - currentScrollPos > 0) || currentScrollPos < 10);
      prevScroll = currentScrollPos
    }
  }

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  useEffect(() => {
    const element = document.querySelector("#scroll-target");
    if(element) {
      element.addEventListener('scroll', () => handleScroll(element));
      return () => element.removeEventListener('scroll', () => handleScroll(element));
    } 
  }, []);


  return (
    <div className="page inventory-overview">
      <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>
          {activeSlideExplorer ? 
          <SlideExplorer /> :
          <BusinessResume>
            <Title2Light>{pctRemain > 60 ? "You still have a ton of inventory!" : pctRemain > 0 ? "You have some inventory left" : "You have no more inventory!"}</Title2Light>
          </BusinessResume>}
        </LeftOverlayContainer>
        {!activeSlideExplorer && <LeftIllustration business={business} timeOfDay={timeOfDay} withProp={pctRemain > 60 ? "large" : pctRemain > 20 ? "medium" : pctRemain > 0 ? "small" : "none"}/>}
      </Left>
      <div className="inventory-overview-right">
        <div className="inventory-overview-right-content">
          <Topbar hide={!visible}/>
          <div className="inventory-middle" id="scroll-target">
            <div className="inventory-overview-right-inside">
              <InventoryOverviewTop>
                <Title2Medium>Inventory Overview </Title2Medium>
                <TitleSubtitle>Your inventory so far</TitleSubtitle>
                <TitleSubtitle2>A better afternoon</TitleSubtitle2>
              </InventoryOverviewTop>
              <div className="inventory-overview-middle">
                <InventoryOverviewCardsContainer>
                  <InventoryCard>
                    <StyledText2Roman>Your Actions</StyledText2Roman>
                    <InventoryMiddleSection>
                      <div className="middle-section-value">
                        <ColorTitle2Bold color={theme.colors.yellow.yellow25}>
                          {inventory}{" "}
                        </ColorTitle2Bold>
                      </div>
                      {getInventoryIllustration(business || 0)}
                    </InventoryMiddleSection>
                    <InventoryBottomSection>
                      <Donut value={100} size={28} thickness={4} foregroundColor={{ fill: theme.colors.yellow.yellow25 }} />
                      <ColorText2Bold color={theme.colors.yellow.yellow25}>  
                        &nbsp;100%&nbsp;
                      </ColorText2Bold>
                      <Text2Roman>inventory</Text2Roman>
                    </InventoryBottomSection>
                  </InventoryCard>
                  <InventoryCard>
                    <StyledText2Roman>Total Sold Items</StyledText2Roman>
                    <InventoryMiddleSection>
                      
                      <div className="middle-section-value">
                        <ColorTitle2Bold color={theme.colors.green.green25}>
                          {salesUntil6pm}
                        </ColorTitle2Bold>
                        {/* <Icon name="up" /> */}
                      </div>
                      {getInventoryIllustration(business || 0)}
                    </InventoryMiddleSection>
                    <InventoryBottomSection>
                      <Donut value={pctSales} size={28} thickness={4} foregroundColor={{ fill: theme.colors.green.green25 }} />
                      <ColorText2Bold color={theme.colors.green.green25}>
                        {pctSales.toFixed(0)}%&nbsp;
                      </ColorText2Bold>
                      <Text2Roman>inventory</Text2Roman>
                    </InventoryBottomSection>
                  </InventoryCard>
                  <InventoryCard>
                    <StyledText2Roman>Current Inventory</StyledText2Roman>
                    <InventoryMiddleSection>
                      
                      <div className="middle-section-value">
                        <ColorTitle2Bold color={theme.colors.red.red25}>
                          {(inventory || 0) - salesUntil6pm}
                        </ColorTitle2Bold>
                        {/* <Icon name="down" /> */}
                      </div>
                      {getInventoryIllustration(business || 0)}
                    </InventoryMiddleSection>
                    <InventoryBottomSection>
                      <Donut value={pctRemain} size={28} thickness={4} foregroundColor={{ fill: theme.colors.red.red25 }} />
                      <ColorText2Bold color={theme.colors.red.red25}>
                        {pctRemain.toFixed(0)}%&nbsp;
                      </ColorText2Bold>
                      <Text2Roman>inventory</Text2Roman>
                    </InventoryBottomSection>
                  </InventoryCard>
                </InventoryOverviewCardsContainer>
              </div>
            </div>
          </div>
        </div>
        <StyledProgressBar text={nextText || ""} />
      </div>
    </div>
  );
};
export default InventoryOverview;

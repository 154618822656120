import React from "react";
import styled, { css, useTheme } from "styled-components";
import { Title2Light, Text4Bold, Text, Text4Light } from "../typography/Typography";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store";
import { resetGame, setStep } from "../../store/logging/actions";
import Icon from "../icon/Icon";


const stepsBefore5 = [
  { name: "Location", step: 2},
  { name: "Inventory", step: 3},
  { name: "Financial Overview", step: 4}
]
const stepsBefore8 = [
  {name: "Financial Overview", step: 5},
  {name: "Location", step: 6},
  {name: "Marketing Campaign", step: 7},
]
const stepsBefore12 = [
  {name: "Financial Overview", step: 8},
  {name: "Inventory Overview", step: 9},
  {name: "Location", step: 10},
  {name: "Price Markdown", step: 11},
]
const stepsBefore16 = [
  {name: "Inventory Overview", step: 12},
  {name: "Financial Overview", step: 13},
  {name: "My Actions", step: 14},
]

const steps = [
  { name: "Your business", step: 2 },

  { name: "Location", step: 3 },
  { name: "Inventory Overview", step: 4 },
  { name: "Financial Overview", step: 5 },
  { name: "Price Markdown", step: 6 },
];
export interface IProgressBarProps {
  text: string;
  disabled?: boolean;
  opBeforeSteping?: () => void;
  dontIncreaseStep?: boolean;
  endGame?: boolean;
}

const StyledTitle2 = styled(Title2Light)(
  ({ theme }) => css`
    margin-left: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);
const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: 44px;
  `
);
export const ProgressBar: React.FC<IProgressBarProps> = ({
  text,
  disabled,
  opBeforeSteping,
  dontIncreaseStep,
  endGame,
}) => {
  const step = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );

  const dispatch = useDispatch();
  const currentStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );
  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const theme: any = useTheme();
  return (
    <div className="progress-bar-container">
      <ProgressBarContainer
        disabled={disabled}
        onClick={() => {
          // Check if there's an operation to be done before progressing
          if (!disabled && opBeforeSteping) {
            opBeforeSteping();
          }
          if (endGame) {
            dispatch(resetGame());
          } else if(!disabled && !dontIncreaseStep) {
            dispatch(
              setStep({
                gameId: currentGame,
                userId: currentName,
                step: currentStep + 1,
              })
            );
          }
        }}
      >
        <Icon name="right-arrow" />
        <StyledTitle2>{text}</StyledTitle2>
      </ProgressBarContainer>
      {/* <ProgressBarLine progress={(step || 0) / 15 * 100}>
        <div className="progress-line-inside" />
      </ProgressBarLine> */}
      {/* <ProgressBarBottom>
        <div className="progress-steps">
          {step && step < 5 && stepsBefore5.map((stepObject) => {
            return step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            (<Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>)
          })}
          {step && step >= 5 && step < 8 && stepsBefore8.map((stepObject) => {
            return step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            (<Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>)
          })}
          {step && step >= 8 && step < 12 && stepsBefore12.map((stepObject) => {
            return step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            (<Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>)
          })}
          {step && step >= 12 && step < 15 && stepsBefore16.map((stepObject) => {
            return step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            (<Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>)
          })}
          
        </div>
        <Text4Bold>{getPartOfDay(step || 0)}</Text4Bold>
      </ProgressBarBottom> */}
      <ProgressBarBottom>
        <div className="progress-steps">
          <div className="progress-dummy">
          <div className="border-div full" />
          </div>
          {step && step < 5 && stepsBefore5.map((stepObject) => {
            return <div key={stepObject.step}>
              <div className={classnames("border-div", { full: stepObject.step < step, half: step == stepObject.step})}></div>
              {step == stepObject.step ? <Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold> :
                                         <Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>}
              </div>
          })}
          {step && step >= 5 && step < 8 && stepsBefore8.map((stepObject) => {
            return <div key={stepObject.step}>
              <div className={classnames("border-div", { full: stepObject.step < step, half: step == stepObject.step})}></div>
              {step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            <Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>}
            </div>
          })}
          {step && step >= 8 && step < 12 && stepsBefore12.map((stepObject) => {
            return <div key={stepObject.step}>
              <div className={classnames("border-div", { full: stepObject.step < step, half: step == stepObject.step})}></div>
              {step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            <Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>}
            </div>
          })}
          {step && step >= 12 && step < 15 && stepsBefore16.map((stepObject) => {
            return <div key={stepObject.step}>
              <div className={classnames("border-div", { full: stepObject.step < step, half: step == stepObject.step})}></div>
              {step == stepObject.step ? (<Text4Bold key={stepObject.name}>{stepObject.name}</Text4Bold>) :
            (<Text4Light key={stepObject.name}>{stepObject.name}</Text4Light>)}
            </div>
          })}
          
        </div>
        <div className="day-subtitle">
          <Text4Bold>{getPartOfDay(step || 0)}</Text4Bold>
        </div>

      </ProgressBarBottom>

      
    </div>
  );
};

const getPartOfDay = (step:number) => {
  
  if(step < 5) {
    return "9am: before the opening";
  } else if(step < 8) {
    return "12pm: a slow day so far"
  } else if(step < 12) {
    return "4pm: a better afternoon"
  } else {
    return "6pm a better afternoon"
  }
  return "";
}

const ProgressBarLine = styled.div<{ progress: number }>(
  ({ theme, progress }) => css`
    height: 5px;
    background-color: ${theme.colors.white};
    position: relative;
    .progress-line-inside {
      position: absolute;
      top: 0px;
      height: 5px;
      border-right: 5px solid ${theme.colors.red.red100};
      left: 0px;
      width: ${progress}%;
      background-color: ${theme.colors.red.red50};
    }
  `
);

const ProgressBarContainer = styled.div<{ disabled?: boolean }>(
  ({ theme, disabled }) => css`
    max-height: 108px;
    background-color: ${disabled
      ? theme.colors.grey.grey50
      : theme.colors.blue.blue100};
    color: ${disabled
      ? theme.colors.grey.grey100
      : theme.colors.warning.warning50};
    cursor: ${disabled ? "normal" : "pointer"};
    display: flex;
    justy-content: center;
    align-items: center;
    padding: 24px 64px;

    @media (max-width: 1300px) {
      padding: 24px 32px;
    }
    .icon {
      stroke: ${disabled ? theme.colors.grey.grey100 : theme.colors.yellow.yellow25};
    }
  `
);
const ProgressBarBottom = styled.div(
  ({ theme }) => css`
    height: 42px;
    display: flex;
    background-color: ${theme.colors.white};
    justify-content: space-between;
    .day-subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 64px;
      @media (max-width: 1300px) {
        padding-right: 32px;
      }
    }
    .progress-steps {
      display: flex;
      > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &.progress-dummy {
          min-width: 48px;
          @media (max-width: 1300px) {
            min-width: 26px;
          }
        }
        .border-div {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 5px;
          width: 0%;
          background-color: ${theme.colors.red.red50};
          &.full {
            width: 100%;
          }
          &.half {
            width: 0%;
            border-right: 5px solid ${theme.colors.red.red100};
            animation: slide-width 0.5s forwards;
          }
        }
      }
      span {
        margin: 0px 16px;
      }
    }

    @keyframes slide-width {
      from {
        width: 0%;
      }
      to {
        width: 50%;
      }
    }
  `
);

const StyledProgressBar = styled(ProgressBar)(
  ({ theme }) => css`
    border: 1px solid;
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
    font-size: 16px;
    padding: 7px 16px;
    border-radius: 17px;
    background-color: transparent;
    &:hover {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
    }
  `
);

export default StyledProgressBar;

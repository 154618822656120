import React, { useEffect } from "react";
import {ReactComponent as Morning } from "../../assets/images/illustrations/map/morning.svg";
import {ReactComponent as Cloudy } from "../../assets/images/illustrations/map/rain.svg";
import {ReactComponent as Afternoon } from "../../assets/images/illustrations/map/afternoon.svg";
import {ReactComponent as Evening } from "../../assets/images/illustrations/map/evening.svg";
import "./map.scss";
import * as d3 from "d3";

{/* <ellipse cx="260" cy="769.5" rx="130" ry="18.5" fill="black" fill-opacity="0.15"></ellipse> */}
const locations: any[] = [
    { name: "Circus", viewBox: "140 600 250 200",  transform: { k: 2.0000000000000013, x: -247.42329992623866, y: -838.3353758754374}},
    { name: "Ballon Ride", viewBox: "370 60 150 150", transform: { k: 3.031433133020798, x: -830.0397791027231, y: -29.40040147463344 }},
    { name: "Merry Go Around", viewBox: "0 800 200 60", transform:{k: 3.0314331330208004, x: -6.1365706416532, y: -2068.718364041766}},
    { name: "Castle", viewBox: "0 800 200 60", transform: {k: 2.297396709994071, x: -676.6408116330347, y: -720.218891651595}},
    { name: "Ferris Wheel", viewBox: "0 800 200 60", transform: {k: 3.4822022531845014, x: -6.6988706347545985, y: -1339.7439699068061}},
    { name: "Bumper Ride", viewBox: "0 800 200 60", transform: {k: 2.63901582154579, x: -402.8657924505562, y: -957.128005304779}},
    { name: "Rollercoaster", viewBox: "0 800 200 60", transform: {k: 2.2973967099940724, x: -282.4555342684632, y: -556.7374392211619}},
    { name: "Poney Ride", viewBox: "0 800 200 60", transform: {k: 3.0314331330208004, x: -750.1365706416532, y: -2068.718364041766}},
]



interface IMapFocusProps {
    name?: string | null;
    timeOfDay?: "location9am" | "location12pm" | "location4pm"
}
export const Map:React.FC<IMapFocusProps> = ({ name, timeOfDay }) => {
    // return <div className="container">

    const zoom: any = d3.zoom()
    .extent([[0, 0], [1000, 1000]])
    .scaleExtent([1, 8])
    .on("zoom", zoomed);

    
    function zoomed(e: any) {
        const svg = d3.select("#container");
        const g = svg.select("g")
        g.transition().duration(1000).ease(d3.easeSin).attr("transform", e.transform);
    }

    useEffect(() => {
        const loc = locations.find(d => d.name == name);
        if(loc) { 
            const t = d3.zoomIdentity.translate(loc.transform.x, loc.transform.y).scale(loc.transform.k);
            d3.select("#container").call(zoom.transform, t)
            // d3.select("#container").select(".grab").interrupt().transition().duration(1000).ease(d3.easeSin).attr("transform", loc.transform) //.attr("viewBox", loc.viewBox);
        } else {
            d3.select("#container").call(zoom.transform, d3.zoomIdentity) //.attr("viewBox", "0 0 523 1000");
        }
    }, [name])

    switch(timeOfDay) {
        case "location9am":
            return <Morning id="container" style={{position: "absolute", height: "100%"}}/>
        case "location12pm":
            return <Cloudy id="container" style={{position: "absolute", height: "100%"}}/>
        case "location4pm": 
            return <Afternoon id="container" style={{position: "absolute", height: "100%"}}/>
        default:
            return <Evening id="container" style={{position: "absolute", height: "100%"}}/>
    }
}
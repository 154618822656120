import React from "react";
import styled, { css } from "styled-components";
import {
  BaseButton,
  BaseButtonStyles,
  IBaseButtonProps,
} from "@diana-ui/button";
import Icon from "../icon/Icon";

const StyledButton = styled(BaseButton).attrs((props) => ({}))(
  ({ theme, disabled, renderLeftIcon, renderRightIcon }) => css`
    border: 1px solid;
    border-color: ${theme.colors.black};
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
    font-size: 16px;
    padding: 12px 24px;
    padding: 7px 16px;
    border-radius: 100px;

    .icon {
      stroke: ${theme.colors.white};
      margin-left: 4px;
    }
    &:hover {
      color: ${theme.colors.black};
      background-color: ${theme.colors.white};

      .icon {
        stroke: ${theme.colors.black};
      }
    }
  `
);
const PrimaryButton: React.FC<IBaseButtonProps> = ({ ...props }) => {
  return <StyledButton {...props} />;
};
export default PrimaryButton;



export const businesses = [
    {inventoryName: "Popcorn Bags", name: "Popcorn Seller", inventoryImage: "BagCorn"},
    {inventoryName: "Teddy Bears", name: "Toy Store", inventoryImage: "Teddy"},
    {inventoryName: "Souvenir Windmills", name: "Souvenir Stand", inventoryImage: "Windmill"},
]
export const locationList = [
    { description: "Positioned in the center of the park", name: "Circus", image: "Circus" },
    { description: "Positioned at the right back of the park", name: "Castle", image: "Castle" },
    { description: "Positioned at the left back of the park", name: "Ferris Wheel", image: "FerrisWheel" },
    { description: "Positioned at the left side of the entrance", name: "Merry Go Around", image: "MerryGoRound" },
    { description: "Positioned at the center of the park", name: "Bumper Ride", image: "BumperRide" },
    { description: "Positioned at the back of the park", name: "Rollercoaster", image: "Rollercoaster" },
    { description: "Positioned at the entrance to the park", name: "Pony Ride", image: "HorseRide" },
    { description: "Positioned at the back of the park", name: "Ballon Ride", image: "Ballon" },
]

export const marketingCampaigns= [
    { description: "", name: "Choir Group", costs: [20, 40, 60] },
    { description: "", name: "Clown Crew", costs: [30, 60, 90]},
    { description: "", name: "Jazz Band", costs: [85, 100, 115]}
]

export const marketingCampaignsDuration = [
    { duration: "20min", description: "20 min"},
    { duration: "40min", description: "40 min"},
    { duration: "60min", description: "60 min"}
]

export const actions = [
]

export const markdowns = [

    {
        cost: 50,
        priceValue: "0%",
        inventoryEffect: 0,
        newPrice: 2,
        amount: 1,
        image: "PopcornSmall",
        description: "no discount on the price",
        name: "0%"
      },
      {
        cost: 50,
        priceValue: "-10%",
        inventoryEffect: -20,
        newPrice: 1.8,
        amount: 2,
        image: "PopcornMedium",
        description: "a 10% discount on the price",
        name: "10%"
      },
      {
        cost: 50,
        priceValue: "-20%",
        inventoryEffect: -40,
        newPrice: 1.6,
        amount: 3,
        image: "PopcornLarge",
        description: "a 20% discount on the price",
        name: "20%"
      }, 
      {
        cost: 50,
        priceValue: "-30%",
        inventoryEffect: -60,
        newPrice: 1.4,
        amount: 3,
        image: "PopcornLarge",
        description: "a 30% discount on the price",
        name: "30%"
      }, 
      
      {
        cost: 50,
        priceValue: "-40%",
        inventoryEffect: -80,
        newPrice: 1.2,
        amount: 3,
        image: "PopcornLarge",
        description: "a 40% discount on the price",
        name: "40%"
      }, 
      
      {
        cost: 50,
        priceValue: "-50%",
        inventoryEffect: -100,
        newPrice: 1,
        amount: 3,
        image: "PopcornLarge",
        description: "a 50% discount on the price",
        name: "50%"
      }, 
]
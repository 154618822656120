import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
// import { Body2Bold, Body2Light, Title2Bold, Title3Bold } from "@metyis-ds/typography";
// import Layout from "@components/layouts/Layout";
// import { MButton } from "@metyis-ds/button";
// import { Icon } from "@metyis-ds/icon";
import { Body2Bold } from "../typography/Typography";

interface IUserPromptModalProps {
  show: boolean;
  src: string;
  name: string;
  grans: string[];
  onClose: () => void;
}

const ImageModalInside: React.FC<IUserPromptModalProps> = ({
  show,
  name,
  grans,
  src,
  onClose,
//   onNo,
//   onYes
}) => {
  const [el, setEl] = useState<Element | undefined>(undefined);

  useEffect(() => {
    if (!el) {
      const el = document.createElement("div");
      setEl(el);

      const modalEl = document.getElementById("modal-root");
      modalEl?.appendChild(el);
      return () => {
        modalEl?.removeChild(el);
      };
    }
  }, []);

  const children = (
    <div style={{ position: "absolute", height: "100vh", width: "100vw", zIndex: "10", top: "0px", left: "0px" }}>
      <div style={{ justifyContent: "center", height: "100vh", alignItems: "center" }} className="flex items-center justify-center h-screen">
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "grey", opacity: "70%"}} onClick={onClose}/>
        <div style={{ width: "auto", backgroundColor: "white", zIndex: "20", borderRadius: "10px"}} className=" w-auto bg-white-light z-10 rounded-lg">
          <div style={{ height: "100%", backgroundColor: "white", borderRadius: "10px"}}  className="rounded-lg bg-white-light h-full">
            <div style={{ justifyContent: "center", alignItems: "center", padding: "4px 12px 0px 12px"}} className="flex flex-row justify-between items-center px-8 pt-4 w-full">
              <Body2Bold>{name}</Body2Bold>
              <a target="_blank" href={`/slide/${grans.join("_")}`}>Open in new Tab</a>
            </div>
            <img src={src} style={{ width: "50vw", borderRadius: "10px" }} className="w-[50vw] rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
  return show && el ? createPortal(children, el) : <></>;
};

interface IImageModalProps {
    src: string,
    name: string;
    grans: string[];
    showModal: boolean;
    setShowModal: (s: boolean) => void;
}

const ImageModal: React.FC<IImageModalProps> = ({ src, grans, name, showModal, setShowModal }) => {

  useEffect(() => {
    window.onbeforeunload = async e => {
      setShowModal(true);
      return;
    };
  }, []);
  return (
    <div>
      <ImageModalInside
        grans={grans}
        name={name}
        show={showModal}
        src={src}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default ImageModal;

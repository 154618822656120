import { defaultPalette } from "../palettes";
import { FontWeight } from "./theme.default.constants";

export const spaceUnit = 16;
const fontSize = 18;
const fontFamily = "NeueHaasDisplay";

 
export default {
  mode: "light",
  name: "default_theme",
  colors: defaultPalette,
  fontFamily,
  fontSize,
  fonts: {},
  spaceUnit: {
    xxs: `${spaceUnit * 0.25}px`,
    xs: `${spaceUnit * 0.5}px`,
    sm: `${spaceUnit * 0.75}px`,
    md: `${spaceUnit}px`,
    lg: `${spaceUnit * 1.5}px`,
    xl: `${spaceUnit * 2}px`,
    xxl: `${spaceUnit * 3}px`,
    xxxl: `${spaceUnit * 4}px`,
  },
  spacing: {
    squishsm: {
      top: `${spaceUnit / 4}px`,
      left: `${spaceUnit / 1.3}px`,
    },
    squishmd: {
      top: `${spaceUnit / 2}px`,
      left: `${spaceUnit}px`,
    },
    squishlg: {
      top: `${spaceUnit}px`,
      left: `${spaceUnit * 2}px`,
    },
    stretchsm: {
      left: `${spaceUnit / 4}px`,
      top: `${spaceUnit / 1.3}px`,
    },
    stretchmd: {
      left: `${spaceUnit / 2}px`,
      top: `${spaceUnit}px`,
    },
    stretchlg: {
      left: `${spaceUnit}px`,
      top: `${spaceUnit * 2}px`,
    },
  },
  typography: {
    header1bold: {
      fontSize: "80px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
    },
    header1roman: {
      fontSize: "80px",
      fontWeight: 450,
      fontFamily: "neue-haas-grotesk-display",
    },
    title1medium: {
      fontSize: "64px",
      lineHeight: "64px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
    },
    title1light: {
      fontSize: "64px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },
    title2bold: {
      fontSize: "48px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
    },
    title2medium: {
      fontSize: "48px",
      fontWeight: 600,
      fontFamily: "neue-haas-grotesk-display",
    },
    title2light: {
      fontSize: "48px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },

    body1bold: {
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
      fontSize: "32px",
    },
    body1light: {
      fontSize: "32px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },


    body2bold: {
      fontSize: "24px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
    },
    body2light: {
      fontSize: "24px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },

    text1bold: {
      fontSize: "18px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
    },
    text1light: {
      fontSize: "18px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },
    text2bold: {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-display",
    },
    text2roman: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },
    text2romanunderline: {
      fontSize: "16px",
      fontWeight: 400,
      textDecoration: "underline",
      fontFamily: "neue-haas-grotesk-display",
    },
    text3bold: {
      fontSize: "14px",
      fontWeight: 700,
      fontFamily: "neue-haas-grotesk-text",
    },
    text3light: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-text",
    },
    text4bold: {
      fontSize: "12px",
      fontWeight: 700, 
      fontFamily: "neue-haas-grotesk-display",
    },
    text4light: {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-display",
    },
    text6: {
      fontSize: "25px",
      fontWeight: 300,
      lineHeight: "30px",
      fontFamily,
    },
    pageTitle: {
      fontSize: "43px",
      fontWeight: FontWeight.BOLD,
      lineHeight: "46px",
      fontFamily,
    },
    subtitle: {
      fontSize: "31px",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "36px",
      fontFamily,
    },
    sectionTitle: {
      fontSize: "21px",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "28px",
      fontFamily,
    },
    buttonText: {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "neue-haas-grotesk-text",
    },
    bodyHighlight: {
      fontSize: "18px",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "24px",
      fontFamily,
    },
    descriptionMedium: {
      fontSize: "15px",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "16px",
      fontFamily,
    },
    description: {
      fontSize: "15px",
      fontWeight: FontWeight.REGULAR,
      lineHeight: "20px",
      fontFamily,
    },
    labelMedium: {
      fontSize: "0.66rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "0.66rem",
      fontFamily,
    },
    label: {
      fontSize: "0.66rem",
      fontWeight: FontWeight.REGULAR,
      lineHeight: "0.66rem",
      fontFamily,
    },
    notificationsNumbers: {
      fontSize: "10px",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "10px",
      fontFamily,
    },
    // CF-DESIGN-SYSTEM SPECIFIC
    detailText: {
      fontSize: "0.9rem", // 16px
      fontWeight: FontWeight.REGULAR,
      lineHeight: "0.9rem",
      fontFamily,
    },
    h1: {
      fontSize: "80px", // 48px
      fontWeight: FontWeight.BOLD,
      lineHeight: "80px",
      fontFamily,
    },
    h2: {
      fontSize: "45px", // 39px
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "45px",
      fontFamily,
    },
    h4: {
      fontSize: "43px",
      fontWeight: 300,
      lineHeight: "51px",
      fontFamily,
    },
    highlightText: {
      fontSize: "1.35rem", // 24px
      fontWeight: FontWeight.REGULAR,
      lineHeight: "1.35rem",
      fontFamily,
    },
    smallHeading: {
      fontSize: "0.9rem", // 16px
      fontWeight: FontWeight.BOLD,
      lineHeight: "0.9rem",
      fontFamily,
    },
    smallText: {
      fontSize: "0.725rem", // 13px
      fontWeight: FontWeight.REGULAR,
      lineHeight: "0.725rem",
      fontFamily,
    },
    subHeading: {
      fontSize: "1.725rem", // 31px
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "1.725rem",
      fontFamily,
    },
    text2: {
      fontSize: "12px",
      fontFamily: "NeueHaasDisplayRoman",
    },
    text: {
      fontSize: "12px", // 20px
      fontWeight: FontWeight.REGULAR,
      lineHeight: "1.125rem",
      fontFamily,
    },
  },
  breakpoints: {
    MOBILE_1: 700,
    MOBILE_2: 900,
    TABLET_1: 1140,
  },
};

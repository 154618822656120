import { dispatch } from "d3";
import { takeLatest, put, delay } from "redux-saga/effects";
import {
  joinGameSuccess,
  checkGameError,
  setStepSuccess,
  getFinancialSuccess,
  //setLocation,
  joinGameError,
  getBestActionsSuccess,
  getDemographicsSuccess,
  getGameInfoSuccess,
  getGameScoresSuccess,
  canIMove,
  setStep as setStepAction,
  canIMoveSuccess,
  getChartSuccess,
  getSlideOptionsSuccess,
  discoverSlideSuccess,
  getSlideSuccess,
  checkGameSuccess,
} from "../store/logging/actions";
import {
  IJoinGameRequest,
  JOIN_GAME_REQUEST,
  ISetStep,
  SET_STEP_REQUEST,
  ICheckGameRequest,
  SET_BUSINESS_REQUEST,
  CHECK_GAME_REQUEST,
  ISetBusiness,
  SET_LOCATION_REQUEST,
  ISetLocation,
  SET_INVENTORY_REQUEST,
  ISetInventory,
  GET_FINANCIAL_REQUEST,
  IGetFinancialRequest,
  ISetMarketingRequest,
  SET_MARKETING_REQUEST,
  SET_MARKDOWN_REQUEST,
  ISetMarkdownRequest,
  IGetBestActionsRequest,
  GET_BEST_ACTIONS_REQUEST,
  GET_DEMOGRAPHICS_REQUEST,
  IGetDemographicsRequest,
  IGetGameInfoRequest,
  GET_GAME_INFO_REQUEST,
  GET_GAME_SCORES_REQUEST,
  IGetGameScoreRequest,
  CAN_I_MOVE_REQUEST,
  ICanIMoveRequest,
  IGetChartRequest,
  GET_CHART_REQUEST,
  KICK_PLAYER_REQUEST,
  IKickPlayer,
  IGetSlideOptions,
  GET_SLIDE_OPTIONS,
  IDiscoverSlide,
  IGetSlide,
  DISCOVER_SLIDE,
  GET_SLIDE,
} from "../store/logging/types";

export const api = "https://kermis-server.azurewebsites.net";
// export const api = "http://localhost:8080";

const request = (endpoint: string, body: any) => {
  return fetch(api + endpoint, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });
};

interface ResponseGenerator{
  config?:any,
  data?:any,
  headers?:any,
  request?:any,
  status?:number,
  statusText?:string
}

function* joinGame(action: IJoinGameRequest): any {
  try {
    const result = yield fetch(api + "/joingame", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        gameid: action.payload.gameId,
        userid: action.payload.name,
        code: action.payload.code,
        reset: action.payload.reset
      }),
    })
    console.log(result.status);
    if (result.status === 401) {
      try {
        yield put(
          joinGameError(
            "name",
            "This user is already in this game room. Please pick another name"
          )
        );
      } catch (err) {
        yield put(joinGameError("game", "Unknown Login Error"));
      }
    } else if (result.status == 501) {
      yield put(
        joinGameError("game", "This game already started, you can not join")
      );
    } else if (result.status == 400) {
      yield put(joinGameError("code", "Wrong code inserted"));
    } else if (result.status == 502) {
      yield put(joinGameError("game", "This is not a valid game room."));
    } else {
      const resultJson = yield result.json();

      // Check if it's an existing game
      if (resultJson.business_choice != null) {
        yield put(
          joinGameError(
            "game",
            "This Name is already being used. Please pick a different name"
          )
        );
      } else {
        yield put(
          joinGameSuccess({
            currentGame: resultJson.currentGame,
            currentStep: resultJson.currentStep,
            currentName: resultJson.currentName,
            currentRandomId: resultJson.currentRandomId
          })
        );
      }
    }
  } catch (err) {
    console.log("Error request", err);
  }
}
function* setStep(action: ISetStep): any {
  try {
    const result = yield fetch(api + "/setstep", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        gameid: action.payload.gameId,
        userid: action.payload.userId,
        step: action.payload.step,
      }),
    });
    const resultJson = yield result.json();
    yield put(
      setStepSuccess(resultJson.step, action.payload.isBackStep || false)
    );
  } catch (err) {
    console.log("Error setting step");
  }
}

function* checkGame(action: ICheckGameRequest): any {
  try {
    const result = yield fetch(api + "/checkgame", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        gameid: action.payload.gameId,
        userid: action.payload.userId,
        random: action.payload.random
      }),
    });
    if(result.status == 200) {
      const resJson = yield result.json()
      console.log("Chek Game Success")
      yield put(checkGameSuccess(resJson));
    } else {
      console.log("Check Game Error")
      yield put(checkGameError());
    }
  } catch (err) {
    console.log("Error checking Game ", err);
    yield put(checkGameError());
  }
}

function* setBusiness(action: ISetBusiness) {
  try {
    yield request("/setbusiness", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      business: action.payload.business,
    });
  } catch (err) {
    console.log("Error");
  }
}
function* setLocationSaga(action: ISetLocation) {
  try {
    yield request("/setlocation", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      location: action.payload.location,
      time: action.payload.time,
    });
  } catch (err) {
    console.log("Error setting location");
  }
}
function* setInventorySaga(action: ISetInventory): any {
  try {
    const result = yield request("/setinventory", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      inventory: action.payload.inventory,
      step: action.payload.step,
    });
    const resultJson = yield result.json();
    yield put(setStepSuccess(resultJson.step, false));
  } catch (err) {
    console.log("Error setting inventory");
  }
}
function* getFinancial(action: IGetFinancialRequest): any {
  try {
    const result = yield request("/getfinancial", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      time: action.payload.time,
    });
    const resultJson = yield result.json();
    yield put(
      getFinancialSuccess({
        time: action.payload.time,
        costs: resultJson.costs,
        profit: resultJson.profit,
        sales: resultJson.sales,
      })
    );
  } catch (err) {
    console.log("Error", err);
  }
}
function* setMarketingSaga(action: ISetMarketingRequest) {
  try {
    yield request("/setmarketing", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      marketing: action.payload.marketing,
      marketing_duration: action.payload.marketingDuration,
    });
    // yield delay(2000)
    // yield put(setStepAction({
    //   gameId: action.payload.gameId,
    //   userId: action.payload.userId,
    //   step: 8 //  HARDCODED
    // }))
  } catch (err) {
    console.log("ERror");
  }
}
function* setPriceMarkdown(action: ISetMarkdownRequest) {
  try {
    yield request("/setmarkdown", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      markdown: action.payload.markdown,
    });
  } catch (err) {
    console.log("ERror");
  }
}
function* getBestActionsSaga(action: IGetBestActionsRequest): any {
  try {
    const result = yield request("/beststrategy", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
    });
    const resultJson = yield result.json();
    console.log(resultJson);
    yield put(getBestActionsSuccess(resultJson));
  } catch (err) {
    console.log("Error");
  }
}
function* getDemographics(action: IGetDemographicsRequest): any {
  try {
    const result = yield request("/getdemographics", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
    });
    const resultJson = yield result.json();
    yield put(getDemographicsSuccess(resultJson.demand));
  } catch (err) {
    console.log("Error");
  }
}

function* getGameInfo(action: IGetGameInfoRequest): any {
  try {
    const result = yield request("/game", {
      gameid: action.payload,
    });
    const resultJson = yield result.json();

    const parsed: any = {};
    parsed.game = JSON.parse(resultJson.game);
    parsed.players = resultJson.players.map((p: string) => JSON.parse(p));
    yield put(getGameInfoSuccess(parsed));
  } catch (err) {
    yield put(
      getGameInfoSuccess({
        game: { game_id: action.payload },
        players: [],
      })
    );
    console.log("Error");
  }
}

function* getCharts(action: IGetChartRequest): any {
  try {
    console.log("Game id ", action.payload.gameId);
    const result = yield request("/slides", {
      gameid: action.payload.gameId,
    })
      .then((res) => res.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Slides.pdf";
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      });
    // yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 0}));
  } catch (err) {}
}

function* getChartsÕld(action: IGetChartRequest): any {
  // TODO fix this
  try {
    console.log("Game id ", action.payload.gameId);
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide1",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 0 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide2",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 1 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide3",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 2 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide4",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 3 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide5",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 4 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide6",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 5 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide7",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 6 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide8",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 7 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide9",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 8 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide10",
    }).then((res) => res.blob());
    yield put(getChartSuccess({ blob: URL.createObjectURL(result), index: 9 }));
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide11",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 10 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide12",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 11 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide12",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 11 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide13",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 12 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide14",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 13 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide15",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 14 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide16",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 15 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide17",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 16 })
    );
  } catch (err) {}
  yield delay(2000);

  try {
    const result = yield request("/charts_daily_average_ticket_sales", {
      gameid: action.payload.gameId,
      slide: "slide18",
    }).then((res) => res.blob());
    yield put(
      getChartSuccess({ blob: URL.createObjectURL(result), index: 17 })
    );
  } catch (err) {}
  yield delay(2000);
}

function* getGameScores(action: IGetGameScoreRequest): any {
  try {
    const result = yield request("/scores", {
      gameid: action.payload.gameId,
    });
    let resultJson = yield result.json();

    resultJson = resultJson.map((d: any) => {
      return JSON.parse(d);
    });

    // Sort
    const orderedResults = resultJson
      .slice()
      .sort((a: any, b: any) => (a.score > b.score ? -1 : 1));

    // Find myself
    let scoreIndex =
      orderedResults.findIndex(
        (d: any) => d.player_id === action.payload.userId
      ) + 1;
    let score = orderedResults.find(
      (d: any) => d.player_id === action.payload.userId
    ).score;

    console.log(orderedResults, scoreIndex);

    yield put(getGameScoresSuccess(score, scoreIndex, orderedResults));
    // yield put(getGameScore(resultJson))
  } catch (err) {
    console.log("Error");
  }
}
function* kickPlayer(action: IKickPlayer) {
  try {
    yield request("/kickuser", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
    });
  } catch (err) {
    console.log("Error");
  }
}

function* getSlideOptions(action: IGetSlideOptions): any {
  try {

    const result = yield request("/get_slide_hints", {
      gameid: action.payload.gameId
    });
    let resultJson = yield result.json();
    yield put(getSlideOptionsSuccess(resultJson));
  } catch (err) {
    console.log("Error");
  }
}


function* getSlide(action: IGetSlide): any {
  try {
    const result = yield request("/slide", {
      index: action.payload.index,
      gameid: action.payload.gameId
    });
    let resultingBlob = yield result.blob();
    let img = URL.createObjectURL(resultingBlob);
    yield put(getSlideSuccess(img));
  } catch (err) {
    console.log("Error");
  }
}


function* discoverSlide(action: IDiscoverSlide): any {
  try {
    const result = yield request("/discover_slide", {
      granularities: action.payload.granularities
    });
    let resultJson = yield result.json();
    yield put(discoverSlideSuccess(resultJson));
  } catch (err) {
    console.log("Error");
  }
}

function* canIMoveSaga(action: ICanIMoveRequest): any {
  try {
    const result = yield request("/canimove", {
      gameid: action.payload.gameId,
      userid: action.payload.userId,
      step: action.payload.step,
    });
    let resultJson = yield result.json();
    yield put(
      canIMoveSuccess({
        canMove: resultJson.move,
        numberPlayersTotal: resultJson.numPlayersTotal,
        numberPlayersConfirmed: resultJson.numPlayersReady,
      })
    );
  } catch (err) {
    console.log("Error");
  }
}
function* loggingWatcher() {
  yield takeLatest(CHECK_GAME_REQUEST, checkGame);
  yield takeLatest(JOIN_GAME_REQUEST, joinGame);
  yield takeLatest(SET_STEP_REQUEST, setStep);
  yield takeLatest(SET_BUSINESS_REQUEST, setBusiness);
  yield takeLatest(SET_LOCATION_REQUEST, setLocationSaga);
  yield takeLatest(SET_INVENTORY_REQUEST, setInventorySaga);

  yield takeLatest(GET_FINANCIAL_REQUEST, getFinancial);
  yield takeLatest(SET_MARKETING_REQUEST, setMarketingSaga);

  yield takeLatest(SET_MARKDOWN_REQUEST, setPriceMarkdown);

  yield takeLatest(GET_BEST_ACTIONS_REQUEST, getBestActionsSaga);

  yield takeLatest(GET_DEMOGRAPHICS_REQUEST, getDemographics);
  yield takeLatest(GET_GAME_INFO_REQUEST, getGameInfo);
  yield takeLatest(GET_GAME_SCORES_REQUEST, getGameScores);

  yield takeLatest(CAN_I_MOVE_REQUEST, canIMoveSaga);
  yield takeLatest(GET_CHART_REQUEST, getCharts);
  yield takeLatest(KICK_PLAYER_REQUEST, kickPlayer);

  yield takeLatest(GET_SLIDE_OPTIONS, getSlideOptions);
  yield takeLatest(GET_SLIDE, getSlide);
  yield takeLatest(DISCOVER_SLIDE, discoverSlide);
}
export default loggingWatcher;

import React from "react";
import { ReactComponent as PopcornGuy } from "../../assets/images/illustration1.svg";
import { ReactComponent as ToyGirl } from "../../assets/images/illustration2.svg";
import { ReactComponent as SouvenirGirl } from "../../assets/images/illustration3.svg";
import { ReactComponent as Circus } from "../../assets/images/12pm/Location/Circus.svg";
import { ReactComponent as BagCorn } from "../../assets/images/illustrations/BagCorn.svg";
import { ReactComponent as MediumBagCorn } from "../../assets/images/illustrations/medium_bag_corn.svg";
import { ReactComponent as LargeBagCorn } from "../../assets/images/illustrations/large_bag_corn.svg";


import { ReactComponent as TeddyBear } from "../../assets/images/illustrations/teddy.svg";
import { ReactComponent as MediumTeddyBear } from "../../assets/images/illustrations/medium_teddy_bear.svg";
import { ReactComponent as LargeTeddyBear } from "../../assets/images/illustrations/large_teddy_bear.svg";

import { ReactComponent as Windmill } from "../../assets/images/illustrations/windmill.svg";
import { ReactComponent as MediumWindmill } from "../../assets/images/illustrations/medium_windmill.svg";
import { ReactComponent as LargeWindmill } from "../../assets/images/illustrations/large_windmill.svg";

import { ReactComponent as SouvenirLadyFlag } from "../../assets/images/illustrations/Businesses/SouvenirLadyFlag.svg";
import { ReactComponent as MissToyTeddy } from "../../assets/images/illustrations/Businesses/MissToyTeddy.svg";
import { ReactComponent as PopcornSmall } from "../../assets/images/illustrations/popcorn_small.svg";
import { ReactComponent as PopcornMedium } from "../../assets/images/illustrations/popcorn_medium.svg";
import { ReactComponent as PopcornLarge } from "../../assets/images/illustrations/popcorn_large.svg";

// import PopcornSmall from "../../assets/images/illustrations/popcorn_small.svg";
// import PopcornMedium from "../../assets/images/illustrations/popcorn_medium.svg";
// import PopcornLarge from "../../assets/images/illustrations/popcorn_large.svg";


import { ReactComponent as Wheel } from "../../assets/images/wheel.svg";
import BigSeatRide from "../../assets/images/illustrations/big_seat_ride.svg"
import styled, { css } from "styled-components";
import CircusImage from "../../assets/images/12pm/Location/Circus.svg";
import PopcornGuyImage from "../../assets/images/illustration1.svg";
import ToyGirlImage from "../../assets/images/illustration2.svg";
import SouvenirGirlImage from "../../assets/images/illustration3.svg";
import WheelImage from "../../assets/images/12pm/12pm/Location/Image.svg";
import Diversion from "../../assets/images/diversion2.svg";
import Location1 from "../../assets/images/location1.svg";
import { ReactComponent as Singing1} from "../../assets/images/choir1.svg";
import { ReactComponent as Singing2} from "../../assets/images/choir2.svg";
import { ReactComponent as Singing3} from "../../assets/images/choir3.svg";
import {ReactComponent as Jazz1} from "../../assets/images/jazz1.svg";
import { ReactComponent as Jazz2 } from "../../assets/images/jazz2.svg";
import {ReactComponent as Jazz3} from "../../assets/images/jazz3.svg";
import {ReactComponent as Clown1} from "../../assets/images/clown1.svg";
import { ReactComponent as Clown2 } from "../../assets/images/clown2.svg";
import {ReactComponent as Clown3 } from "../../assets/images/clown3.svg";
import WelcomeImage from "../../assets/images/welcomeImage.svg";
import DemographicsGuy from "../../assets/images/demographics_guy.svg";
import Adviser from "../../assets/images/adviser.svg";
import BumperRide from "../../assets/images/illustrations/bumper_ride.svg";
import MerryGoRound from "../../assets/images/illustrations/merry_go_round.svg";
import HorseRide from "../../assets/images/illustrations/horse_ride.svg";

// LOCATIONS
import CastleImage from "../../assets/images/castle.svg";
import FerrisWheel from "../../assets/images/ferris_wheel.svg";
import Rollercoaster from "../../assets/images/rollercoaster.svg";
import DuckyRide from "../../assets/images/ducky_ride.svg";
import BallonImage from "../../assets/images/ballon.svg";

import Image9am from "../../assets/images/9amloading.svg";
import Image12pm from "../../assets/images/12pmloading.svg";
import Image4pm from "../../assets/images/4pmloading.svg";
import Image6pm from "../../assets/images/6pmloading.svg";
import WelcomeToKermis from "../../assets/images/illustrations/welcome_to_kermis.svg";
import Kermis from "../../assets/images/parkmaster_logo_extended.svg";
import { ReactComponent as KermisL } from "../../assets/images/parkmaster_logo_extended.svg";

// BUSINESS
// import Business0 from "../../assets/images/business-0.svg"
import Business0Small from "../../assets/images/Popcorn_Man_Banner.svg"
import Business1Small from "../../assets/images/illustrations/Businesses/Characters & Icons/State=Flag.svg"
import Business2Small from "../../assets/images/business-2-small.svg"
import Business0 from "../../assets/images/business-0.svg"
import Business1 from "../../assets/images/business-1.svg"
import Business2 from "../../assets/images/business-2.svg"

// MARKETING
import Marketing0 from "../../assets/images/clowns.svg"
import Marketing1 from "../../assets/images/choir.svg"
import Marketing2 from "../../assets/images/jazz.svg"

import { ReactComponent as PopcornGuy1 } from "../../assets/images/popcorn-1.svg";
import { ReactComponent as PopcornGuy2 } from "../../assets/images/popcorn-2.svg";
import { ReactComponent as PopcornGuy3 } from "../../assets/images/popcorn-3.svg";

export const getOpenerImage = (imageIndex: number) => {
  switch (imageIndex) {
    case 0:
      return WelcomeToKermis
    case 1:
      return Image9am;
    case 2:
      return Image12pm;
    case 3:
      return Image4pm;
    case 4:
      return Image6pm;
    default:
      return Image9am;
  }
};
export const getBusinessImage = (businessIndex: number) => {
  switch (businessIndex) {
    case 0:
      return Business0;
    case 1:
      return Business2;
    case 2:
      return Business1;
    default:
      return Business0;
  }
};
export const getMarketingImage = (index: number) => {
  switch (index) {
    case 0:
      return Marketing1;
    case 1:
      return Marketing0;
    case 2:
      return Marketing2;
    default:
      return Marketing0;
  }
};

export const getInventoryIllustration = (index: number, height: string = "46", items: number = 1) => {
  switch(index) {
    case 0:
      if(items < 400) {
        return <Illustration name="BagCorn" height={height} />
      } else if(items < 600) {
        return <Illustration name="MediumBagCorn" height={height} />
      } else {
        return <Illustration name="LargeBagCorn" height={height} />
      }
    case 1:
      if(items < 400) {
        return <Illustration name="Teddy" height={height} />
      } else if(items < 600) {
        return <Illustration name="MediumTeddy" height={height} />
      } else {
        return <Illustration name="LargeTeddy" height={height} />
      }
    case 2:
      if(items < 400) {
        return <Illustration name="Windmill" height={height} />
      } else if(items < 600) {
        return <Illustration name="MediumWindmill" height={height} />
      } else {
        return <Illustration name="LargeWindmill" height={height} />
      }
    default:
      return <Illustration name="BagCorn" height={height} />
  }
}

export const getPriceMarkdownIllustration = (index: number, items: number = 1) => {
  switch(index) {
    case 0:
      if(items == 0) { 
        return <Illustration name="PopcornSmall" className="price-markdown-illustration" />
      } else if(items == 1) {
        return <Illustration name="PopcornMedium" className="price-markdown-illustration"/>
      } else {
        return <Illustration name="PopcornLarge" className="price-markdown-illustration"/>
      }
    case 1:
      return <Illustration name="MissToyTeddy" className="price-markdown-illustration"/>
    case 2:
      return <Illustration name="SouvenirLadyFlag" className="price-markdown-illustration"/>
    default:
      if(items == 0) {
        return <Illustration name="PopcornSmall" className="price-markdown-illustration"/>
      } else if(items == 1) {
        return <Illustration name="PopcornMedium" className="price-markdown-illustration"/>
      } else {
        return <Illustration name="PopcornLarge" className="price-markdown-illustration"/>
      }
  }
}

export const getMarketingName = (index: number) => {
  switch (index) {
    case 0:
      return "Choir Group";
    case 1:
      return "Clow Crew";
    case 2:
      return "Jazz Band";
    default:
      return "";
  }
}

export const getBusinessImageResume = (businessIndex: number) => {
  switch (businessIndex) {
    case 0:
      return Business0Small;
    case 1:
      return Business2Small;
    case 2:
      return Business1Small;
    default:
      return Business0Small;
  }
};

export const getBusinessImageMarketingResume = (businessIndex: number) => {
  switch (businessIndex) {
    case 0:
      return Business0Small;
    case 1:
      return Business2Small;
    case 2:
      return Business1Small;
    default:
      return Business0Small;
  } 
};



interface IGetPriceImage {
  business: number;
  amount: number;
}
export const GetPriceImage: React.FC<IGetPriceImage> = ({
  business,
  amount,
}) => {
  switch (business) {
    case 0:
      switch (amount) {
        case 1:
          return <PopcornGuy1 />;
        case 2:
          return <PopcornGuy2 />;
        case 3:
          return <PopcornGuy3 />;
        default:
          return <PopcornGuy1 />;
      }
    default:
      break;
  }
  return <PopcornGuy />;
};

export const getLocationImage = (location: number) => {

  switch(location) {
    case 0:
    return CircusImage
    case 1:
    return CastleImage
    case 2:
      return FerrisWheel
    case 3:
      return MerryGoRound
    case 4:
      return BumperRide
    case 5:
      return Rollercoaster
    case 6:
      return HorseRide
    case 7:
      return BallonImage
    default:
      return BallonImage
  }
}
export const getBackgroundImage = (image: string) => {
  switch (image) {
    case "Circus":
      return CircusImage;
    case "FerrisWheel":
      return WheelImage;
    case "Rollercoaster":
      return Rollercoaster;
    case "PonyRide":
      return DuckyRide;
    case "Castle":
      return CastleImage;
    case "Ballon":
      return BallonImage;
    case "Location1":
      return Location1;
    case "BigSeatRide":
      return BigSeatRide;
    
    case "WelcomeImage":
      return WelcomeImage;
    case "DemographicsGuy":
      return DemographicsGuy;
    case "BumperRide":
      return BumperRide;
    case "MerryGoRound":
      return MerryGoRound;
    case "HorseRide":
      return HorseRide;
    case "Adviser": 
      return Adviser;
    // case "PopcornSmall":
    //   return PopcornSmall;
    // case "PopcornMedium":
    //   return PopcornMedium;
    // case "PopcornLarge":
    //   return PopcornLarge;
    default:
      return CircusImage;
  }
};
export const getBusinessColor = (businessIndex: number) => {
  switch (businessIndex) {
    case 0:
      return "#FFECC1";
    case 1:
      return "#FDC5BD";
    case 2:
      return "#E3E8F3";
    default:
      return "white";
  }
};

export interface IIllustrationProps {
  name: string;
  height?: string;
  className?: string;
}
export const Illustration: React.FC<IIllustrationProps> = ({
  name,
  height,
  className,
}) => {
  switch (name) {
    case "PopcornGuy":
      return <PopcornGuy className={className} />;
    case "ToyGirl":
      return <ToyGirl className={className} />;
    case "SouvenirGirl":
      return <SouvenirGirl className={className} />;
    case "Circus":
      return <Circus />;
    case "BagCorn":
      return <BagCorn className={className} height={height} />;
    case "MediumBagCorn":
      return <MediumBagCorn className={className} height={height} />;
    case "LargeBagCorn":
      return <LargeBagCorn className={className} height={height} />;
    case "Teddy":
      return <TeddyBear className={className} height={height} />;
    case "MediumTeddy":
      return <MediumTeddyBear className={className} height={height} />;
    case "LargeTeddy":
      return <LargeTeddyBear className={className} height={height} />;
    case "Windmill":
      return <Windmill className={className} height={height} />;
    case "MediumWindmill":
      return <MediumWindmill className={className} height={height} />;
    case "LargeWindmill":
      return <LargeWindmill className={className} height={height} />;
    case "Jazz1":
      return <Jazz1 className={className} height={height}/>;
    case "Jazz2":
      return <Jazz2 className={className} height={height}/>;
    case "Jazz3":
      return <Jazz3 className={className} height={height}/>;
    case "Singing1":
      return <Singing1 className={className} />
    case "Singing2":
      return <Singing2 className={className} />
    case "Singing3":
      return <Singing3 className={className} />
    case "Clown1":
      return <Clown1 className={className} height={height} />
    case "Clown2":
      return <Clown2 className={className} height={height} />
    case "Clown3":
      return <Clown3 className={className} height={height} />
    case "SouvenirLadyFlag":
      return <SouvenirLadyFlag className={className} />
    case "MissToyTeddy":
      return <MissToyTeddy className={className} />
    case "PopcornSmall":
      return <PopcornSmall className={className} />
    case "PopcornMedium":
      return <PopcornMedium className={className} />
    case "PopcornLarge":
      return <PopcornLarge className={className} />
    default:
      return <PopcornGuy />;
  }
};

export const DivImage = styled.div<{ backgroundImage: string }>(
  ({ backgroundImage }) => css`
    background-size: cover;
    width: 60%;
    background-repeat: no-repeat;
    background-image: url(${backgroundImage});
    position: absolute;
    top: 0px;
    z-index: 1;
    height: 100%;
    &.right {
      right: 0px;
    }
    opacity: 0;
    animation: fade-in forwards 0.5s;
    @keyframes fade-in {
      from {opacity: 0}
      to {opacity: 1}
    }
  `
);

export const KermisLogo = styled.div`
height: 30px;
width: 250px;
background-size: contain;
background-repeat: no-repeat;
    background-image: url(${Kermis});
  `

export const WhiteKermisLogo = () => <KermisL fill="white" height="30px" width="250px" />
  
export default Illustration;

import React, { useEffect, useState } from "react";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Icon from "../icon/Icon";
import { Body2Bold, Body2Light, Text1Bold, Text1Light, Text2Roman, Text3Light, Title2Light } from "../typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { discoverSlide, getSlide, getSlideOptions } from "../../store/logging/actions";
import { IAppState } from "../../store";
import ImageModal from "../image-modal/ImageModal";

export const isOnlineGame = (gameId: string) => {
    if(gameId) {
        return gameId.indexOf("_ONLINE") !== -1
    } 
    return false;
}
export const SeeSlidesButton: React.FC<{ active: boolean, setActive: (s:boolean) => void}> = ({ active, setActive }) => {
    return <div style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}
        onClick={() => setActive(!active)}>
        {!active && <>
            <span>See Slides</span>
            <Icon style={{ margin: "0px 4px"}} name="chart" height={32} width={32} fill="black"/>
        </>}
        {active && <>
            <span style={{color: "white"}}>Close</span>
            <Icon style={{ margin: "0px 4px"}} stroke="white" name="close" height={32} width={32}/>
        </>}
    </div>
}


const GranularItem: React.FC<{ onClick: (e:any) => void; name: string, onChange: (e: any) => void, color?: string }> = ({ name, onChange, color, onClick }) => {
    const [{ dragging }, dragRef ] = useDrag(
        () => ({
            type: "granularity",
            item: name,
            collect: (monitor: DragSourceMonitor) => ({
                dragging: monitor.isDragging()
            }),
            end(item, monitor) {
                const dropResult = monitor.getDropResult() as { second: boolean };
                if (dropResult) {
                    console.log("Dropped", name)
                  onChange(name);
                }
            },
        })
    )
    return <div key={"key"+name} ref={dragRef} onClick={() => onClick(name)} 
        style={{
            backgroundColor: color ? color : 'initial',
            margin: "4px", borderRadius: "0.375rem", justifyContent: "center", alignItems: "center",
            cursor: "pointer", padding: "4px", border: "1px solid #4b5563" 
        }}
        className={`mx-xxs my-xxs text-sm rounded-md justify-center items-center cursor-pointer p-xs border-gray-600 border`}>
        <Text2Roman style={{fontWeight: 600}}>{name}</Text2Roman>
    </div>
}


const GranularCombinator: React.FC<{ granularities: string[], removeItem: (d:string) => void; }>= ({ granularities, removeItem }) => {
    const [discovery, setDiscovery] = useState<any>();
    const [imageSrc, setImageSrc] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const gameId = useSelector(
        (state: IAppState) => state.loggingReducer.currentGame
    );

    const dispatch = useDispatch();
    const discoveredSlides = useSelector(
        (state: IAppState) => state.loggingReducer.discoveredSlide
    );
    const imgSlide = useSelector(
        (state: IAppState) => state.loggingReducer.imageSlide
    );
    console.log("Discovered", discovery);

    console.log(imgSlide);

    const checkCombination = async () => {
        dispatch(
            discoverSlide({ granularities })
        );
    }
    const seeSlide = () => {
        dispatch(
            getSlide({
                index: discovery.slide_index,
                gameId: gameId
            })
        )
    }
    useEffect(() => {
        if(discoveredSlides && discoveredSlides.slide_index !== -1) {
            setDiscovery(discoveredSlides)
        } else {
            setDiscovery(null)
        }
    }, [discoveredSlides])

    useEffect(() => {
        if(imgSlide) {
            setImageSrc(imgSlide)
            setShowModal(true);
        } else {
            setImageSrc(null);
            setShowModal(false);
        }
    }, [imgSlide])

    useEffect(() => {
        checkCombination();
    }, [granularities])
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: "granularity",
          drop: (d) => ({
            name: "dest,",
            source: d
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
          })
        }),
        []
    );

    const isActive = canDrop && isOver;
    return <>
        <div ref={drop} 
            style={{
                border: isActive ? "4px dashed #4b5563" : "2px dashed #4b5563",
                borderRadius: "6px",
                margin: "4px 0px"
            }}
            className={`${isActive ? "border-4" : "border-2"} border-dashed border-gray-600 h-[200px] rounded-md my-sm`}>
            {granularities.length == 0 && <div 
                style={{ justifyContent: "center", alignItems: "center", height: "100%"}}
                className="w-full h-full flex flex-col justify-center items-center"><Text2Roman className="text-gray-500">Drop items here</Text2Roman></div>}
            <div className="flex flex-row flex-wrap">
                {granularities.map((d:any) => <Text2Roman 
                    onClick={() => removeItem(d)}
                    style={{
                        fontWeight: 600,
                        margin: "4px", borderRadius: "0.375rem", justifyContent: "center", alignItems: "center", padding: "4px",
                        border: "1px solid #4b5563", cursor: "pointer"
                    }}
                    className={`mx-xxs my-xxs rounded-md justify-center items-center cursor-pointer p-xs border-gray-600 border`}>
                        {d}
                    </Text2Roman>)}
            </div>
        </div>
        {discovery && <div className="flex flex-row"
            style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px", justifyContent: "center", alignItems: "center", cursor: "pointer", padding: "12px 8px",
        backgroundColor: "#f5f5f1"}}
            onClick={() => seeSlide()}>
            <Icon fill="white" width={34} name="chart" style={{fill: "black" }} />
            <div className="flex flex-col flex-1 ml-2 justify-center" style={{ flex: "1", color: "black", marginLeft: "12px", justifyContent: "center"}}>
                <Text2Roman style={{fontWeight: 600}} className="text-black">{discovery.slide}</Text2Roman>
                <Text3Light style={{fontWeight: 500, color: "#4b5563"}} className="text-gray-400 text-gray-medium">Click to view information</Text3Light>
            </div>
        </div>}
        {/* <ImageModal grans={granularities} showModal={true} setShowModal={setShowModal}/> */}
        {/* <ManagerIllustration found={discovery != null} /> */}
        {imageSrc && discovery && <ImageModal grans={granularities} name={discovery.slide} src={imageSrc} showModal={showModal} setShowModal={setShowModal}/>}
    </>
}

export const SlideExplorer = () => {
    const [pickedGranularities, setPickedGranularities] = useState<string[]>([]);
    const [category, setCategory] = useState<string | null>(null);
    const [categories, setCategories] = useState<{ [key: string]: string[] }>({})

    const dispatch = useDispatch();

    const gameId = useSelector(
        (state: IAppState) => state.loggingReducer.currentGame
    );

    const slideOptions = useSelector(
        (state: IAppState) => state.loggingReducer.slideOptions
    );


    const getPossibilities = async () => {
        console.log(gameId)
        dispatch(
            getSlideOptions({
              gameId: gameId
            })
        );
    }

    useEffect(() => {
        if(gameId) {
            getPossibilities();
        }
    }, [gameId])


    useEffect(() => {
        if(slideOptions && slideOptions.possibilities) {
            setCategories(slideOptions.possibilities)
            const first = Object.keys(slideOptions.possibilities as any)[0];
            setCategory(first)
        }
    }, [slideOptions])

    useEffect(() => {
        if(category) {
            setPickedGranularities([]);
        }
    }, [category])

    const addGranularity = (gran: string) => {
        setPickedGranularities((old:any) => {
            const oldIndex = old.findIndex((d: any) => d == gran);
            if(oldIndex == -1) {
                return old.slice().concat([gran]);
            } 
            return old;
        })
    }
    const removeGranularity = (gran: string) => {
        setPickedGranularities((old:any) => {
            const oldIndex = old.findIndex((d: any) => d == gran);
            const newArray = old.slice();
            if(oldIndex !== -1) {
                newArray.splice(oldIndex, 1)
            }
            return newArray;
        })
    }
    return <div style={{padding: "0px 32px", color: "white"}}>
        <div className="flex flex-row w-full text-white-light align-center justify-between my-lg"
            style={{ margin: "24px 0px", alignItems: "center", justifyContent: "space-between"}}>
            {Object.keys(categories).map(d => <div key={"cat-"+d} 
                className={`p-md justify-center items-center rounded-md cursor-pointer`}
                style={{
                    padding: "4px 16px",
                    borderRadius: "0.375rem",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: category == d ? '#4b5563' : 'initial'}} 
                onClick={() => setCategory(d)}>
                <Text1Light style={{fontWeight: 500}}>{d}</Text1Light>
            </div>)}
        </div>

        <DndProvider backend={HTML5Backend}>
            <div style={{margin: "12px 0px"}} className="flex flex-col text-white-light my-lg min-h-[150px]">
                <Text2Roman style={{fontWeight: 500}}>Available Granularities</Text2Roman>
                <div className="flex flex-row flex-wrap mx-[-4px]" >
                    {category && categories && categories[category]
                        .filter(d => !pickedGranularities.find(d2 => d2 == d))
                        .map(d => <GranularItem onClick={(e) => addGranularity(e)} key={"gran"+d} onChange={(e) => addGranularity(e)} name={d} />)}
                </div>
            </div>

            <div className="my-lg text-white-light">
                <Text2Roman style={{fontWeight: 500}}>Selected Granularities (Click on chips to remove)</Text2Roman>
                <GranularCombinator granularities={pickedGranularities} removeItem={removeGranularity} />
            </div>
        </DndProvider>
    </div>
}
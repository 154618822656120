import React from "react";
import { Body1Light, Body2Bold, Text3Light, Text4Light, Text6 } from "../typography/Typography";
import styled, { css } from "styled-components";
import Illustration, { getBackgroundImage } from "../illustration/Illustration";
// import "./location-picker.scss";
export interface ILocationChoiceContainerProps {
  selected?: boolean;
  illustrationName?: string;
  dummy?: boolean;
  previouslySelected?: boolean;
}
export interface ILocationChoiceProps {
  locationName: string;
  locationDescription: string;
  selected?: boolean;
  illustrationName?: string;
  onClick?: () => void;
  dummy?: boolean;
  previouslySelected?: boolean;
}
const LocationChoice: React.FC<ILocationChoiceProps> = ({
  locationName,
  locationDescription,
  selected,
  illustrationName,
  onClick,
  dummy,
  previouslySelected,
}) => {
  return (
    <StyledLocationChoice
      className="location-choice"
      selected={selected}
      onClick={onClick}
      illustrationName={illustrationName}
      dummy={dummy}
      previouslySelected={previouslySelected}
    >
      <div className="location-choice-left">
        <div className="location-choice-header"><SelectedDiv selected={selected}/>
        {previouslySelected && <Text4Light className="previous-text">Previously Selected</Text4Light>}
        </div>
        <Body2Bold className="location-name">{locationName}</Body2Bold>
        <Text3Light>{locationDescription}</Text3Light>
      </div>
      {/*illustrationName && <Illustration name={illustrationName} />*/}
      <div className="location-choice-right"></div>
    </StyledLocationChoice>
  );
};

const StyledLocationChoice = styled.div<ILocationChoiceContainerProps>(
  ({ theme, selected, illustrationName, dummy, previouslySelected }) => css`
    background-color: ${selected
      ? theme.colors.blue.blue100
      : previouslySelected
      ? theme.colors.blue.blue50
      : theme.colors.grey.grey25};

    color: ${selected ? theme.colors.white : theme.colors.blue.blue100};
    border: ${selected
      ? "2px solid " + theme.colors.black
      : previouslySelected ? "2px solid "+ theme.colors.blue.blue50 : "2px solid " + theme.colors.grey.grey25};
    height: 176px;
    max-width: 1000px;
    min-width: 350px;
    border-radius: 17px;
    outline: none;
    flex: 1;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    cursor: pointer;
    opacity: ${dummy ? 0 : 1};
    @media (min-width:1600px) {
      min-width: 450px;
    }
    &:hover {
      background-color: ${selected
        ? theme.colors.blue.blue100
        : theme.colors.white};
      border: ${"2px solid " + theme.colors.black};
    }
    .location-name {
      color: ${selected ? theme.colors.green.green25 : theme.colors.blue.blue100};
      margin-bottom: 8px;
    }
    .location-choice-header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      > * {
        margin-right: 12px;
      }
      .previous-text {
        color: #2D9CDB;
      }
    }
    .location-choice-left {
      flex: 0.5;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      padding: 24px;
    }
    .selected-circle {
      border-radius: 50%;
      height: 15px;
      width: 15px;
      border: ${selected
        ? "2px solid white"
        : "2px solid " + theme.colors.blue.blue100};

      .is-selected {
        border-radius: 50%;
        background-color: ${theme.colors.green.green25};
        width: 11px;
        height: 11px;
      }
    }
    .location-choice-right {
      flex: 0.5;
      border-radius: 17px;
      background-image: url(${getBackgroundImage(illustrationName || "")});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right bottom;
    }
  `
);

interface ISelectedDiv {
  selected?: boolean;
  children?: React.ReactNode
}
export const SelectedDiv: React.FC<ISelectedDiv> = ({ selected, children }) => {
  return (
    <div className="selected-circle">
      {selected && <div className="is-selected" />}
      {children}
    </div>
  );
};
export default LocationChoice;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessResume } from "../../components/business-resume/BusinessResume";
import Illustration, { getBusinessColor, getInventoryIllustration, KermisLogo, WhiteKermisLogo } from "../../components/illustration/Illustration";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import Slider from "../../components/slider/Slider";
import Topbar from "../../components/topbar/Topbar";
import { Title1Medium, Title2Light } from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { setInventory } from "../../store/logging/actions";
import Left, { LeftOverlayContainer, LeftTitle } from "../default/Left";
import styled, { css } from "styled-components";
import "./inventory.scss";
import { businesses } from "../../tokens/definitions";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";

const InventorySubtitle = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.red.red50};
    color: ${theme.colors.white};
    padding: 4px 6px;
    margin: 24px 0px 6px 0px;
  `
);

const InventoryName = styled(Title1Medium)<{ padding?: string }>(
  ({ theme, padding }) => css`
    color: ${theme.colors.red.red50};
    padding-bottom: ${padding ? padding : 0};
  `
);

let prevScroll = 0;
export const Inventory: React.FC = () => {
  const [items, setItems] = useState(350);

  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const currentStep = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );

  const [visible, setVisible] = useState(true);  
  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);

  const handleScroll = (element: any) => {
    if(element) {
      const currentScrollPos = element.scrollTop;
      // console.log(currentScrollPos);
      setVisible((prevScroll > currentScrollPos && prevScroll - currentScrollPos > 0) || currentScrollPos < 10);
      prevScroll = currentScrollPos
    }
  }

  useEffect(() => {

    const element = document.querySelector("#scroll-target");
    if(element) {
      element.addEventListener('scroll', () => handleScroll(element));
      return () => element.removeEventListener('scroll', () => handleScroll(element));
    } 
  }, []);

  // console.log(prevScroll);
  return (
    <div className="page inventory">
      <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>

          {activeSlideExplorer ? 
          <SlideExplorer /> :
          <BusinessResume>
            <Title2Light>Manage your inventory for the day!</Title2Light>
          </BusinessResume>}
        </LeftOverlayContainer>
        {!activeSlideExplorer && <LeftIllustration timeOfDay="morning" business={business} withProp="small" />}
      </Left>
      <div className="inventory-right">
        <div className="inventory-right-content">
          <Topbar hide={!visible}/>
          <div className="inventory-right-inside" id="scroll-target">
            {business != undefined && <Title2Light className="inventory-title">
              Before you take your place, you must select how many {businesses[business].inventoryName} to
              take for the day
            </Title2Light>}
            <div className="inventory-selector">
              {getInventoryIllustration(business || 0, "120", items)}
              <InventorySubtitle>Each item costs 1€</InventorySubtitle>
              <InventoryName>{businesses[business || 0].inventoryName}</InventoryName>
              <InventoryName padding="32px">{items}</InventoryName>
              <Slider
                min={400}
                max={800}
                step={5}
                onChange={(e) => setItems(+e)}
              />
            </div>
          </div>
        </div>

        <StyledProgressBar
          text="Continue to financial overview"
          opBeforeSteping={() => {

            if(currentStep != undefined) {
              dispatch(
                setInventory({
                  gameId,
                  userId,
                  inventory: items,
                  step: currentStep + 1,
                })
              );
            }
          }}
          dontIncreaseStep
        />
      </div>
    </div>
  );
};
export default Inventory;

import React from "react";
import styled, { css } from "styled-components";
import { BaseButton, IBaseButtonProps } from "@diana-ui/button";
import Icon from "../icon/Icon";

export const StyledIconButton = styled(BaseButton)(({ theme }) => css`
  background-color: transparent;
  outline: none;
  border: none;
`);

interface IIconButton {
  name: string;
  stroke?: string;
}
export const IconButton: React.FC<IIconButton & IBaseButtonProps> = ({
  name,
  stroke,
  ...props
}) => {
  return (
    <StyledIconButton {...props}>
      <Icon name={name} stroke={stroke}/>
    </StyledIconButton>
  );
};

export default IconButton;

import React from "react";

import styled, { css } from "styled-components";
import { Body1Light, Text4Light } from "../typography/Typography";

interface ISelectedProps {
  selected?: boolean;
}

interface ISelectedCircleProps extends ISelectedProps {
  text?: string;
}

const SelectionCircleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    > * {
      margin: 0px 6px;
    }
  `
);
const SelectionCircle: React.FC<ISelectedCircleProps> = ({
  selected,
  text,
}) => {
  return (
    <SelectionCircleContainer className="selection-circle">
      <StyledSelectionCircle selected={selected} />
      <Text4Light>{text}</Text4Light>
    </SelectionCircleContainer>
  );
};
const StyledSelectionCircle = styled.div<ISelectedProps>(
  ({ theme, selected }) => css`
    border-radius: 50%;
    width: ${selected ? "11px" : "15px"};
    height: ${selected ? "11px" : "15px"};
    border: ${selected
      ? "2px solid white"
      : "2px solid " + theme.colors.blue.blue100};

    background-color: ${selected ? theme.colors.green.green25 : theme.colors.white};
  `
);

export default SelectionCircle;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessResume } from "../../components/business-resume/BusinessResume";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import { Body2Light, Text3Bold, Title1Medium, Title2Light, Title2Medium } from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { getFinancial, setInventory } from "../../store/logging/actions";
import Left, { LeftOverlayContainer, LeftTitle } from "../default/Left";
import "./financial-overview.scss";
import FinancialCard from "../../components/financial-card/FinancialCard";
import { times } from "../../store/logging/reducer";
import { IFinancial } from "../../store/logging/types";
import { getBusinessColor, KermisLogo, WhiteKermisLogo } from "../../components/illustration/Illustration";
import styled, { css, useTheme } from "styled-components";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";

const StyledTag = styled(Text3Bold)(
  ({ theme }) => css`
    padding: 2px 8px;
    background-color: ${theme.colors.grey.grey100};
    border-radius: 6px;
  `)
interface IFinancialProps {
  time: "9am" | "12pm" | "4pm" | "6pm";
  timeOfDay: "morning" | "noon" | "evening" | "raining" ;
  nextText?: string;
  leftText?: string;
  tagText?: string;
  descriptionText?: string;
}
export const FinantialOverview: React.FC<IFinancialProps> = ({
  time,
  nextText = "",
  timeOfDay,
  descriptionText,
  leftText,
  tagText,
}) => {
  const dispatch = useDispatch();

  const theme: any = useTheme();
  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );

  const financial: IFinancial = useSelector(
    (state: IAppState) => state.loggingReducer.financial
  );
  useEffect(() => {
    if (gameId && userId) {
      dispatch(
        getFinancial({
          gameId,
          userId,
          time,
        })
      );
    }
  }, [gameId, userId, time]);

  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);
  
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const priceMarkdown = useSelector(
    (state: IAppState) => state.loggingReducer.markdown
  );

  const max = 1000
  const min = 0;
  
  
  return (
    <div className="page finantial-overview">
      <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>
          {activeSlideExplorer ? 
          <SlideExplorer /> :
            <BusinessResume>
              <Title2Light>{leftText}</Title2Light>
            </BusinessResume>
          }
        </LeftOverlayContainer>
        {!activeSlideExplorer && <LeftIllustration timeOfDay={timeOfDay} business={business} withProp="small"/>}
      </Left>
      <div className="finantial-overview-right">
        {financial && time && (
          <div className="finantial-overview-right-inside">
            <div className="financial-header">
            <Title2Medium>Financial Overview</Title2Medium>
            <Body2Light>{descriptionText || "Your Profit so far"}</Body2Light>
            <StyledTag>{tagText}</StyledTag>
            </div>
            <div className="financial-overview-cards-container">
              {financial.sales[times.indexOf(time)] != undefined && <FinancialCard
               min={min} max={max}
              currentPartOfDay={time}
                title="Revenue"
                unit=""
                value={financial.sales[times.indexOf(time)]}
                chartTitle=""
                color={theme.colors.green.green25}
                chartData={financial.sales.map((d: number | null, index) => ({
                  label: times[index],
                  value: d,
                }))}
              />}
              {financial.costs[times.indexOf(time)] != null && <FinancialCard
              min={min} max={max}
              currentPartOfDay={time}
                title="Costs"
                unit="€"
                value={financial.costs[times.indexOf(time)]}
                chartTitle=""
                isNegative
                color={theme.colors.red.red25}
                chartData={financial.costs.map((d: number | null, index) => ({
                  label: times[index],
                  value: d,
                }))}
              />}
              {financial.profit[times.indexOf(time)] != null && <FinancialCard
              min={min} max={max}
              currentPartOfDay={time}
                title="Current Profit"
                unit="€"
                color={theme.colors.green.green25}
                isNegative={financial.profit[times.indexOf(time)] <= 0}
                value={financial.profit[times.indexOf(time)]}
                chartTitle=""
                chartData={financial.sales.map((d: number | null, index) => ({
                  label: times[index],
                  value: d != null ? (priceMarkdown != undefined && priceMarkdown > -1 && index == 4 ? d * priceMarkdown : d * 2) : null,
                }))}
                chartData2={financial.costs.map((d: number | null, index) => ({
                  label: times[index],
                  value: d,
                }))}
              />}
            </div>
          </div>
        )}

        <StyledProgressBar text={nextText} />
      </div>
    </div>
  );
};
export default FinantialOverview;

import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessResume } from "../../components/business-resume/BusinessResume";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";

import VisibilitySensor from "react-visibility-sensor";
import {
  Text2Roman,
  Title2Light,
  Body2Bold,
  Body2Light,
} from "../../components/typography/Typography";
import {
  locationList,
  marketingCampaigns,
  markdowns,
  marketingCampaignsDuration,
} from "../../tokens/definitions";
import { IAppState } from "../../store";
import { getBestActions, setInventory } from "../../store/logging/actions";
import Left, { LeftOverlayContainer, LeftTitle } from "../default/Left";
import "./actions.scss";
import styled, { css, useTheme } from "styled-components";
import { TopbarWithActions } from "../../components/topbar/Topbar";
import {
  getBusinessColor,
  KermisLogo,
} from "../../components/illustration/Illustration";
import Donut from "../../components/donut/Donut";
import classnames from "classnames"
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";




export const Actions: React.FC = () => {
  const [selectedTime, setSelectedTime] = useState("9am");
  const dispatch = useDispatch();
  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );

  useEffect(() => {
    dispatch(getBestActions({ gameId, userId }));
  }, []);

  // Refs

  const ref9am: any = useRef(null);
  const ref12pm: any = useRef(null);
  const ref4pm: any = useRef(null);
  const ref6pm: any = useRef(null);

  const scrollToRef = (refName: string) => {
    setSelectedTime(refName);
    switch (refName) {
      case "9am":
        ref9am.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      case "12pm":
        ref12pm.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      case "4pm":
        ref4pm.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      case "6pm":
        ref6pm.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;

      default:
    }
  };

  const actionResults = useSelector(
    (state: IAppState) => state.loggingReducer.results
  );
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );

  return (
    <div className="page inventory">
      <Left>
        <LeftOverlayContainer>
          <LeftTitle>
            <KermisLogo />
          </LeftTitle>
          <BusinessResume>
            <Title2Light>Congratulations! The Day has ended</Title2Light>
          </BusinessResume>
        </LeftOverlayContainer>
        <LeftIllustration business={business} timeOfDay="evening" withProp="small"/>
      </Left>
      <div className="inventory-right">
        <TopbarWithActions
          onTimeButtonClick={(time) => scrollToRef(time)}
          selectedTimeButton={selectedTime}
        />

        <div className="actions-container">
          <div className="actions-inside">
            {actionResults && (
              <ActionsList>
                <ActionItem
                  ref={ref9am}
                  time="9am"
                  actionName="Location"
                  yourAction={locationList[actionResults.location_choice1] ? locationList[actionResults.location_choice1].name : ""}
                  bestAction={
                    locationList[actionResults.best_location_choice1] ? locationList[actionResults.best_location_choice1].name : ""
                  }
                  result={actionResults.location1_demand_diff}
                  resultName="demand"
                />
                <ActionItem
                  ref={ref12pm}
                  time="12pm"
                  actionName="Location"
                  yourAction={locationList[actionResults.location_choice2] ? locationList[actionResults.location_choice2].name : ""}
                  bestAction={
                    locationList[actionResults.best_location_choice2] ? locationList[actionResults.best_location_choice2].name : ""
                  }
                  result={actionResults.location2_demand_diff.toFixed(0)}
                  resultName="demand"
                />
                <ActionItem
                  time="12pm"
                  actionName="Marketing Campaign"
                  yourAction={
                    marketingCampaigns[actionResults.marketing_camp_choice] ? marketingCampaigns[actionResults.marketing_camp_choice].name : ""
                  }
                  bestAction={
                    marketingCampaigns[actionResults.best_marketing_camp_choice] ?  marketingCampaigns[actionResults.best_marketing_camp_choice].name : ""
                  }
                  resultName=""
                  withoutBorder
                />
                <ActionItem
                  time=""
                  actionName=""
                  yourAction={
                    marketingCampaignsDuration[
                      actionResults.marketing_leng_choice
                    ] ? marketingCampaignsDuration[
                      actionResults.marketing_leng_choice
                    ].duration : ""
                  }
                  bestAction={
                    marketingCampaignsDuration[
                      actionResults.best_marketing_leng_choice
                    ] ? marketingCampaignsDuration[
                      actionResults.best_marketing_leng_choice
                    ].duration : ""
                  }
                  result={actionResults.marketing_profit_diff.toFixed(0)}
                  unit="€"
                  resultName="profit"
                />
                <ActionItem
                  ref={ref4pm}
                  time="4pm"
                  actionName="Location"
                  yourAction={locationList[actionResults.location_choice3] ? locationList[actionResults.location_choice3].name : ""}
                  bestAction={
                    locationList[actionResults.best_location_choice3] ? locationList[actionResults.best_location_choice3].name : ""
                  }
                  result={actionResults.location3_demand_diff.toFixed(0)}
                  resultName="demand"
                />
                <ActionItem
                  ref={ref6pm}
                  time="4pm"
                  actionName="Price Markdown"
                  yourAction={markdowns[actionResults.markdown_choice] ? markdowns[actionResults.markdown_choice].name : "None"}
                  bestAction={
                    markdowns[actionResults.best_markdown_choice].name
                  }
                  result={actionResults.revenue_diff.toFixed(0)}
                  unit="€"
                  resultName="revenue"
                />
              </ActionsList>
            )}
          </div>
        </div>

        <StyledProgressBar
          text="Show my final score"
        />
      </div>
    </div>
  );
};

const ActionsList = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
  `
);
const StyledActionItem = styled.div<{ withoutBorder?: boolean }>(
  ({ theme, withoutBorder }) => css`
    display: flex;
    flex-direction: column;
    height: 186px;
    min-width: 700px;
    border-bottom: ${withoutBorder ? "none" : `4px solid ${theme.colors.grey.grey25}`};
    margin: 12px 0px;
    .title {
      text-align: left;
    }
    .details-container {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }
    .action-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      flex: 0.3;

      .results {
        display: flex;
        align-items: center;
      
        > svg {
          margin: 0px 8px;
        }
      }
      
      .value {
        font-weight: 700;
        color: ${theme.colors.red.red25};
        &.perfect {
          color: ${theme.colors.yellow.yellow25};
        }
      }
    }
    .your-action {
      color: ${theme.colors.red.red25};
      &.perfect {
        color: ${theme.colors.yellow.yellow25};
      }
    }
    .best-action {
      color: ${theme.colors.green.green25};

      &.perfect {
        color: ${theme.colors.yellow.yellow25};
      }
    }
  `
);

interface IActionItemProps {
  yourAction: string;
  bestAction: string;
  result?: string;
  time: string;
  actionName: string;
  resultName: string;
  unit?: string;
  withoutBorder?: boolean;
}
export const ActionItem = forwardRef<HTMLInputElement, IActionItemProps>(
  ({ time, actionName, yourAction, bestAction, result, resultName, unit, withoutBorder }, ref) => {

    const theme: any = useTheme();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const perfect: boolean = yourAction == bestAction;

    return (<VisibilitySensor onChange={(d:any) => {
      if(d) {
        setIsVisible(d)
      }
    }}>    
      <StyledActionItem withoutBorder={withoutBorder}>
        <div className="title" ref={ref}>
          <Body2Light> 
            <strong>{time}</strong> {actionName}
          </Body2Light>
        </div>
        <div className="details-container">
          <div className="action-detail">
            <Text2Roman>Your Action</Text2Roman>
            <Body2Bold className={classnames("your-action", { perfect })}>{yourAction}</Body2Bold>
          </div>

          <div className="action-detail">
            <Text2Roman>Best Action</Text2Roman>
            <Body2Bold className={classnames("best-action", { perfect })}>{bestAction}</Body2Bold>
          </div>
          <div className="action-detail">
            {result && <Text2Roman>Results</Text2Roman>}
            {result != null && <div className="results">
              <Donut value={isVisible == undefined || isVisible ? +result : 0} delay={500} size={28} thickness={4} foregroundColor={{ fill: perfect ? theme.colors.yellow.yellow25 : theme.colors.red.red25 }} />
              <Text2Roman className={classnames("value", { perfect })}>+{result}{unit ? unit : "%"}</Text2Roman>&nbsp;<Text2Roman>{resultName}</Text2Roman>
            </div>}
          </div>
        </div>
      </StyledActionItem>
    </VisibilitySensor>
    );
  }
);

export default Actions;

export default {
  alert: {
    alert25: "#ED5B41",
    alert50: "#ED5B41",
    alert100: "#ED5B41",
  },
  background: {
    background25: "#f8f8f8",
    background50: "",
    background100: "#161616",
    overlay: "rgba(4, 4, 15, 0.7)",
    marketingBackground: "#e5e5e545",
    marketingBackgroundHover: "#e5e5e599"
  },
  black: "#000000",
  blue: {
    blue25: "#f2f7f8",
    blue50: "#EEFAFE",
    blue100: "#0B1F2F",
  },
  green: {
    green25: "#05D973",
    green50: "#284656",
    green100: "#284656",
  },
  grey: {
    grey25: "#F5F5F5",
    grey50: "#E0E0E0",
    grey100: "#BFBFBF",
  },
  brown: {
    brown25: "#EAD9C5",
    brown50: "#EAD9C5",
    brown100: "#EAD9C5",
  },
  red: {
    red25: "#EB5757",
    red50: "#ed5b41",
    red100: "#8e3727",
  },
  primary: "#14306B",
  secondary: "",
  yellow: {
    yellow25: "#FFBA22",
    yellow50: "#ffba225c"
  },
  success: {
    success25: "#4e9a55",
    success50: "#4e9a55",
    success100: "#4e9a55",
  },
  warning: {
    warning25: "#fbbc44",
    warning50: "#fbbc44",
    warning100: "#fbbc44",
  },
  white: "#ffffff",
};

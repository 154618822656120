import React, { useEffect, useState } from "react";
import { Title2Light } from "../../components/typography/Typography";
import "./login.scss";
import classnames from "classnames";
import { TextInputWithLabel } from "../../components/input/TextInput";
import { Buffer } from "buffer";
import { ReactComponent as GiantWheel } from "../../assets/images/illustrations/big_ferris_wheel.svg";
import { ReactComponent as Tent } from "../../assets/images/illustrations/big_tent.svg";
import Left from "../default/Left";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinGameRequest } from "../../store/logging/actions";
import { IAppState } from "../../store";
import CTABig from "../../components/cta-big/CTABig";
import { useTheme } from "styled-components";
import Icon from "../../components/icon/Icon";
import { KermisLogo } from "../../components/illustration/Illustration";

export const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [gameId, setGameId] = useState("");
  const [userId, setUserId] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const theme: any = useTheme();
  
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );

  const loginError = useSelector(
    (state: IAppState) => state.loggingReducer.loginError
  );
  useEffect(() => {
    console.log(currentGame);
    if (currentGame != undefined) {
      history.push({
        pathname: "/game",
        state: { cameFromLogin: true },
      });
    }
  }, [currentGame]);

  useEffect(() => {
    setDisabled(gameId.length == 0 || userId.length == 0 || code.length == 0,);
  }, [gameId, userId, code])
  const joinGame = () => {
    if(gameId && userId && code && gameId.length > 0 && userId.length > 0 && code.length > 0) {
      dispatch(
        joinGameRequest({
          gameId,
          name: userId,
          code: Buffer.from(code).toString('base64'),
        })
      );
    }
  };

  return (
    <div className="page">
      <Left className="left" flexWidth="0.5" backgroundColor="linear-gradient(180deg, #A0C2F5 0%, #BFD6F9 49.26%)">
        <GiantWheel style={{ position: "absolute", right: 0, height: "100%" }} />
        <Tent style={{ position: "absolute", left: "0px", bottom: 0, width: "60%" }} />
      </Left>
      <div className={classnames("right", "login")}>
        <form
         className="login-form"
          onSubmit={() => joinGame()}
          action="javascript:void(0);"
        >
          <div className="login-form-container">

            <KermisLogo className="login-logo" style={{width: "400px", height: "100px" }}/>

            <div className="name-input-container">
              <TextInputWithLabel
                label="Name"
                type="text"
                value={userId}
                error={loginError && loginError.field == "name" ? loginError.error : undefined}
                onChange={(e) => setUserId(e.target.value)}
                />
              <div className="others-input-container">
                  <TextInputWithLabel
                    className="room"
                    label="Room"
                    type="text"
                    error={loginError && loginError.field == "game" ? loginError.error : undefined}
                    value={gameId}
                    onChange={(e) => setGameId(e.target.value)}
                  />
                  <br />
                  <TextInputWithLabel
                    label="Password"

                    type="text"
                    value={code}
                    error={loginError && loginError.field == "code" ? loginError.error : undefined}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
            </div>
          </div>
        <CTABig backgroundColor={theme.colors.red.red25} disabled={disabled}>
          <Icon name="rounded-right-arrow" />
          <Title2Light>Start the game</Title2Light></CTABig>
        </form>

      </div>
    </div>
  );
};
export default withRouter(Login);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BusinessResume,
  MarketingResume,
} from "../../components/business-resume/BusinessResume";
import SecondaryButton from "../../components/button/SecondaryButton";
import {
  DivImage,
  getBusinessColor,
  getBusinessImage,
  getMarketingImage,
  getMarketingName,
  KermisLogo,
  WhiteKermisLogo,
} from "../../components/illustration/Illustration";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import { MarketingCard } from "../../components/marketing-card/MarketingCard";
import Picker from "../../components/picker/Picker";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import Topbar from "../../components/topbar/Topbar";
import {
  Body1Light,
  Body2Light,
  Body1Bold,
  Title2Light,
  Text3Light,
  Body2Bold,
  Title2Bold,
  Title2LightSpan,
  Title2BoldSpan,
} from "../../components/typography/Typography";
import { IAppState } from "../../store";
import {
  setMarketing,
  setMarketingRequest,
  setStep,
} from "../../store/logging/actions";
import Left, {
  LeftContent,
  LeftOverlayContainer,
  LeftTitle,
} from "../default/Left";
import { ReactComponent as Singing1 } from "../../assets/images/singing1.svg";
import "./marketing-campaign.scss";
import styled, { css } from "styled-components";
import {
  marketingCampaigns,
  marketingCampaignsDuration,
} from "../../tokens/definitions";
import Icon from "../../components/icon/Icon";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";

let prevScroll = 0;
export const MarketingCampaign: React.FC = () => {
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );

  const marketing = useSelector(
    (state: IAppState) => state.loggingReducer.marketing
  );
  const marketingDuration = useSelector(
    (state: IAppState) => state.loggingReducer.marketingDuration
  );
  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);

  return (
    <div className="page inventory">
      <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>
          {activeSlideExplorer ? 
          <SlideExplorer /> :
          (marketing != undefined ? (
            <MarketingResume>
              <Body1Light>
                Congratulations! You chose the{" "}
                <Body1Bold>{getMarketingName(marketing)}</Body1Bold> campaign!
              </Body1Light>
            </MarketingResume>
          ) : (
            <BusinessResume>
              <Body1Light>
                Would you like to boost sales with a campaign?
              </Body1Light>
            </BusinessResume>
          ))}
        </LeftOverlayContainer>
        {!activeSlideExplorer && <LeftIllustration
          otherProp={
            marketing == undefined || marketing == -1 ? business : undefined
          }
          marketing={
            marketing != -1 && marketing != undefined ? marketing : undefined
          }
          withProp="small"
          timeOfDay="raining"
        />}
      </Left>
      {marketing != undefined && marketingDuration != undefined ? (
        <MarketingAfterSelection
          marketing={marketing}
          marketingDuration={marketingDuration}
        />
      ) : (
        <div className="picker-horizontal">
          <Picker
            onClick={() => dispatch(setMarketing(1))}
            backgroundColor="#C4CDFF"
            noHover
          >
            <div className="dummy-picker"></div>
            <DivImage backgroundImage={getMarketingImage(1)} />
            <div className="select-container">
              <MarketingPickerSelect marketingNumber={1}>
                <Title2Light>Clown Crew</Title2Light>
              </MarketingPickerSelect>
            </div>
          </Picker>
          <Picker
            onClick={() => dispatch(setMarketing(0))}
            backgroundColor="#E3A89E"
            noHover
          >
            <div className="select-container">
              <MarketingPickerSelect marketingNumber={0}>
                <Title2Light>Choir Group</Title2Light>
              </MarketingPickerSelect>
            </div>
            <div className="dummy-picker"></div>
            <DivImage
              className="right"
              backgroundImage={getMarketingImage(0)}
            />
          </Picker>
          <Picker
            onClick={() => dispatch(setMarketing(2))}
            backgroundColor="#B6D7FF"
            noHover
          >
            <DivImage backgroundImage={getMarketingImage(2)} />
            <div className="dummy-picker"></div>
            <div className="select-container">
              <MarketingPickerSelect marketingNumber={2}>
                <Title2Light>Jazz Band</Title2Light>
              </MarketingPickerSelect>
            </div>
          </Picker>
        </div>
      )}
    </div>
  );
};

const MarketingChoicesContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    min-height: 160px;
  `
);

const MarketingChoice = styled.div(
  ({ theme }) => css`
    border-radius: 16px;
    background-color: ${theme.colors.background.marketingBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 57px;
    width: 75px;
    margin: 4px;
    padding-bottom: 8px;
    cursor: pointer;
    .inside-marketing-choice {
      padding: 8px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-height: 57px;
      max-height: 57px;
      align-items: center;
    }
    .hire-button {
      display: none;
    }
    &:hover {
      background-color: ${theme.colors.background.marketingBackgroundHover};
      height: 89px;
      padding-bottom: 0px;
      .hire-button {
        display: flex;
      }
    }
  `
);

const HireButton = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: ${theme.colors.blue.blue100};
    color: white;
    border-radius: 16px;
    padding: 7px 14px;
    .icon {
      margin-left: 4px;
    }
  `
);

const Divider = styled.div(
  ({ theme }) => css`
    height: 1px;
    background-color: #aaa;
    width: 100%;
  `
);
export const MarketingPickerSelect: React.FC<{ marketingNumber: number, children?: React.ReactNode }> = ({
  children,
  marketingNumber,
}) => {
  return (
    <div className="business-picker-select">
      {children}
      <div className="marketing-picker-select-inside">
        <Text3Light className="marketing-duration-subtitle">
          How long will you hire the service?
        </Text3Light>
        <MarketingCampaingDuration marketing={marketingNumber} />
      </div>
    </div>
  );
};

interface IMarketingCampaingDurationProps {
  marketing: number;
}
const MarketingCampaingDuration: React.FC<IMarketingCampaingDurationProps> = ({
  marketing,
}) => {
  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const currentStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );

  const dispatch = useDispatch();
  return (
    <MarketingChoicesContainer>
      {marketingCampaigns[marketing].costs.map((d: any, index: number) => {
        return (
          <MarketingChoice
            key={d.name + "_" + index}
            onClick={() => {
              dispatch(
                setMarketingRequest({
                  gameId: currentGame,
                  userId: currentName,
                  marketing: marketing,
                  marketingDuration: index,
                })
              );
              // dispatch(
              //   setStep({
              //     gameId: currentGame,
              //     userId: currentName,
              //     step: currentStep + 2,
              //   })
              // );
            }}
          >
            <div className="inside-marketing-choice">
              <Icon name="clock" />
              <Text3Light>
                {marketingCampaignsDuration[index].duration}
              </Text3Light>
              {/* <Divider /> */}
              {/* <Body2Bold>{d}€</Body2Bold> */}
            </div>
            <HireButton className="hire-button">
              <Text3Light>Hire</Text3Light>
              <Icon name="arrow-right" />
            </HireButton>
          </MarketingChoice>
        );
      })}
    </MarketingChoicesContainer>
  );
};

const marketingNames = ["Singing", "Clown", "Jazz"];
export const MarketingAfterSelection: React.FC<{
  marketing: number;
  marketingDuration: number;
}> = ({ marketing, marketingDuration }) => {
  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );

  const currentMarketing: any = useSelector(
    (state: IAppState) => state.loggingReducer.marketing
  );

  const currentMarketingDuration: any = useSelector(
    (state: IAppState) => state.loggingReducer.marketingDuration
  );

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(true);

  const handleScroll = (element: any) => {
    if (element) {
      const currentScrollPos = element.scrollTop;

      setVisible(
        (prevScroll > currentScrollPos && prevScroll - currentScrollPos > 0) ||
          currentScrollPos < 10
      );
      prevScroll = currentScrollPos;
    }
  };

  useEffect(() => {
    const element = document.querySelector("#scroll-target");
    if (element) {
      element.addEventListener("scroll", () => handleScroll(element));
      return () =>
        element.removeEventListener("scroll", () => handleScroll(element));
    }
  }, []);

  return (
    <div className="marketing-after-selection">
      <div className="marketing-after-selection-content">
        <Topbar
          hide={!visible}
          customBackOperation={() => {
            dispatch(setMarketing(undefined));
          }}
        />
        <div className="marketing-after-selection-inside" id="scroll-target">
          <div className="marketing-after-selection-container">
            <Title2LightSpan>
              You hired the {marketingCampaigns[marketing].name} for{" "}
              <Title2BoldSpan>
                {marketingCampaignsDuration[marketingDuration].description}.
              </Title2BoldSpan>
              <br />
              This will cost you{" "}
              <Title2BoldSpan>
                {marketingCampaigns[marketing].costs[marketingDuration]}€.
              </Title2BoldSpan>
            </Title2LightSpan>
          </div>
          <StyledProgressBar
            text="Proceed to the afternoon sales"
            disabled={currentMarketing == undefined || currentMarketing == -1}
          />
        </div>
      </div>
    </div>
  );
};

export default MarketingCampaign;

import React from "react";
import "./picker.scss";

import styled, { css } from "styled-components";

interface IPickerProps {
  backgroundColor?: string;
  backgroundOnHover?: string;
  onClick: () => void;
  noHover?: boolean;
  children?: React.ReactNode
}
export const Picker: React.FC<IPickerProps> = ({
  backgroundColor,
  backgroundOnHover, 
  onClick,
  children,
  noHover
}) => {
  return (
    <StyledPicker noHover={noHover} onClick={onClick} backgroundColor={backgroundColor} backgroundOnHover={backgroundOnHover}>{children}</StyledPicker>
  );
};

const StyledPicker = styled.div<{ noHover?: boolean; backgroundColor?: string, backgroundOnHover?: string }>(
  ({ theme, backgroundColor, backgroundOnHover, noHover }) => css`
    display: flex;
    justify-content: space-between;
		cursor: ${noHover ? 'normal' : 'pointer'};
    align-items: center;
    background: ${backgroundColor || "white"};
    position: relative;
    min-height: 300px;
    flex: 1;
    &:hover {
      background: ${backgroundOnHover ? backgroundOnHover : backgroundColor};
      button {
        background: ${theme.colors.blue.blue100};
        color: white;
      }
    }
    .dummy-picker {
      flex: 1;
    }
    .select-container {
      flex: 1;
      display: flex;
      justify-content: center;
      .business-picker-select {
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: column;
      }
    }
  `
);
export default Picker;

import styled, { css } from "styled-components";

export const DetailText = styled.span`
  ${(props) => props.theme.typography.detailText}
`;

export const H1 = styled.h1`
  ${(props) => props.theme.typography.h1}
`;

export const H2 = styled.h2`
  ${(props) => props.theme.typography.h2}
`;

export const H3 = styled.h3`
  ${(props) => props.theme.typography.h3}
`;

export const H4 = styled.h4`
  ${(props) => props.theme.typography.h4}
`;

export const H5 = styled.h5`
  ${(props) => props.theme.typography.h5}
`;

export const Header1Bold = styled.div`
  ${(props) => props.theme.typography.header1bold}
`;
export const Header1Roman = styled.div`
  ${(props) => props.theme.typography.header1roman}
`;

// TITLE 1
export const Title1Medium = styled.div`
  ${(props) => props.theme.typography.title1medium} 
`;
export const Title1Light = styled.div`
  ${(props) => props.theme.typography.title1light}
`;

// TITLE 2
export const Title2Light = styled.div`
  ${(props) => props.theme.typography.title2light}
`;
export const Title2Medium = styled.div`
  ${(props) => props.theme.typography.title2medium}
`;
export const Title2Bold = styled.div`
  ${(props) => props.theme.typography.title2bold}
`;
export const Title2LightSpan = styled.span`
  ${(props) => props.theme.typography.title2light}
`;
export const Title2MediumSpan = styled.span`
  ${(props) => props.theme.typography.title2medium}
`;
export const Title2BoldSpan = styled.span`
  ${(props) => props.theme.typography.title2bold}
`;

// BODY 1

export const Body1Light = styled.span`
  ${(props) => props.theme.typography.body1light}
`;
export const Body1Bold = styled.span`
  ${(props) => props.theme.typography.body1bold}
`;

// BODY 2
export const Body2Bold = styled.span`
  ${(props) => props.theme.typography.body2bold}
`;
export const Body2Light = styled.span`
  ${(props) => props.theme.typography.body2light}
`;

// TEXT 1
export const Text1Bold = styled.span`
  ${(props) => props.theme.typography.text1bold}
`;
export const Text1Light = styled.span`
  ${(props) => props.theme.typography.text1light}
`;

// TEXT 2
export const Text2Bold = styled.span`
  ${(props) => props.theme.typography.text2bold}
`;
export const Text2Roman = styled.span`
  ${(props) => props.theme.typography.text2roman}
`;
export const Text2RomanUnderline = styled.span`
  ${(props) => props.theme.typography.text2romanunderline}
`;

// TEXT 3
export const Text3Bold = styled.span`
  ${(props) => props.theme.typography.text3bold}
`;
export const Text3Light = styled.span`
  ${(props) => props.theme.typography.text3light}
`;

// TEXT 4
export const Text4Bold = styled.span`
  ${(props) => props.theme.typography.text4bold}
`;
export const Text4Light = styled.span`
  ${(props) => props.theme.typography.text4light}
`;

export const Text6 = styled.span`
  ${(props) => props.theme.typography.text6}
`;

export const Text = styled.span`
  ${(props) => props.theme.typography.text}
`;

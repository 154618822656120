import React, { useEffect } from "react";
import Game from "./pages/game/Game";
import Login from "./pages/login/Login";
import { ThemeProvider } from "styled-components";
import "./pages/default/default.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { defaultTheme } from "./tokens";
import rootSaga from "./sagas";
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./store";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
import { defaultState } from "./store/logging/reducer";
import GameMaster from "./pages/game-master/GameMaster";
import JoinGame from "./pages/join/JoinPage";
import Slide from "./pages/slide/Slide";

const persistConfig = {
  key: "primary",
  storage,
  whitelist: ["loggingReducer"],
};

const initialState: any = defaultState;
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store: any = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={defaultTheme}>
            {/* <GlobalFonts /> */}
            <AppInside />
          </ThemeProvider>
        </PersistGate>
      </Router>
    </Provider>
  );
}

export const AppInside: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location && history.location.state) {
      const obj: any = history.location.state;
      const state = { ...obj };
      state.cameFromLogin = false;
      history.replace({ ...history.location, state });
    }
  }, [history]);
  return (<>
    <div className="App">
      <Switch>
        
        <Route path="/gamemaster/:gameid">
          <GameMaster />
        </Route>
        <Route path="/game">
          <Game />
        </Route>
        <Route path="/gamejoin">
          <Game isAutomaticJoin />
        </Route>
        <Route path="/slide">
          <Slide />
        </Route>
        <Route path="/join" component={JoinGame} />
        <Route exact path="/">
          <Login />
        </Route>
      </Switch>
    </div>
    <div id="modal-root"></div>
    </>
  );
};

export default App;

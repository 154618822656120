import React, { SVGAttributes } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as RightArrow } from "../../assets/images/icons/right-arrow.svg";
import { ReactComponent as BackIcon } from "../../assets/images/icons/back-icon.svg";
// import { ReactComponent as SimpleRightArrow } from "../../assets/images/simple_arrow_right.png";
import { ReactComponent as RoundedLeftArrow } from "../../assets/images/icons/rounded_arrow_left.svg";
import { ReactComponent as RoundedRightArrow } from "../../assets/images/icons/rounded_arrow_right.svg";
import { ReactComponent as Close } from "../../assets/images/icons/close.svg";
import { ReactComponent as Down } from "../../assets/images/icons/down.svg";
import { ReactComponent as Up } from "../../assets/images/icons/up.svg";
import { ReactComponent as Clock } from "../../assets/images/icons/clock.svg";
import { ReactComponent as Star } from "../../assets/images/icons/star.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/icons/arrow-right.svg";
import { ReactComponent as Medal1 } from "../../assets/images/icons/medal1.svg";
import { ReactComponent as Medal2 } from "../../assets/images/icons/medal2.svg";
import { ReactComponent as Medal3 } from "../../assets/images/icons/medal3.svg";
import { ReactComponent as MedalAll } from "../../assets/images/icons/medal_all.svg";
import { ReactComponent as Chart } from "../../assets/images/icons/chart.svg";

interface IIconProps {
  name: string;
  color?: string;
}
export const Icon: React.FC<IIconProps & SVGAttributes<Element>> = ({ name, color, ...props }) => {
  switch (name) {
    case "right-arrow":
      return <RightArrow className="icon" {...props} />;
    case "medal-1":
      return <Medal1 className="icon" {...props} />;
    case "medal-2":
      return <Medal2 className="icon" {...props} />;
    case "medal-3":
      return <Medal3 className="icon" {...props} />;
    case "arrow-right":
      return <ArrowRight className="icon" {...props} />
    case "back":
      return <BackIcon {...props}/>;
    case "rounded-left-arrow":
      return <RoundedLeftArrow {...props}/>;
      case "rounded-right-arrow":
        return <RoundedRightArrow {...props}/>;
    case "close":
      return <Close {...props}/>;
    case "star":
      return <Star {...props}/>
    case "down":
      return <Down {...props}/>
    case "up":
      return <Up {...props}/>
    case "clock":
      return <Clock {...props} />
    case "chart":
      return <Chart {...props} />
    default:
      return <MedalAll className="icon" {...props} />
  }
};

export default Icon;

import React from "react";
import { useSelector } from "react-redux";

import styled, { css } from "styled-components";
import { IAppState } from "../../store";
import { LeftContent } from "../../pages/default/Left";
import {
  getBusinessImage,getBusinessImageResume,
  getBusinessColor,
  getBusinessImageMarketingResume,
  getMarketingImage,
} from "../illustration/Illustration";
import { Title1Medium, Title2Light } from "../typography/Typography";

interface IBusinessResumeProps {
  title?: string;
  backgroundColor?: string;
  isBusinessInMarketing?: boolean;
  children?: React.ReactNode
}

export const BusinessResume: React.FC<IBusinessResumeProps> = ({ children }) => {
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  return (
    <StyledBusinessResume
      business={business || 0}
    >
      <div className="content">
         {children}
      </div>
    </StyledBusinessResume>
  );
};

export const BusinessResumeOld: React.FC<IBusinessResumeProps> = ({
  title,
  backgroundColor,
  isBusinessInMarketing,
  children
}) => {
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  return (
    <StyledBusinessResume
      business={business || 0}
      isBusinessInMarketing={isBusinessInMarketing}
      backgroundColor={backgroundColor}
    >
      <div className="content">
         {children}
      </div>
      <div className="business-background" />
    </StyledBusinessResume>
  );
};

export const MarketingResume: React.FC<IBusinessResumeProps> = ({
  title,
  backgroundColor,
  children
}) => {
  const marketing = useSelector(
    (state: IAppState) => state.loggingReducer.marketing
  );

  return (
    <StyledBusinessResume
    isMarketing={true}
      business={marketing || 0}
      backgroundColor={backgroundColor}
    >
      <div className="content">
         {children}
      </div>
      {/* <div className="business-background" /> */}
    </StyledBusinessResume>
  );
};

export const StyledBusinessResume = styled.div<{
  business: number;
  isMarketing?: boolean;
  isBusinessInMarketing?: boolean;
  backgroundColor?: string;
}>(
  ({ theme, business, backgroundColor, isMarketing, isBusinessInMarketing }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: space-around;
    position: relative;
    background: ${backgroundColor};
    .content {
      position: absolute;
      top: 0px;
      height: 100%;
      z-index: 1;
      padding: 12px 32px;
    }
    .business-background {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 0;
      height: 85%;
      min-height: ${isMarketing ? "85%" : "85%"};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      background-image: url(${isMarketing ? getMarketingImage(business) : isBusinessInMarketing ? getBusinessImageMarketingResume(business) : getBusinessImageResume(business)});
    }
  `
);

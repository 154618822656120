import React from "react";

import styled, { css } from "styled-components";
import BigButton from "../button/BigButton";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store";
import { setStep } from "../../store/logging/actions";
interface CTABigProps {
  backgroundColor: string;
  onClick?: () => void;
  disabled?: boolean;
  goToNextStep?: boolean;
  children?: React.ReactNode
}
export const CTABig: React.FC<CTABigProps> = ({
  backgroundColor,
  onClick,
  children,
  disabled,
  goToNextStep
}) => {

  const dispatch = useDispatch();
  const currentStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );
  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  
  return (
    <StyledCTABig
      className={classnames({ disabled })}
      onClick={() => {
        if(onClick){
          onClick()
        }
        if(goToNextStep && !disabled) {
          dispatch(
            setStep({
              gameId: currentGame,
              userId: currentName,
              step: currentStep + 1,
            })
          );
        }
      }}
      backgroundColor={backgroundColor}
    >
      <div className="cta-big-inside">{children}</div>
    </StyledCTABig>
  );
};

const StyledCTABig = styled(BigButton)<{ backgroundColor: string }>(
  ({ theme, backgroundColor }) => css`
    color: ${theme.colors.white};
    background-color: ${backgroundColor};
    display: flex;
    height: 178px;
    align-items: flex-start;
    justify-content: flex-start;

    &.disabled {
      background-color: ${theme.colors.grey.grey50};
      color: ${theme.colors.grey.grey100};
      .cta-big-inside {
        svg {
          stroke: ${theme.colors.grey.grey100};
        }
      }
    }

    .cta-big-inside {
      display: flex;
      padding: 32px 64px;
      align-items: center;
      svg {
        margin-right: 30px;
        stroke: white;
      }
    }
  `
);
export default CTABig;

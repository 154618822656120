import { combineReducers } from "redux";
import loggingReducer from "./logging/reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const errorConfig = {
  key: "kermis-error",
  storage,
  blacklist: ["loginError", "gameError", "demographics"],
};

export const rootReducer = combineReducers({
  loggingReducer: persistReducer(errorConfig, loggingReducer),
  //loggingReducer,
});
export type IAppState = ReturnType<typeof rootReducer>;

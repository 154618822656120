import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import styled, { css } from "styled-components";
import { getOpenerImage } from "../../components/illustration/Illustration";
import { WelcomeIllustration } from "../../components/illustration/WelcomeIllustration";

import { ReactComponent as ParkBackground } from "../../assets/images/illustrations/park_background.svg";
import Night from "../../assets/images/illustrations/night.svg";
import {ReactComponent as NightTitle} from "../../assets/images/illustrations/night_title.svg";
// import { ReactComponent as KermisPark } from "../../assets/images/illustrations/kermis_park_small.svg";
// import { ReactComponent as KermisParkWhite } from "../../assets/images/illustrations/kermis_park_small_white.svg";
import { ReactComponent as KermisPark } from "../../assets/images/parkmaster_logo_blue.svg";
import { ReactComponent as KermisParkWhite } from "../../assets/images/parkmaster_logo_white.svg";
import Morning from "../../assets/images/illustrations/morning.svg";
import { ReactComponent as MorningTitle } from "../../assets/images/illustrations/morning_title.svg";
import { ReactComponent as MorningTitle2 } from "../../assets/images/illustrations/morning_title2.svg";
import Cloudy from "../../assets/images/illustrations/cloudy.svg";
import { ReactComponent as CloudyTitle } from "../../assets/images/illustrations/cloudy_title.svg";
import Afternoon from "../../assets/images/illustrations/afternoon.svg";
import { ReactComponent as AfternoonTitle } from "../../assets/images/illustrations/afternoon_title.svg";
import Evening from "../../assets/images/illustrations/evening.svg";
import { ReactComponent as EveningTitle } from "../../assets/images/illustrations/evening_title.svg";
import EndOfDay from "../../assets/images/illustrations/endOfDay.svg";
import { CSSTransition } from "react-transition-group"
import { Body1Bold, Body2Light } from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { canIMove, canIMoveSuccess } from "../../store/logging/actions";

const openerTime = 6000;
interface IOpenerProps {
  imageIndex: number;
  children?: React.ReactNode
}
export const Opener: React.FC<IOpenerProps> = ({ imageIndex, children }) => {
  const [isOpening, setIsOpening] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpening(false);
    }, openerTime);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {isOpening ? <StyledBackgroundImage imageIndex={imageIndex} /> : children}
    </>
  );
};


export const OpenerWithIllustration: React.FC<IOpenerProps> = ({ imageIndex, children }) => {
  const [isOpening, setIsOpening] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpening(false);
    }, openerTime);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {isOpening ? <WelcomeIllustration /> : children}
    </>
  );
};


const getParkBackground = (back: string) => {

  switch(back) {
    case "cloudy":
      return Cloudy
    case "night":
      return Night;
    case "morning":
      return Morning;
    case "afternoon":
      return Afternoon;
    case "evening":
      return Evening;
    case "endOfDay":
      return EndOfDay;
  }
}

const ParkScreenContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`
const ParkOverlay = styled(ParkBackground)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`
const ParkSky = styled.div<{ background: string }>( ({ background }) => css`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  background-image: url(${getParkBackground(background)});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  transition: background-image 1s;
`)

const ParkTitle = styled(KermisPark)`
  margin: 16px 0px;
`
const ParkTitleWhite = styled(KermisParkWhite)`
  margin: 16px 0px;
`
const ParkTitleContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .park-title-enter {
    opacity: 0;
  }
  .park-title-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .park-title-exit {
    opacity: 1;
  }
  .park-title-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  .waiting-warning-enter {
    opacity: 0;
  }
  .waiting-warning-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .waiting-warning-exit {
    opacity: 0;
  }
  .waiting-warning-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  .title {
    width: 40%;
  }
  > * {
  }

  .continue-button {
    font-size: 20px;
    margin-bottom: 24px;
    border-radius: 10px;
    background-color: #34DDA5;
    padding: 12px 16px;
    cursor: pointer;
    color: #1A548B;
    &:hover {
      background-color: #278464;
    }
  }
`

const OpenerSubtitle = styled(Body1Bold)(
  ({ theme }) => css `
  color: ${theme.colors.red.red50};
  
  `
)

const StyledBody2Light = styled(Body2Light)(
  ({ theme }) => css`
  color: ${theme.colors.red.red50};
`)
interface IParkScrenProps {
  prevBackground?: string
  background: string;
  isBackstep?: boolean;
  children?: React.ReactNode
}

let hasChecked: boolean = false;
let intervalId: any;
export const ParkScreen: React.FC<IParkScrenProps> = ({ prevBackground, background, children, isBackstep }) => {

  const [currentBack, setCurrentBack] = useState(prevBackground || background);
  const [isOpening, setIsOpening] = useState(true);
  const [shuffle, setShuffle] = useState(false);

  const [showNextTitle, setShowNextTitle] = useState(false);

  const dispatch = useDispatch();

  const gameId = useSelector((state: IAppState) => state.loggingReducer.currentGame)
  const userId = useSelector((state: IAppState) => state.loggingReducer.currentName)
  const step = useSelector((state: IAppState) => state.loggingReducer.currentStep)

  const canMove = useSelector((state: IAppState) => state.loggingReducer.canMove);
  const numPlayersTotal = useSelector((state: IAppState) => state.loggingReducer.numberPlayersTotal);
  const numPlayersConfirmed = useSelector((state: IAppState) => state.loggingReducer.numberPlayersConfirmed);
  
  useEffect(() => {
    if(canMove && hasChecked) {
      const timeout = setTimeout(() => {
        // setIsOpening(false);
      }, openerTime);
      clearInterval(intervalId)

      return () => clearTimeout(timeout);
    }
  }, [canMove, hasChecked]);

  useEffect(() => {
    if(prevBackground && canMove && hasChecked) {
      setTimeout(() => {
        setCurrentBack(background);
      }, 2000)
    }
  }, [canMove, hasChecked])

  const checkGameMove = () => {
    if(gameId && userId && step != undefined) {
      dispatch(canIMove({ gameId, userId, step }))
    }
  }

  useEffect(() => {
    dispatch(canIMoveSuccess({ canMove: false, numberPlayersConfirmed: 0, numberPlayersTotal: 0 } ))
    hasChecked = true;
    checkGameMove();
    intervalId = setInterval(() => {
      checkGameMove();
      setShuffle(shuffle => !shuffle)
    }, 4000)

    return () => {
      hasChecked = false
      clearInterval(intervalId)
    }
  }, [])
  
  return <>
    {isOpening && !isBackstep ? <ParkScreenContainer>
    {currentBack && <ParkSky background={currentBack} />}
    <ParkOverlay />
    <ParkTitleContainer>
      {canMove && hasChecked && <div className="continue-button" onClick={() => setIsOpening(false)}>Continue</div>}
      { currentBack == 'night' || currentBack == 'endOfDay' ? <ParkTitleWhite /> : <ParkTitle />}
      {!showNextTitle && prevBackground && <CSSTransition timeout={300} in={currentBack == prevBackground} onExited={() => setShowNextTitle(true)} classNames="park-title" unmountOnExit><>
        {getTitle(prevBackground, false)} 
        {!canMove && numPlayersTotal != 0 && <CSSTransition timeout={300} in={shuffle} classNames="waiting-warning">
          {shuffle ? <StyledBody2Light>You must move on together</StyledBody2Light> : 
          <StyledBody2Light>Please wait for your {(numPlayersTotal || 0) - (numPlayersConfirmed || 0)} {(numPlayersTotal || 0) - (numPlayersConfirmed || 0) == 1 ? 'colleague' : 'colleagues'}</StyledBody2Light>}
        </CSSTransition>}
        </>
      </CSSTransition>}
      {currentBack && <CSSTransition in={showNextTitle} timeout={300} classNames="park-title" unmountOnExit>
        {getTitle(currentBack, true)}
      </CSSTransition>}
      <OpenerSubtitle>{getSubtitle(currentBack)}</OpenerSubtitle>
    </ParkTitleContainer>
  </ParkScreenContainer> : children}
  </>
}

interface IBackgroundImageProps {
  imageIndex: number;
}
const StyledBackgroundImage = styled.div<IBackgroundImageProps>(
  ({ theme, imageIndex }) => css`
    background-image: url(${getOpenerImage(imageIndex)});
    height: 100vh;
    background-size: cover;
    overflow: hidden;
  `
);

const getTitle = (title: string, isPrev: boolean) => {

  switch(title) {
    case "night":
      return <NightTitle className="title" />
    case  "morning":
      return isPrev ? <MorningTitle className="title" /> : <MorningTitle2 className="title"  />
    case "cloudy":
      return <CloudyTitle className="title"  />
    case "afternoon":
      return <AfternoonTitle className="title" />
    case "evening":
      return <AfternoonTitle className="title" />
    case "endOfDay":
      return <EveningTitle className="title" />
    default:
      return <MorningTitle className="title" />
  }
}


const getSubtitle = (title: string) => {
  switch(title) {
    case "night":
      return "0pm"
    case  "morning":
      return "9am"
    case "cloudy":
      return "12pm";
    case "afternoon":
      return "4pm";
    case "evening":
      return "6pm"
    default:
      return "6pm";
  }
}
export default Opener;

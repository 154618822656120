import { parseIsolatedEntityName } from "typescript";

export const JOIN_GAME_REQUEST = "logging/JOIN_GAME_REQUEST";
export const JOIN_GAME_SUCCESS = "logging/JOIN_GAME_SUCCESS";
export const JOIN_GAME_ERROR = "logging/JOIN_GAME_ERROR";

export const SET_STEP_REQUEST = "logging/SET_STEP_REQUEST";
export const SET_STEP_SUCCESS = "logging/SET_STEP_SUCCESS";

export const CHECK_GAME_REQUEST = "logging/CHECK_GAME_REQUEST";
export const CHECK_GAME_SUCCESS = "logging/CHECK_GAME_SUCCESS";
export const CHECK_GAME_ERROR = "logging/CHECK_GAME_ERROR";

export const RESET_GAME = "logging/RESET_GAME";

// Actions
export const SET_BUSINESS_REQUEST = "logging/SET_BUSINESS";
export const SET_LOCATION_REQUEST = "logging/SET_LOCATION";
export const SET_INVENTORY_REQUEST = "logging/SET_INVENTORY";
export const SET_MARKETING = "loggin_SET_MARKETING";
export const SET_MARKETING_REQUEST = "logging/SET_MARKETING_REQUEST";
export const SET_MARKDOWN_REQUEST = "logging/SET_MARKDOWN_REQUEST";

export const GET_FINANCIAL_REQUEST = "logging/GET_FINANCIAL_REQUEST";
export const GET_FINANCIAL_SUCCESS = "logging/GET_FINANCIAL_SUCCESS";
export const GET_FINANCIAL_ERROR = "logging/GET_FINANCIAL_ERROR";

export const GET_BEST_ACTIONS_REQUEST = "logging/GET_BEST_ACTIONS_REQUEST";
export const GET_BEST_ACTIONS_SUCCESS = "logging/GET_BEST_ACTIONS_SUCCESS";

export const SET_DEMOGRAPHICS = "logging/SET_DEMOGRAPHICS";

export const GET_DEMOGRAPHICS_REQUEST = "logging/GET_DEMOGRAPHICS_REQUEST";
export const GET_DEMOGRAPHICS_SUCCESS = "logging/GET_DEMOGRAPHICS_SUCCESS";

export const GET_GAME_INFO_REQUEST = "logging/GET_GAME_INFO_REQUEST";
export const GET_GAME_INFO_SUCCESS = "logging/GET_GAME_INFO_SUCCESS";

export const GET_GAME_SCORES_REQUEST = "logging/GET_GAME_SCORES_REQUEST";
export const GET_GAME_SCORES_SUCCESS = "logging/GET_GAME_SCORES_SUCCESS";

export const CAN_I_MOVE_REQUEST = "logging/CAN_I_MOVE_REQUEST";
export const CAN_I_MOVE_SUCCESS = "logging/CAN_I_MOVE_SUCCESS";

export const GET_CHART_REQUEST = "logging/GET_CHART_REQUEST";
export const GET_CHART_SUCCESS = "logging/GET_CHART_SUCCESS";

export const KICK_PLAYER_REQUEST = "logging/KICK_PLAYER";

export const GET_SLIDE_OPTIONS = "logging/GET_SLIDE_OPTIONS"
export const GET_SLIDE_OPTIONS_SUCCESS = "logging/GET_SLIDE_OPTIONS_SUCCESS"

export const GET_SLIDE = "logging/GET_SLIDE"
export const GET_SLIDE_SUCCESS = "logging/GET_SLIDE_SUCCESS"

export const DISCOVER_SLIDE = "logging/DISCOVER_SLIDE"
export const DISCOVER_SLIDE_SUCCESS = "logging/DISCOVER_SLIDE_SUCCESS"

export interface ISingleFinancial {
  sales: number;
  costs: number;
  profit: number;
}
export interface IFinancial {
  sales: number[];
  costs: number[];
  profit: number[];
}
export interface ILoggingState {
  loginError?: {
    field: string;
    error: string;
  };
  gameError?: string;
  demographics?: boolean;

  canMove?: boolean;

  numberPlayersTotal?: number;
  numberPlayersConfirmed?: number;

  demographicsData: any;

  currentGame?: string;
  currentName?: string;
  currentStep?: number;
  currentRandomId?: number;
  stepWasBackstep?: boolean;
  business?: number;
  location9am?: number;
  location12pm?: number;
  location4pm?: number;

  inventory?: number;

  marketing?: number;
  marketingDuration?: number;

  markdown?: number;

  financial: IFinancial;

  results?: any;

  slideOptions?: any;
  imageSlide?: any;
  discoveredSlide?: any;

  gameInfo: any;

  score?: number;
  scoreIndex?: number;
  ranking?: any[];

  charts?: any[];
  checking: any;
  loginResult: any;
}
export interface IJoinGameSuccessPayload {
  currentGame: string;
  currentStep: number;
  currentName: string;
  currentRandomId: string;
}

export interface IJoinGamePayload {
  name: string;
  gameId: string;
  code: string;
  reset?: boolean;
}
export interface IJoinGameRequest {
  type: typeof JOIN_GAME_REQUEST;
  payload: IJoinGamePayload;
}
export interface IJoinGameSuccess {
  type: typeof JOIN_GAME_SUCCESS;
  payload: IJoinGameSuccessPayload;
}
export interface IJoinGameError {
  type: typeof JOIN_GAME_ERROR;
  payload?: {
    field: string;
    error: string;
  };
}

export interface IGameRequest {
  gameId?: string;
  userId?: string;
  random?: number;
}
export interface ISetStepPayload extends IGameRequest {
  step: number;
  isBackStep?: boolean;
}
export interface ISetStep {
  type: typeof SET_STEP_REQUEST;
  payload: ISetStepPayload;
}
export interface ISetStepSucess {
  type: typeof SET_STEP_SUCCESS;
  payload: {
    step: number;
    isBackStep: boolean
  }
}

export interface ICanMovePayload {
  canMove: boolean;
  numberPlayersTotal: number;
  numberPlayersConfirmed: number;
}
export interface ICheckGameRequest {
  type: typeof CHECK_GAME_REQUEST;
  payload: IGameRequest;
}
export interface ICheckGameSuccess {
  type: typeof CHECK_GAME_SUCCESS;
  payload: number;
}
export interface ICheckGameError {
  type: typeof CHECK_GAME_ERROR;
}
export interface IResetGame {
  type: typeof RESET_GAME;
}

export interface ISetBusinessPayload extends IGameRequest {
  business: number;
}
export interface ISetBusiness {
  type: typeof SET_BUSINESS_REQUEST;
  payload: ISetBusinessPayload;
}

export interface ISetLocationPayload extends IGameRequest {
  location: number;
  time: string;
}
export interface ISetLocation {
  type: typeof SET_LOCATION_REQUEST;
  payload: ISetLocationPayload;
}
export interface ISetInventoryPayload extends IGameRequest {
  inventory: number;
  step: number;
}

export interface ISetInventory {
  type: typeof SET_INVENTORY_REQUEST;
  payload: ISetInventoryPayload;
}

export interface IGetFinancialRequestPayload extends IGameRequest {
  time: "9am" | "12pm" | "4pm" | "6pm";
}
export interface IGetFinancialSuccessPayload extends ISingleFinancial {
  time: "9am" | "12pm" | "4pm" | "6pm";
}
export interface IGetFinancialRequest {
  type: typeof GET_FINANCIAL_REQUEST;
  payload: IGetFinancialRequestPayload;
}
export interface IGetFinancialSuccess {
  type: typeof GET_FINANCIAL_SUCCESS;
  payload: IGetFinancialSuccessPayload;
}
export interface IGetFinancialError {
  type: typeof GET_FINANCIAL_ERROR;
}

export interface ISetMarketing {
  type: typeof SET_MARKETING;
  payload: number | undefined;
}
export interface ISetMarketingPayload extends IGameRequest {
  marketing: number;
  marketingDuration: number;
}
export interface ISetMarketingRequest {
  type: typeof SET_MARKETING_REQUEST;
  payload: ISetMarketingPayload;
}
export interface ISetMarkdownRequestPayload extends IGameRequest {
  markdown: number;
}
export interface ISetMarkdownRequest {
  type: typeof SET_MARKDOWN_REQUEST;
  payload: ISetMarkdownRequestPayload;
}

export interface IGetBestActionsRequest {
  type: typeof GET_BEST_ACTIONS_REQUEST;
  payload: IGameRequest;
}

export interface IGetBestActionsSuccess {
  type: typeof GET_BEST_ACTIONS_SUCCESS;
  payload: any; //TODO define
}
export interface ISetDemographics {
  type: typeof SET_DEMOGRAPHICS;
  payload: boolean;
}


export interface IGetDemographicsRequest {
  type: typeof GET_DEMOGRAPHICS_REQUEST;
  payload: IGameRequest;
}

export interface IGetDemographicsSuccess {
  type: typeof GET_DEMOGRAPHICS_SUCCESS;
  payload: any;
}

export interface IGetGameInfoRequest {
  type: typeof GET_GAME_INFO_REQUEST;
  payload: string;
}
export interface IGetGameInfoSuccess {
  type: typeof GET_GAME_INFO_SUCCESS;
  payload: any;
}
export interface IGetGameScoreRequest {
  type: typeof GET_GAME_SCORES_REQUEST;
  payload: IGameRequest;
}
export interface IGetGameScoreSuccess {
  type: typeof GET_GAME_SCORES_SUCCESS;
  payload: {
    score: number, scoreIndex: number, ranking: any[]
  };
}
export interface ICanIMoveRequest {
  type: typeof CAN_I_MOVE_REQUEST;
  payload: ISetStepPayload
}
export interface ICanIMoveSuccess {
  type: typeof CAN_I_MOVE_SUCCESS;
  payload: ICanMovePayload;
}

export interface IGetChartRequestPayload {
  gameId: string;
  slide: string;
}
export interface IGetChartRequest {
  type: typeof GET_CHART_REQUEST,
  payload: IGetChartRequestPayload
}

export interface IGetChartSuccess {
  type: typeof GET_CHART_SUCCESS,
  payload: any
}
export interface IKickPlayer {
  type: typeof KICK_PLAYER_REQUEST,
  payload: IGameRequest
}

export interface IGetSlideOptionsPayload {
  [key: string]: string[]
}
export interface IGetSlideOptions {
  type: typeof GET_SLIDE_OPTIONS,
  payload: { 
    gameId: string
  }
}
export interface IGetSlideOptionsSuccess {
  type: typeof GET_SLIDE_OPTIONS_SUCCESS,
  payload: IGetSlideOptionsPayload
}

export interface IGetSlide {
  type: typeof GET_SLIDE,
  payload: { 
    index: number,
    gameId: string
  }
}
export interface IGetSlidePayload {
  slide: string;
}
export interface IGetSlideSuccess {
  type: typeof GET_SLIDE_SUCCESS,
  payload: IGetSlidePayload
}

export interface IDiscoverSlide {
  type: typeof DISCOVER_SLIDE,
  payload: {
    granularities: string[]
  }
}
export interface IDiscoverSlideSuccess {
  type: typeof DISCOVER_SLIDE_SUCCESS,
  payload: any
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { BusinessResume } from "../../components/business-resume/BusinessResume";
import CTABig from "../../components/cta-big/CTABig";
import Donut from "../../components/donut/Donut";
import Icon from "../../components/icon/Icon";
import { getBusinessColor, KermisLogo } from "../../components/illustration/Illustration";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import Slider from "../../components/slider/Slider";
import Topbar from "../../components/topbar/Topbar";
import { Body2Bold, Text1Light, Text1Bold, Body2Light, Header1Bold, Text2Bold, Title1Medium, Title2Bold, Title2Light } from "../../components/typography/Typography";
import { IAppState } from "../../store";
import { getGameScoresRequest, resetGame, setInventory } from "../../store/logging/actions";
import Left, { LeftOverlayContainer, LeftTitle } from "../default/Left";
import styled, { css } from "styled-components";
import "./final-score.scss";
import { IFinancial } from "../../store/logging/types";
import { LeftIllustration } from "../../components/left-illustration/LeftIllustration";
import * as d3 from "d3";

import * as confetti from "canvas-confetti"

export const DonutInsideLabel = styled(Body2Bold)(
  ({theme}) => css`
    color: ${theme.colors.yellow.yellow25};
`)

export const StarScore = styled.div(
  ({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.yellow.yellow25};

  .star-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    > svg {
      opacity: 0;
      animation: star-anim 0.5s forwards;
      fill: #FFBA22;

    }
  }
  @keyframes star-anim {
    from {
      opacity: 0;
      transform: scale(10);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  `
)

let prevScroll = 0;

const donutScale = d3.scaleLinear().domain([0, 100]).range([0, 100]).clamp(true)
const starScale = d3.scaleLinear().domain([0, 50, 75, 85, 100]).range([1, 2, 3, 4, 5]).clamp(true)
export const FinalScore: React.FC = () => {
  const [stars, setStarts] = useState([]);

  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  
  const business = useSelector(
    (state: IAppState) => state.loggingReducer.business
  );
  const theme: any = useTheme();

  const ranking: any = useSelector(
    (state: IAppState) => state.loggingReducer.ranking
  );

  useEffect(() => {
    if(gameId != undefined && userId != undefined) {

      dispatch(getGameScoresRequest({ gameId, userId }));
      // const interval = setInterval(() => {
      //   dispatch(getGameScoresRequest({ gameId, userId }));
      // }, 4000)

      var myCanvas = document.querySelector('#confetti-canvas');
      var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true
      });
      setTimeout(() => {
        myConfetti({
          particleCount: 200,
          spread: 160
          // any other options from the global
          // confetti function
        });
      }, 1000)

      // return () => clearInterval(interval)
    }

    
  }, []);
  const [visible, setVisible] = useState(true);  

  const handleScroll = (element: any) => {
    if(element) {
      const currentScrollPos = element.scrollTop;
      
      setVisible((prevScroll > currentScrollPos && prevScroll - currentScrollPos > 0) || currentScrollPos < 10);
      prevScroll = currentScrollPos
    }
  }
  useEffect(() => {

    const element = document.querySelector("#scroll-target");
    if(element) {
      element.addEventListener('scroll', () => handleScroll(element));
      return () => element.removeEventListener('scroll', () => handleScroll(element));
    } 
  }, []);

  const score = useSelector((state: IAppState) => state.loggingReducer.score) || 0
  const scoreIndex = useSelector((state: IAppState) => state.loggingReducer.scoreIndex);

  let numStars = [];
  for(let i = 0; i < starScale(score); i++) {
    numStars.push(i);
  }
  
  return (
    <div className="page inventory">
      <Left>
        <LeftOverlayContainer>
          <LeftTitle>
            <KermisLogo />
          </LeftTitle>
          <BusinessResume>
              <Title2Light>Congratulations! Your Final Score</Title2Light>
          </BusinessResume>
        </LeftOverlayContainer>
        <LeftIllustration business={business} timeOfDay="evening" withProp={scoreIndex != undefined && scoreIndex < 3 ? "trophy" : "empty"}/>
      </Left>
      <div className="final-score-right">
        <div className="final-score-content">
          <Topbar hide={!visible} hideDemographics/>
          <Score>
            <Icon name={`medal-${scoreIndex}`} width="60" height="60" />
            <Text1Bold>{scoreIndex}{getOrder(scoreIndex || 0)} Place</Text1Bold>
            <div className="inside"></div>
          </Score>
          <div className="final-score-right-inside" id="scroll-target">
            <Header1Bold>Hurray!</Header1Bold>
            <Body2Light>Your Score:</Body2Light>
            <div className="canvas-container">
              <canvas id="confetti-canvas" height="1000" width="1500"/>
            </div>
            <StarScore>
              {score < 0 && <Body2Bold>Poor</Body2Bold>}
              {score >= 0 && score <= 65 && <Body2Bold>Good</Body2Bold>}
              {score > 65 && score <= 85 && <Body2Bold>Great</Body2Bold>}
              {score > 85 && score <= 95 && <Body2Bold>Excellent</Body2Bold>}
              {score > 95 && <Body2Bold>Incredible!!</Body2Bold>}
              <div className="star-container">
                {numStars.map((star, index) => <Icon name="star" style={{animationDelay: `${index * 0.5 + 2}s`}}/>)}
              </div>
            </StarScore>
            <Donut value={donutScale(score)} size={250} foregroundColor={{fill: theme.colors.yellow.yellow25}}>
              {/* <Donut specialTransform="translate(0, 16)" value={87} size={215} foregroundColor={{fill: theme.colors.red.red25}}>
                <Donut specialTransform="translate(0, 16)" value={17} size={179} foregroundColor={{fill: theme.colors.green.green25}}>
                  <div className="inside-donut">
                    <DonutInsideLabel>{profitScore}</DonutInsideLabel>
                  </div>
                </Donut>
              </Donut> */}
              <div className="inside-donut">
                <DonutInsideLabel>{score.toFixed(0)}</DonutInsideLabel>
                <DonutInsideLabel>Points</DonutInsideLabel>
              </div>
            </Donut>
          </div>
          {ranking && <Ranking users={ranking}/>}
        </div>
        <CTABig
          backgroundColor={theme.colors.red.red50}
          onClick={() => dispatch(resetGame())}
        >
          <Icon name="right-arrow" />
          <Title2Light>Start a new game</Title2Light>
        </CTABig>
      </div>
    </div>
  );
};

export const Score = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0px;
    right: 135px;
    height: 118px;
    width: 127px;
    background-color: ${theme.colors.yellow.yellow50};
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
    .inside {
      position: absolute;
      bottom: 0px;
      width: 0; 
      height: 0; 
      border-left: 66px solid transparent;
      border-right: 66px solid transparent;
      border-bottom: 30px solid white;
    }
    > svg {
      fill: ${theme.colors.black};
    }
`
)


interface IRankingProps {
  users: any[];
}

let opacityScale: any = d3.scaleLinear<any, any>().domain([0, 1, 2, 3]).range(["#ffba2242", "#ffcb5933", "#ffcb591f", "white"]).clamp(true)
const Ranking: React.FC<IRankingProps> = ({ users })  => {

  return <StyledUserList>
    {users.map((d:any,i:number) => <StyledUserItem position={i} key={d.name}>
        <Icon name={`medal-${i+1}`} />
        <Text1Bold>{i+1}{getOrder(i+1)}</Text1Bold>
        <Text1Light className="user">{d.player_id}</Text1Light>
        <Text1Light>{d.score.toFixed(0)} points</Text1Light>
      </StyledUserItem>)}
    </StyledUserList>
}

const getOrder = (place:number) => {
  const rest = place % 10;

  if(rest == 1) {
    return "st"
  } else if(rest == 2) {
    return "nd"
  } else if(rest == 3) {
    return "rd"
  } else {
    return "th"
  }
} 

const StyledUserList = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    
`)

const StyledUserItem = styled.div<{ position: number }>(
  ({ theme, position }) => css`
    max-width: 800px;
    margin: 8px 0px;
    min-width: 500px;
    display: flex;
    padding: 13px 16px;
    justify-content: space-between;
    align-items: center;
    background-color: ${opacityScale(position)};
    > * {
      padding: 0px 8px;
    }
    .user {
      flex: 1;
      text-align: left;
    }
`
)
export default FinalScore;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Body2Bold } from "../../components/typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { discoverSlide, getSlide as getSlideAction } from "../../store/logging/actions";
import { IAppState } from "../../store";

export const Slide: React.FC<RouteComponentProps> = ({
    location
}) => {
    const [imageSrc, setImageSrc] = useState<any>();
    const gameId = useSelector(
      (state: IAppState) => state.loggingReducer.currentGame
    );
    const [slideName, setSlideName] = useState();
    const [_, grans] = location!.pathname.split("/slide/");

    const dispatch = useDispatch();

    const slides = useSelector(
        (state: IAppState) => state.loggingReducer.discoveredSlide
    );

    const imgSlide = useSelector(
        (state: IAppState) => state.loggingReducer.imageSlide
    );

    const getSlide = async () => { 
        
        const granularities = decodeURI(grans).split("_");
        dispatch(
            discoverSlide({
                granularities
            })
        )
    }

    useEffect(() => {
        if(slides) {
            console.log(slides);
            const slideIndex = slides.slide_index;
            if(slideIndex != -1) {
                setSlideName(slides.slide);
                dispatch(getSlideAction({
                    index: slideIndex,
                    gameId: gameId
                }));
            }
        }
    }, [slides])

    useEffect(() => {
        if(location && grans) {
            getSlide();
        }
    }, [location])



    return <div className="flex flex-col items-center p-[5%] w-full h-screen">
        {slideName && <Helmet>
            <meta charSet="utf-8" />
            <title>{slideName}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>}
        <div className="px-8 pt-4">
            <Body2Bold>{slideName}</Body2Bold>
        </div>
        {imgSlide && <img className="h-screen" src={imgSlide} />}
    </div>
}
export default withRouter(Slide);
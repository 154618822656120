import React from "react";
import styled, { css, useTheme } from "styled-components";
import * as d3 from "d3";
import { Text2Roman, Text4Bold, Title1Medium, Title2Light } from "../typography/Typography";
import classnames from "classnames";
import "./financial-card.scss";
import { isGetAccessorDeclaration } from "typescript";
import Icon from "../icon/Icon";
import { min } from "d3";
interface IDataPoint {
  label: string;
  value: number | null;
}
interface IFinancialCardProps {
  title: string;
  value: number;
  unit: string;
  chartTitle: string;
  chartData: IDataPoint[];
  chartData2?: IDataPoint[];
  currentPartOfDay: string;
  isNegative?: boolean;
  color?: string;
  min: number;
  max: number;
}
const FinancialCard: React.FC<IFinancialCardProps> = ({
  title,
  value,
  chartTitle,
  unit,
  currentPartOfDay,
  chartData,
  isNegative,
  min,
  max,
  chartData2,
  color,
}) => {
  return (
    <StyledFinancialCard>
      <div className="financial-card-header">
        <Text2Roman>{title}</Text2Roman>
        <Title1Medium className={classnames("value", { negative: isNegative })}>
          {value < 0 && "-"}
          {unit}
          {Math.abs(value)}
          {/* <Icon name={isNegative ? "down" : "up"} /> */}
        </Title1Medium>
        <Text4Bold>{chartTitle}</Text4Bold>
      </div>
      <Chart min={min} max={max} data={chartData} data2={chartData2} currentPartOfDay={currentPartOfDay} color={color}/>
    </StyledFinancialCard>
  );
};

class Chart extends React.Component<{ data: any[], data2?: any[], currentPartOfDay: string, color?: string, color2?: string, max?: number, min?: number}, {}> {
  svgElement: any;
  svg: any;
  chartContainer: any;
  xScale: any;
  yScale: any;

  height: any;
  width: any;
  chartWidth: any;
  chartHeight: any;
  public margin = { top: 8, left: 0, right: 0, bottom: 25 };
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    setTimeout(() => {

      let firstNullIndex = this.props.data.findIndex((d:any) => d && d.value == null);
      
      if(firstNullIndex > 0) {
        const data = this.props.data.slice(0, firstNullIndex)
        let data2 = null;
        if(this.props.data2) {
          data2 = this.props.data2.slice(0, firstNullIndex)
        }
        this.build(data, data2);
      } else {
        this.build(this.props.data, this.props.data2);
      }
    }, 500)
  }
  componentDidUpdate() {
    setTimeout(() => {

      let firstNullIndex = this.props.data.findIndex((d:any) => d.value == null);
      
      if(firstNullIndex > 0) {
        const data = this.props.data.slice(0, firstNullIndex)
        let data2 = null;
        if(this.props.data2) {
          data2 = this.props.data2.slice(0, firstNullIndex)
        }
        this.build(data, data2);
      } else {
        this.build(this.props.data, this.props.data2);
      }
    }, 1000)
  }
  getChartDimensions() {
    if (this.svgElement) {
      this.height = (
        this.svgElement.height || this.svgElement.parentNode.clientHeight
      ).baseVal.value;
      this.width = (this.svgElement.width || this.svgElement.parentNode.clientWidth).baseVal.value;
      this.chartHeight = this.height - this.margin.top - this.margin.bottom;
      this.chartWidth = this.width - this.margin.right - this.margin.left;
    }
  }

  build(data: any, data2: any) {
    this.svg = d3.select(this.svgElement);
    this.getChartDimensions();
    this.chartContainer = appendOrSelect(
      this.svg,
      ".chart-container",
      "g",
      "chart-container"
    );

    this.chartContainer.attr(
      "transform",
      `translate(${this.margin.left},${this.margin.top})`
    );
    this.buildScales(data, this.props.min || 0, this.props.max || 0, data2);
    this.buildAxis();
    this.buildBackground(this.props.currentPartOfDay)

    if(data.filter((f:any) => f.value != null).length == 1) {
      this.buildPoints(data.filter((f:any) => f.value != null))
    } else {
      this.buildLine(data, data2);
    }
  }
  buildScales(data: any,min: number, max: number, data2?: any ) {
    this.xScale = d3
      .scaleBand()
      .domain(["9am", "12pm", "4pm", "6pm"])
      .range([0, this.chartWidth]);

    // let min: any =
    //   d3.min(
    //     data.filter((f: any) => f.value != null),
    //     (d: any) => d.value
    //   ) || 0;
    // let max: any =
    //   d3.max(
    //     data.filter((f: any) => f.value != null),
    //     (d: any) => d.value
    //   ) || 0;

    // if(data2) {
    //   // const min2 =
    //   // d3.min(
    //   //   data2.filter((f: any) => f.value != null),
    //   //   (d: any) => d.value
    //   // ) || 0;
    //   // const max2 =
    //   //   d3.max(
    //   //     data2.filter((f: any) => f.value != null),
    //   //     (d: any) => d.value
    //   //   ) || 0;

    //   minS = d3.min([min, min2 || 0]);
    //   maxS = d3.max([max, max2 || 0]);
    // }
    this.yScale = d3.scaleLinear().domain([min, max]).range([this.chartHeight, 10]);
  }
  buildAxis() {
    const xAxisElement = appendOrSelect(
      this.chartContainer,
      ".x",
      "g",
      "axis x"
    );
    let xAxis = d3.axisBottom(this.xScale).tickSize(5).ticks(15);
    xAxisElement
      .attr("transform", "translate(0," + this.chartHeight + ")")
      // .transition()
      .call(xAxis);
    return { xAxisElement, xAxis };
  }
  buildLine(data: any, data2?: any) {
    if(!data) {
      return;
    }
    const line = d3
      .line()
      .curve(d3.curveBasis)
      .defined((d: any) => d && d.value !== null)
      .x((d: any) => d ? this.xScale(d.label) + this.xScale.step() / 2 : 0)
      .y((d: any) => d && d.value != null ? this.yScale(d.value) : 0);

    const lineDom: any = this.chartContainer.selectAll(".line").data(data2 ? [data, data2] : [data]);

    lineDom
      .enter()
      .append("path")
      .attr("class", "line")
      .merge(lineDom)
      .attr("d", (d: any) => line(d))
      .attr("stroke", (d:any, i:number) => i == 0 ? (this.props.color ? this.props.color : "orange") : (this.props.color2 || "red"))
      .attr("fill", "none")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", function(this: any) {
        const totalLength = d3.select(this).node().getTotalLength();
        return `${totalLength} ${totalLength}`;
      })
      .style("stroke-dashoffset", function strokeDashOffset(this: any) {
        const totalLength = d3.select(this).node().getTotalLength();
        return totalLength;
      }).transition().duration(1000).style("stroke-dashoffset", "0px")
      
  }
  buildPoints(data: any) {
    const circle = this.chartContainer.selectAll(".circle").data(data)

    circle.enter().append("circle").attr("class", "circle")
      .attr("r", 5).attr("cx", (d:any)=> this.xScale(d.label) + this.xScale.bandwidth() / 2)
      .attr("cy", (d:any) => this.yScale(d.value))
      .attr("fill", "#ED5B41")

  }
  buildBackground(selected: string) {

    const background = appendOrSelect(this.chartContainer, ".background", "rect", "background")

    background.attr("width", this.xScale.bandwidth)
      .attr("x", this.xScale(selected))
      .attr("rx", 5).attr("ry", 5)
      .attr("y", 0).attr("height", this.height)
      .attr("fill", "#B8B9B5").attr("fill-opacity", 0).transition().duration(500).delay(500)
      .attr("fill-opacity", 0.15)

      d3.selectAll(".tick").select("text").attr("fill", (d) => {
        if(d == selected) {
          return "#ED5B41";
        } else {
          return "#B8B9B5";
        }
      })
  }
  render() {
    return (
      <svg
        ref={(node) => {
          this.svgElement = node;
        }}
        width="100%"
        height="146"
      ></svg>
    );
  }
}
const appendOrSelect = (
  container: any,
  selector: any,
  element: any,
  classString: string
) => {
  let result = container.select(selector);
  if (result.empty()) {
    result = container.append(element).attr("class", classString);
  }
  return result;
};
const StyledFinancialCard = styled.div(
  ({ theme }) => css`
    height: 308px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${theme.colors.grey.grey50};
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 8px;
    opacity: 0;
    animation: fade-in 0.5s forwards;

    .financial-card-header {
      padding: 8px;
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: space-between;
      flex: 1;
    }
    .value {
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: ${theme.colors.green.green25};
      > svg {
        margin-left: 24px;
      }
      &.negative {
        color: ${theme.colors.alert.alert50};
      }
    }
    &:last-child {
      border-right: ${"2px solid " + theme.colors.grey.grey25};
    }
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
);

export default FinancialCard;

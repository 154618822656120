
import { useEffect, useState } from "react";
import { ReactComponent as GiantWheel } from "../../assets/images/illustrations/big_ferris_wheel.svg";
import { ReactComponent as Tent } from "../../assets/images/illustrations/big_tent.svg";
import { Title2Bold } from "../../components/typography/Typography";
import Left from "../default/Left";
import { useDispatch, useSelector } from "react-redux";
import { checkGameRequest, joinGameRequest } from "../../store/logging/actions";
import { IAppState } from "../../store";
import { RouteComponentProps } from "react-router-dom";
import { Buffer } from "buffer";
import { api } from "../../sagas/logging";

let i = 0;
export const JoinGame: React.FC<RouteComponentProps> = ({ location, history }) => {

    const dispatch = useDispatch();
    const loginError = useSelector(
        (state: IAppState) => state.loggingReducer.loginError
    );
    const loginResult = useSelector(
        (state: IAppState) => state.loggingReducer.loginResult
    );
    const checking = useSelector(
        (state: IAppState) => state.loggingReducer.checking
    );
    const appState = useSelector(
        (state: IAppState) => state.loggingReducer
    );
    const [checked, setChecked] = useState(false);

    const login = async (gameId: string, userId: string, code: string) => {
        try {
            dispatch(
                joinGameRequest({
                  gameId,
                  name: userId,
                  code: Buffer.from(code).toString('base64'),
                  reset: true
                })
            );
            // const { data, status } = await api.postJoinGame({
            //   gameid: gameId.toUpperCase(),
            //   userid: userId,
            //   code: Buffer.from(code).toString("base64")
            // });
            // if (status === 200) {
                // setStep(data.currentStep as ESteps);
                // setAuth({ gameid: gameId.toUpperCase(), userid: userId });
                // navigate("/")
            // }
        } catch(err:any) {
            console.log("ERRORR LOGING IN", err)
            setError(err.error.error);
        }
    }
    const [error, setError] = useState<string | null>()

    useEffect(() => {
        if(loginError) {
            console.log("Login Error", loginError)
            setError(loginError.error)
        }
    }, [loginError])

    useEffect(() => {
        if(checked && loginResult && loginResult == 'success') {
            console.log("REDIRECTING TO GAMEJOIN")
            dispatch(
                checkGameRequest({
                    gameId: appState.currentGame,
                    userId: appState.currentName,
                    random: appState.currentRandomId
                })
            )
        }
    }, [loginResult])


    // useEffect(() => {
    //     console.log({ appState, checked })
    //     if (checked && appState.currentGame != undefined && appState.currentName !== undefined && location) {
    //         console.log("NEw State", appState)
    //         const Urlparams = new URLSearchParams(location.search);
    //         const parameter1 = Urlparams.get("json");
    //         if(parameter1) {
    //             const params = JSON.parse(decodeURIComponent(parameter1));
    //             console.log(appState, params)
    //             if(params.gameId && params.userId && params.code) {

    //                 console.log("RANDOM ID", appState.currentRandomId, appState.checking.random)
    //                 if(appState.currentGame == params.gameId && appState.currentName == params.userId && appState.currentRandomId == appState.checking.random) {
    //                     history.push({
    //                         pathname: "/gamejoin",
    //                         state: { cameFromLogin: false, automaticJoin: true },
    //                     });
    //                 } else {
    //                     i = i+1
    //                     console.log("Login Request...");
    //                     if(i < 15) {
    //                         login(params.gameId, params.userId, params.code)
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }, [appState, location, checked]);

    useEffect(() => {
        if(checked && Object.keys(checking).length > 0 && location) {
            console.log("Checking again")
            if(checking.state !== 'Error') {
                history.push({
                    pathname: "/gamejoin",
                    state: { cameFromLogin: false, automaticJoin: true },
                });
            } else {
                const Urlparams = new URLSearchParams(location.search);
                const parameter1 = Urlparams.get("json");
                if(parameter1) {
                    const params = JSON.parse(decodeURIComponent(parameter1));
                    console.log(appState, params)
                    if(appState.checking && appState.checking.state == 'Error') {
                        login(params.gameId, params.userId, params.code)
                    }
                }
            }
        } 
    }, [checked, checking])

    useEffect(() => {
        if(location) {
            const params = new URLSearchParams(location.search);
            const parameter1 = params.get("json");
            
            if(parameter1) {
                const params = JSON.parse(decodeURIComponent(parameter1));
                if(params.gameId && params.userId && params.code) {
                    
                    if(appState.currentGame !== params.gameId || appState.currentName !== params.userId) {
                        // setError("This browser was being used in another game")
                        login(params.gameId, params.userId, params.code)
                    } else {
                        // Check Game
                        dispatch(
                            checkGameRequest({
                                gameId: params.gameId.toUpperCase(),
                                userId: params.userId,
                                random: appState.currentRandomId
                            })
                        )
                        setChecked(true);
                    }

                }
            } else {
                setError("No Parameters detected")
            }
        }
    }, [location])

    return <div style={{width: "100%", height: "100vh", position: "relative"}}>
        <Left className="left" flexWidth="1" backgroundColor="linear-gradient(180deg, #A0C2F5 0%, #BFD6F9 49.26%)" style={{"height": "100%"}}>
            <GiantWheel style={{ position: "absolute", right: 0, bottom: "0px", width: "300px" }} />
            <Tent style={{ position: "absolute", left: 0, bottom: 0, width: "500px" }} />
        </Left>
        <div style={{"position": "absolute", top: "0px", left: "0px", "width": "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            {error ? <Title2Bold>{error}</Title2Bold> : <Title2Bold>Joining Game...</Title2Bold>}
        </div>
    </div>
}
export default JoinGame;
import { dispatch } from "d3";
import React from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled, { css } from "styled-components";
import { resetGame } from "../../store/logging/actions";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";
import { getBackgroundImage } from "../illustration/Illustration";
import { Body2Light, Title2Bold } from "../typography/Typography";
const StyledModal = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(10, 10, 10, 0.7);
    z-index: 1;
  `
);
const ModalImage = styled.div(
  () => css`
    width: 250px;
    background-image: url(${getBackgroundImage("WelcomeImage")});
    background-size: cover;
    background-position: bottom;
    border-radius: 16px;
  `
);

const ModalInside = styled.div(
  ({ theme }) => css`
    height: 430px;
    border-radius: 10px;
    background-color: white;
    display: flex;
  `
);

const ModalContent = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
    `
);

const ModalContentText = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
      padding: 0px 40px 40px 40px;
      flex: 1;
      justify-content: space-around;
      text-align: left;
      .button-container {
        display: flex;
        > * {
          margin: 0px 8px;
        }
      }
    `
);

const StyledBody2Light = styled(Body2Light)(
  () => css`
    max-width: 400px;
  `
);

interface IModalProps {
  onClickOutside?: () => void;
  onClose?: () => void;
  onRestart?: () => void;
  onContinue?: () => void;
}
export const Modal: React.FC<IModalProps & RouteComponentProps> = ({
  history,
  onContinue,
  onClose,
}) => {
  const dispatch = useDispatch();

  const resetGameAux = () => {
    dispatch(resetGame());
    history.push("/");
  };
  return (
    <StyledModal>
      <ModalInside>
        <ModalImage />
        <ModalContent>
          {onClose && <div>Close</div>}
          <ModalContentText>
            <Title2Bold>Welcome Back</Title2Bold>
            <StyledBody2Light>
              Do you want to continue the game from where you left off?
            </StyledBody2Light>
            <div className="button-container">
              <SecondaryButton onClick={() => resetGameAux()}>
                No, start a new game
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  if (onContinue) {
                    onContinue();
                  }
                }}
              >
                Yes, continue from where I left off
              </PrimaryButton>
            </div>
          </ModalContentText>
        </ModalContent>
      </ModalInside>
    </StyledModal>
  );
};

export const ErrorModalWithoutRouter: React.FC<
  IModalProps & RouteComponentProps
> = ({ onClose, history }) => {
  const dispatch = useDispatch();

  const resetGameAux = () => {
    dispatch(resetGame());
    history.push("/");
  };
  return (
    <StyledModal>
      <ModalInside>
        <ModalImage />
        <ModalContent>
          {onClose && <div>Close</div>}
          <ModalContentText>
            <Title2Bold>
              It seems like something is wrong with this game...
            </Title2Bold>
            <div className="button-container">
              <SecondaryButton onClick={() => resetGameAux()}>
                Let's start a new game
              </SecondaryButton>
            </div>
          </ModalContentText>
        </ModalContent>
      </ModalInside>
    </StyledModal>
  );
};
export const ErrorModal = withRouter(ErrorModalWithoutRouter);
export default withRouter(Modal);

import {
  ILoggingState,
  JOIN_GAME_REQUEST,
  JOIN_GAME_SUCCESS,
  JOIN_GAME_ERROR,
  SET_STEP_SUCCESS,
  CHECK_GAME_ERROR,
  RESET_GAME,
  SET_BUSINESS_REQUEST,
  SET_LOCATION_REQUEST,
  SET_INVENTORY_REQUEST,
  GET_FINANCIAL_SUCCESS,
  SET_MARKETING,
  SET_MARKETING_REQUEST,
  SET_MARKDOWN_REQUEST,
  SET_DEMOGRAPHICS,
  GET_BEST_ACTIONS_SUCCESS,
  GET_DEMOGRAPHICS_SUCCESS,
  GET_GAME_INFO_SUCCESS,
  GET_GAME_SCORES_SUCCESS,
  CAN_I_MOVE_SUCCESS,
  GET_CHART_SUCCESS,
  GET_SLIDE_OPTIONS,
  GET_SLIDE_OPTIONS_SUCCESS,
  GET_SLIDE_SUCCESS,
  DISCOVER_SLIDE_SUCCESS,
  DISCOVER_SLIDE,
  GET_SLIDE,
  CHECK_GAME_SUCCESS,
  CHECK_GAME_REQUEST,
} from "./types";

export const defaultState: ILoggingState = {
  demographicsData: [],
  financial: {
    sales: new Array(4),
    profit: new Array(4),
    costs: new Array(4),
  },
  gameInfo: {},
  imageSlide: null,
  canMove: false,
  checking: false,
  loginResult: null
};

export const times = ["9am", "12pm", "4pm", "6pm"];

const resetGame = () => {
  return {
    loginError: undefined,
    gameError: undefined,
    demographics: undefined,

    demographicsData: [],
    currentGame: undefined,
    currentName: undefined,
    currentRandomId: undefined,
    currentStep: 0,
    stepWasBackstep: false,
    business: undefined,
    location9am: undefined,
    location12pm: undefined,
    location4pm: undefined,
    inventory: undefined,
    marketing: undefined,
    marketingDuration: undefined,
    markdown: undefined,
    numberPlayersTotal: undefined,
    numberPlayersConfirmed: undefined,

    results: undefined,
    canMove: false,

    score: undefined,
    scoreIndex: undefined,
    ranking: undefined,

    slideOptions: undefined,
    imageSlide: undefined,
    discoveredSlide: undefined,

    gameInfo: {},

    charts: [],

    checking: {},
    loginResult: null,

    financial: {
      sales: new Array(4),
      profit: new Array(4),
      costs: new Array(4),
    },
  };
};

export const loggingReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JOIN_GAME_REQUEST:
      return {
        ...state,
        loginResult: null
      };

    case GET_CHART_SUCCESS:

      let newCharts = state.charts || [];
      // newCharts = newCharts.concat(action.payload);

      const index = action.payload.index;
      if(index != null) {
        newCharts[index] = action.payload.blob;
        console.log(index, newCharts[index])
      }
      return {
        ...state,
        charts: newCharts.slice()
      }
    case CAN_I_MOVE_SUCCESS: {
      return {
        ...state,
        canMove: action.payload.canMove,
        numberPlayersTotal: action.payload.numberPlayersTotal,
        numberPlayersConfirmed: action.payload.numberPlayersConfirmed,
      }
    }
    case RESET_GAME:
      return {
        ...state,
        ...resetGame(),
      };
    case GET_GAME_SCORES_SUCCESS:
      return {
        ...state,
        score: action.payload.score,
        scoreIndex: action.payload.scoreIndex,
        ranking: action.payload.ranking,
      }
    case GET_GAME_INFO_SUCCESS:
      return {
        ...state,
        gameInfo: action.payload
      }
    case JOIN_GAME_SUCCESS:
      return {
        ...state,
        currentStep: action.payload.currentStep,
        currentGame: action.payload.currentGame,
        currentName: action.payload.currentName,
        currentRandomId: action.payload.currentRandomId,
        loginResult: "success"
      };
    case SET_STEP_SUCCESS:
      return {
        ...state,
        currentStep: action.payload.step,
        stepWasBackstep: action.payload.isBackStep,
      };
    case JOIN_GAME_ERROR:
      return {
        ...state,
        loginError: action.payload,
        loginResult: "error"
      };
    case SET_BUSINESS_REQUEST: //TODO change this to success
      return {
        ...state,
        business: action.payload.business,
      };
    case SET_INVENTORY_REQUEST:
      return {
        ...state,
        inventory: action.payload.inventory,
      };
    case SET_MARKETING:
      return {
        ...state,
        marketing: action.payload,
      };
    case SET_LOCATION_REQUEST:
      const { location, time } = action.payload;

      return { ...state, [time]: location };
    case SET_MARKETING_REQUEST:
      return {
        ...state,
        marketing: action.payload.marketing,
        marketingDuration: action.payload.marketingDuration,
      };
    case SET_MARKDOWN_REQUEST:
      return {
        ...state,
        markdown: action.payload.markdown,
      };
    case GET_FINANCIAL_SUCCESS:
      const financialIndex = times.indexOf(action.payload.time);
      let newCosts = state.financial.costs.slice(0);
      let newProfit = state.financial.profit.slice(0);
      let newSales = state.financial.sales.slice(0);

      if (financialIndex != -1) {
        newCosts[financialIndex] = +action.payload.costs.toFixed(0);
        newProfit[financialIndex] = +action.payload.profit.toFixed(0);
        newSales[financialIndex] = +action.payload.sales.toFixed(0);
      }
      return {
        ...state,
        financial: {
          costs: newCosts,
          profit: newProfit,
          sales: newSales,
        },
      };
    case CHECK_GAME_REQUEST:
      return {
        ...state,
        checking: {},
        loginResult: null
      }
    case CHECK_GAME_ERROR:
      console.log("Check game error")
      return {
        ...state,
        checking: { state: "Error" },
        gameError: "This Game seeems Broken...",
      };
    case CHECK_GAME_SUCCESS:
      console.log("Success Check", action.payload)
      return {
        ...state,
        checking: action.payload,
        gameError: undefined
      }
    case SET_DEMOGRAPHICS:
      return {
        ...state,
        demographics: action.payload,
      };
    case GET_BEST_ACTIONS_SUCCESS:
      return {
        ...state,
        results: action.payload
      }
    case GET_SLIDE_OPTIONS_SUCCESS:
      return {
        ...state,
        slideOptions: action.payload
      }
    case GET_SLIDE:
      return {
        ...state,
        imageSlide: null
      }
    case GET_SLIDE_SUCCESS:
      return {
        ...state,
        imageSlide: action.payload
      }
    case DISCOVER_SLIDE:
      return {
        ...state,
        imageSlide: null,
        discoveredSlide: null
      }
    case DISCOVER_SLIDE_SUCCESS:
      return {
        ...state,
        discoveredSlide: action.payload
      }
    case GET_DEMOGRAPHICS_SUCCESS:

      let dataWithTotal = action.payload.slice(0);
      dataWithTotal.push(dataWithTotal.reduce((a:any,total:any) => total+= a, 0));
      return {
        ...state,
        demographicsData: dataWithTotal
      }
    default:
      return state;
  }
};
export default loggingReducer;

import React, { useEffect, useState } from "react";
import "./location.scss";

import LocationChoice from "../../components/location-picker/LocationChoice";
import LocationList from "../../components/location-picker/LocationList";
import Left, { BackgroundLeft, LeftContent, LeftOverlayContainer, LeftTitle } from "../default/Left";
import StyledProgressBar from "../../components/progress-bar/ProgressBar";
import { IAppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "../../store/logging/actions";
import { locationList } from "../../tokens/definitions";
import Topbar from "../../components/topbar/Topbar";
import { Title2Bold } from "../../components/typography/Typography";
import { KermisLogo, WhiteKermisLogo } from "../../components/illustration/Illustration";
import { Map } from "../../components/illustration/Map";
import styled, { css } from "styled-components";
import { SeeSlidesButton, SlideExplorer, isOnlineGame } from "../../components/slide-explorer/SlideExplorer";
interface ILocationProps {
  locationPhase: "location9am" | "location12pm" | "location4pm";
  nextText?: string;
}

const StyledLocationTitle = styled(Title2Bold)`
  background-color: rgba(255,255,255,0.8);
  padding: 8px;
`
let prevScroll = 0;
export const Location: React.FC<ILocationProps> = ({ locationPhase, nextText }) => {
  const selectedLocation = useSelector(
    (state: IAppState) => state.loggingReducer[locationPhase]
  );
  const dispatch = useDispatch();

  const gameId = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const userId = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );

  const [hovered, setHovered] = useState<string | null>(null);

  const [activeSlideExplorer, setActiveSlideExplorer] = useState(false);

  const locationsAlreadyPicked = useSelector((state: IAppState) => [
    state.loggingReducer.location4pm,
    state.loggingReducer.location9am,
    state.loggingReducer.location12pm,
  ]);
  const [visible, setVisible] = useState(true);  

  const handleScroll = (element: any) => {
    if(element) {
      const currentScrollPos = element.scrollTop;
      
      setVisible((prevScroll > currentScrollPos && prevScroll - currentScrollPos > 0) || currentScrollPos < 10);
      prevScroll = currentScrollPos
    }
  }

  useEffect(() => {

    const element = document.querySelector("#scroll-target");
    if(element) {
      element.addEventListener('scroll', () => handleScroll(element));
      return () => element.removeEventListener('scroll', () => handleScroll(element));
    } 
  }, []);


  return (
    <div className="location page">
      <Left backgroundColor={activeSlideExplorer ? "linear-gradient(180deg, #06213A 0%, #1A548B 100%)" : "initial"}>
        <LeftOverlayContainer>
          <LeftTitle>
            {activeSlideExplorer ? <WhiteKermisLogo /> : <KermisLogo />}
            {gameId && isOnlineGame(gameId) && <SeeSlidesButton active={activeSlideExplorer} setActive={setActiveSlideExplorer}/>}
          </LeftTitle>
          {activeSlideExplorer ? 
          <SlideExplorer /> :
          <LeftContent>
              {selectedLocation != undefined && <StyledLocationTitle>You've selected<br/><span className="hightlight">{locationList[selectedLocation].name}</span></StyledLocationTitle>}
              {selectedLocation == undefined && <StyledLocationTitle>Select your location</StyledLocationTitle>}
          </LeftContent>}
        </LeftOverlayContainer>
        {!activeSlideExplorer && <Map name={hovered} timeOfDay={locationPhase}/>}
      </Left>
      <div className="location-right"> 
        <div className="location-right-inside">
          <Topbar hide={!visible} />
          <div className="location-content" >
            <LocationList id="scroll-target" >
              {locationList.map((option, index) => {
                return (
                  <LocationChoice
                    key={index}
                    locationName={option.name}
                    locationDescription={option.description}
                    illustrationName={option.image}
                    selected={selectedLocation == index}
                    previouslySelected={
                      locationsAlreadyPicked && selectedLocation != index &&
                      locationsAlreadyPicked.indexOf(index) != -1
                    }
                    onClick={() => {
                      setHovered(option.name);
                      dispatch(
                        setLocation({
                          gameId,
                          userId,
                          location: index,
                          time: locationPhase,
                        })
                      )
                    }}
                  />
                );
              })}
            </LocationList>
          </div>
        </div>
        <StyledProgressBar disabled={selectedLocation == undefined || selectedLocation == -1} text={ nextText || "Continue to inventory"} />
      </div>
    </div>
  );
};

export default Location;

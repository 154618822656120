import React from "react";
import styled, { css } from "styled-components";
import {
  BaseButton,
  BaseButtonStyles,
  IBaseButtonProps,
} from "@diana-ui/button";

const StyledButton = styled(BaseButton).attrs((props) => ({}))(
  ({ theme, disabled, renderLeftIcon, renderRightIcon }) => css`
    color: ${theme.colors.black};
    font-size: 16px;
    border: none;
    outline: none;
    padding: 7px 16px;
    height: 178px;
    background-color: ${theme.colors.brown.brown50};
    font-size: 43px;
    display: flex;
    align-items: center;
  `
);

const BigButton: React.FC<IBaseButtonProps> = ({ style, ...props }) => {
  return <StyledButton {...props} />;
};
export default BigButton;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import {
  getDemographicsRequest,
  setDemographics,
} from "../../../store/logging/actions";
import IconButton from "../../button/IconButton";
import * as d3 from "d3";
import { getBackgroundImage } from "../../illustration/Illustration";
import {
  Body2Light,
  Text2Roman,
  Body1Light,
  Title2Light,
} from "../../typography/Typography";
import { IAppState } from "../../../store";

export const ShadowOverlay = styled.div<{ open?: boolean }>(
  () => css`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background: rgba(10, 10, 10, 0.7);
    pointer-events: none;
    width: 100%;
    z-index: 0;
  `
);
export const StyledSideModal = styled.div<{ open?: boolean }>(
  ({ theme, open }) => css`
    position: fixed;
    right: 0px;
    z-index: 2;
    top: 0px;
    width: ${open ? "70%" : "0%"};
    min-width: 0%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.grey.grey25};
    transition: ${open ? "width 0.5s" : "none"};

    .header {
      display: flex;
      justify-content: flex-end;
      padding: 12px;
      flex-shrink: 0;

      .icon {
        cursor: pointer;
      }
    }
    .side-modal-content {
      transition: ${open ? "opacity 1s 0.5s" : "none"};
      display: flex;
      flex-direction: column;
      opacity: ${open ? 1 : 0};
    }
    .demographics-content {
      display: flex;
      padding: 40px 40px 0px 40px;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      opacity: 0;
      animation: fade-in 0.5s 1s forwards;
    }
    .bottom-image {
      height: 370px;
      width: 341px;
      z-index: 3;
      background-image: url(${getBackgroundImage("Adviser")});
      background-size: cover;
      animation: fade-in 1s 1s forwards;
      opacity: 0;
    }
    .dialog-bubble {
      background-color: ${theme.colors.white};
      padding: 32px;
      border-radius: 10px;
      animation: fade-in 1s 1s forwards;
      opacity: 0;
    }
    .demographics-chart {
      margin: 40px 0px;
      height: 300px;
      flex-shrink: 0;
    }

    .demographics-content-header {
      display: flex;
      text-align: left;
      flex-direction: column;
      flex-shrink: 0;

      > * {
        margin-bottom: 24px;
      }
      .chart-title {
        color: ${theme.colors.red.red25};
      }
    }
    .adviser-container {
      padding-left: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
    }
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
);

const demographicNames = ["Children", "Teens", "Adults", "Total"];
interface ISideModalProps {
  open?: boolean;
}
export const SideModal: React.FC<ISideModalProps> = ({ open }) => {
  const dispatch = useDispatch();
  const userId: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const gameId: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  useEffect(() => {
    if (open) {
      dispatch(
        getDemographicsRequest({
          gameId,
          userId,
        })
      );
    }
  }, [open]);

  const demographic_data = useSelector(
    (state: IAppState) => state.loggingReducer.demographicsData
  );

  const targetDemo = d3.maxIndex(
    demographic_data.filter((f: any, i: number) => i < 3),
    (d: any) => d
  );

  return (
    <StyledSideModal open={open}>
      {open && (
        <div className="side-modal-content">
          <div className="header">
            <IconButton
              stroke="black"
              name="close"
              onClick={() => dispatch(setDemographics(false))}
            />
          </div>
          <div className="demographics-content">
            <div className="demographics-content-header">
              <Title2Light>Take a look at your key demographics</Title2Light>
              <Body2Light>
                We can use past sales to identify our key demographics
              </Body2Light>
              <Text2Roman className="chart-title">
                Average sales/day during last 7 days
              </Text2Roman>
            </div>
            <BarChart
              data={demographic_data.map((d: any, i: number) => {
                return {
                  label: demographicNames[i],
                  value: d,
                };
              })}
            />
          </div>
          <div className="adviser-container">
            <div className="dialog-bubble">
              <Body1Light>
                {demographicNames[targetDemo]} are your key demographics
              </Body1Light>
            </div>

            <div className="bottom-image"></div>
          </div>
        </div>
      )}
    </StyledSideModal>
  );
};

const appendOrSelect = (
  container: any,
  selector: any,
  element: any,
  classString: string
) => {
  let result = container.select(selector);
  if (result.empty()) {
    result = container.append(element).attr("class", classString);
  }
  return result;
};
class BarChart extends React.Component<{ data: any[] }, {}> {
  svgElement: any;
  svg: any;
  chartContainer: any;
  xScale: any;
  yScale: any;
  public margin = { top: 5, left: 45, right: 5, bottom: 25 };

  componentDidUpdate() {
    setTimeout(() => {
      this.build();
    }, 2000);
  }

  build() {
    this.svg = d3.select(this.svgElement);
    this.chartContainer = appendOrSelect(
      this.svg,
      ".chart-container",
      "g",
      "chart-container"
    );

    this.chartContainer.attr(
      "transform",
      `translate(${this.margin.left},${this.margin.top})`
    );
    this.buildScales();
    this.buildAxis();
    this.buildBar(this.props.data);
  }

  buildScales() {
    const chartWidth = 700 - this.margin.left - this.margin.right;
    this.xScale = d3
      .scaleBand()
      .domain(demographicNames)
      .range([0, chartWidth])
      .paddingInner(0.8)
      .paddingOuter(0.5);

    const max =
      d3.max(
        this.props.data.filter((f) => f.value != null),
        (d) => d.value
      ) || 0;
    this.yScale = d3.scaleLinear().domain([0, max]).range([226, 0]);
  }

  buildAxis() {
    const xAxisElement = appendOrSelect(
      this.chartContainer,
      ".x",
      "g",
      "axis x"
    );
    let xAxis = d3.axisBottom(this.xScale).tickSize(5).ticks(15);
    xAxisElement
      .attr("transform", "translate(0," + 226 + ")")
      // .transition()
      .call(xAxis);

    const yAxisElement = appendOrSelect(
      this.chartContainer,
      ".y",
      "g",
      "axis y"
    );
    let yAxis = d3.axisLeft(this.yScale).tickSize(5).ticks(3);
    yAxisElement.call(yAxis);
  }

  buildBar(data: any) {
    const bar: any = this.chartContainer.selectAll(".bar").data(data);
    bar
      .enter()
      .append("rect")
      .merge(bar)
      .attr("class", "bar")
      .attr("rx", 5)
      .attr("ry", 5)

      .attr("x", (d: any) => this.xScale(d.label))
      .attr("width", () => this.xScale.bandwidth())
      .attr("y", this.yScale(0))
      .attr("height", 0)
      .transition()
      .duration(500)
      .delay((d: any, i: any) => i * 200 + 500)
      .attr("y", (d: any) => this.yScale(d.value))
      .attr("height", (d: any) => this.yScale(0) - this.yScale(d.value))
      .attr("fill", "#ED5B41");
  }

  render() {
    return (
      <svg
        ref={(node) => {
          this.svgElement = node;
        }}
        className="demographics-chart"
        width="728"
        height="300"
      ></svg>
    );
  }
}
export default SideModal;

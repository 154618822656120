import React from "react";
import styled, { css } from "styled-components";
import {
  BaseButton,
  BaseButtonStyles,
  IBaseButtonProps,
} from "@diana-ui/button";

const StyledButton = styled(BaseButton).attrs((props: any) => ({}))(
  ({ theme, disabled, renderLeftIcon, renderRightIcon }) => css`
    border: 1px solid;
    border-color: ${theme.colors.black};
    color: ${theme.colors.black};
    border-radius: 100px;
    padding: 12px 24px;
    background-color: transparent;

    
`
);

interface ISecondaryButtonProps {
  hovered?: boolean
}
const SecondaryButton: React.FC<ISecondaryButtonProps & IBaseButtonProps> = ({ hovered, style, ...props }) => {
  return <StyledButton {...props} />;
};
export default SecondaryButton;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkGameRequest, resetGame } from "../../store/logging/actions";
import Welcome from "../welcome/Welcome";
import Location from "../location/Location";

import { IAppState } from "../../store";
import ErrorPage from "./ErrorPage";
import Business from "../business/Business";
import Inventory from "../inventory/Inventory";
import FinantialOverview from "../financial/FinancialOverview";
import MarketingCampaign from "../marketing-campaign/MarketingCampaign";
import InventoryOverview from "../inventory-overview/InventoryOverview";
import PriceMarkdown from "../price-markdown/PriceMarkdown";
import Actions from "../actions/Actions";
import FinalScore from "../final-score/FinalScore";
import Opener, { OpenerWithIllustration, ParkScreen } from "../opener/Opener";
import Modal, { ErrorModal } from "../../components/modal/Modal";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import SideModal, { ShadowOverlay } from "../../components/cta-next-step/sidemodal/SideModal";

interface IGameProps extends RouteComponentProps {
  isAutomaticJoin?: boolean;
}
export const Game: React.FC<IGameProps> = ({ isAutomaticJoin = false, location }) => {
  const currentStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentStep
  );

  const currentName: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentName
  );
  const currentGame: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentGame
  );
  const currentRandom: any = useSelector(
    (state: IAppState) => state.loggingReducer.currentRandomId
  );
  const dispatch = useDispatch();

  if (currentGame != undefined && currentName != undefined) {
    dispatch(
      checkGameRequest({ gameId: currentGame, userId: currentName || "", random: currentRandom })
    );
  }

  return (
    <GameInternals
      currentStep={currentStep}
      currentName={currentName}
      currentGame={currentGame}
      location={location}
      isAutomaticJoin={isAutomaticJoin}
    />
  );
};

const getPage = (
  currentStep: number,
  currentGame?: string,
  currentName?: string,
  isBackStep?: boolean
) => {
  switch (currentStep) {
    case 0:
      return currentName == undefined || currentGame == undefined ? (
        <ErrorPage />
      ) : (
        <OpenerWithIllustration imageIndex={1}>
          <Welcome />
        </OpenerWithIllustration>
      );
    case 1:
      return <Business />;
    case 2: 
      return (
        <ParkScreen isBackstep={isBackStep} prevBackground="night" background="morning">
          <Location locationPhase="location9am" />
        </ParkScreen>
      );
    case 3:
      return <Inventory />;
    case 4:
      return (
        <FinantialOverview timeOfDay="morning" time="9am" nextText="Show me the morning sales" 
          descriptionText="This is your financial overview before the start of the day. Let's make some money!"
          leftText="Congratulations! You are ready to start the day" tagText="Before the Opening"/> 
      );
    case 5:
      return (
        <ParkScreen isBackstep={isBackStep} prevBackground="morning" background="cloudy">
          <FinantialOverview
            timeOfDay="raining"
            descriptionText="Morning is over! Your profit so far"
            time="12pm"
            nextText="Select a new location"
            tagText="A slow day so far"
            leftText="It's raining now! Sales are lower than expected"
          />
        </ParkScreen>
      );
    case 6:
      return <Location locationPhase="location12pm" nextText="Select a marketing campaign" />;
    case 7:
      return <MarketingCampaign />;
    case 8:
      return (
        
        <ParkScreen isBackstep={isBackStep} prevBackground="cloudy" background="afternoon">
          <FinantialOverview
            timeOfDay="noon"
            descriptionText="We are halfway through the afternoon. Your profit so far"
            time="4pm"
            nextText="Show my inventory"
            tagText="A better afternoon"
            leftText="Your Marketing effors have improved sales"
          />
        </ParkScreen>
      );
    case 9:
      return <InventoryOverview time="4pm" nextText="Let's change to another location" timeOfDay="noon" />;
    case 10:
      return <Location locationPhase="location4pm" nextText="Continue to price markdown"/>;
    case 11:
      return <PriceMarkdown />;
    case 12:
      return (
        
        <ParkScreen isBackstep={isBackStep} prevBackground="evening" background="endOfDay">
          <FinantialOverview
            timeOfDay="evening"
            descriptionText="The day is over, this is your final financial balance"
            time="6pm"
            nextText="Show my final inventory"
            tagText="The day has ended"
            leftText="Your Marketing effors have improved sales"
          />
        </ParkScreen>
      );
    case 13:
      return <InventoryOverview time="6pm" nextText="Review my actions" leftText="6pm a better afternoon" timeOfDay="evening" />;
    case 14:
      return <Actions />;
    case 15:
      return <FinalScore />;

    default:
      /*dispatch(
        setStep({
          gameId: currentGame,
          userId: currentName || "",
          step: 1,
        })
      );*/
      return <div>Unknown </div>;
  }
};

interface IGameInternalsProps {
  currentStep: number;
  currentGame?: string;
  currentName?: string;
  location?: any;
  isAutomaticJoin?: boolean
}
export const GameInternals: React.FC<IGameInternalsProps> = ({
  currentStep,
  currentGame,
  currentName,
  location,
  isAutomaticJoin = false
}) => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const gameError = useSelector(
    (state: IAppState) => state.loggingReducer.gameError
  );
  const demographics = useSelector(
    (state: IAppState) => state.loggingReducer.demographics
  );

  const isBackStep: any = useSelector(
    (state: IAppState) => state.loggingReducer.stepWasBackstep
  );
  useEffect(() => {
    if (location.state) {
      setShowWelcomeModal(!location.state.cameFromLogin);

      console.log("GAME ERROR", gameError)
      if (gameError) {
        setShowErrorModal(true);
      }
    }
  }, [location, gameError]);

  return (
    <div>
      {getPage(currentStep, currentGame, currentName, isBackStep)}
      {currentGame != undefined && currentName != undefined ? (
        <>
          {showWelcomeModal && !isAutomaticJoin && (
            <Modal onContinue={() => setShowWelcomeModal(false)} />
          )}
          {showErrorModal && <ErrorModal />}
          <SideModal open={demographics} />
          { demographics && <ShadowOverlay />}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
};
export default withRouter(Game);
